import React from "react";

import classnames from "classnames";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import FormattedDate from "components/formatted_date/FormattedDate";
import Tooltip from "components/tooltip/Tooltip";

import "./metric_columns.scss";

const MetricColumns = ({ stats, selectedStartDate, selectedEndDate, timezoneOffset }) => {
  const { t } = useTranslation();

  const title = `${t("reporting.dashboard.overview.metricColumns.title")} `;

  const columnsMap = [
    {
      id: "new_registered_customers",
      label: t("reporting.dashboard.overview.metricColumns.newReferrerRegistrations"),
      class_name: "primary",
      tooltip_content: t(
        "reporting.dashboard.overview.metricColumns.newReferrerRegistrationsTooltipContent"
      ),
    },
    {
      id: "sharers",
      label: t("reporting.dashboard.overview.metricColumns.sharers"),
      class_name: "primary",
      tooltip_content: t("reporting.dashboard.overview.metricColumns.sharersTooltipContent"),
    },
    {
      id: "friend_visits",
      label: t("reporting.dashboard.overview.metricColumns.uniqueFriendVisits"),
      class_name: "secondary",
      tooltip_content: t(
        "reporting.dashboard.overview.metricColumns.uniqueFriendVisitsTooltipContent"
      ),
    },
    {
      id: "pending_referrals",
      label: t("reporting.dashboard.overview.metricColumns.pendingReferrals"),
      class_name: "secondary-faded",
      tooltip_content: t(
        "reporting.dashboard.overview.metricColumns.pendingReferralsTooltipContent"
      ),
    },
    {
      id: "confirmed_referrals",
      label: t("reporting.dashboard.overview.metricColumns.confirmedReferrals"),
      class_name: "secondary",
      tooltip_content: t(
        "reporting.dashboard.overview.metricColumns.confirmedReferralsTooltipContent"
      ),
    },
  ];

  const columns = columnsMap.map((v) => ({
    value: stats.find((element) => element.id === v.id).value,
    ...v,
  }));

  const highestValue = Math.max(...columns.map((column) => column.value));

  const columnClassNames = classnames("metric-column__inner", {
    "metric-column__inner--short": highestValue < 10,
  });

  return (
    <div className="metric-columns">
      <div className="metric-columns-date">
        <span>
          {title}
          <FormattedDate
            timestamp={selectedStartDate}
            timezoneOffset={timezoneOffset}
            type="numeral_short"
          />
          {" - "}
          <FormattedDate
            timestamp={selectedEndDate}
            timezoneOffset={timezoneOffset}
            type="numeral_short"
          />
        </span>
      </div>
      <div className="metric-columns__inner">
        {columns.map((v) => {
          const columnHeight = highestValue > 0 ? (v.value / highestValue) * 100 : 0;
          return (
            <div className="metric-column" key={v.id}>
              <Tooltip content={v.tooltip_content} direction="up">
                <div className="metric-column__wrapper">
                  <div className={`${columnClassNames} metric-column__inner--${v.class_name}`}>
                    {columnHeight < 25 && <span>{v.value.toLocaleString(i18next.language)}</span>}
                    <div className="metric-column__value" style={{ height: `${columnHeight}%` }}>
                      {columnHeight >= 25 && (
                        <span>{v.value.toLocaleString(i18next.language)}</span>
                      )}
                    </div>
                  </div>
                </div>
              </Tooltip>
              <div className="metric-column__label">{v.label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MetricColumns;
