import React from "react";

import BaseUser from "models/BaseUser";
import Campaign from "models/Campaign";
import Market from "models/Market";
import { useTranslation } from "react-i18next";

import CisIdentifierField from "components/customer_lookup/cis_user/cis_user_campaigns/cis_user_campaign_fields/CisIdentifierField";
import ReferredByDate from "components/customer_lookup/user/user_campaigns/user_campaign_fields/ReferredByDate";
import ReferredByField from "components/customer_lookup/user/user_campaigns/user_campaign_fields/ReferredByField";
import "components/customer_lookup/user/user_campaigns/user_campaigns.scss";
import FormattedDate from "components/formatted_date/FormattedDate";

const CisUserCampaign = ({
  user,
  campaign,
  joinedAt,
  selectedMarket,
  handleIdentifierEdit,
  handleRevalidate,
}) => {
  const { t } = useTranslation();
  const campaignReferral = user.campaignReferrals.find((cr) => cr.campaignId === campaign.id);

  const headerText = `${t("campaign.header")}: ${campaign.name}`;
  const referredByDateLabel = t("campaign.referredByDateLabel");
  const referredByFieldLabel = t("campaign.referredByFieldLabel");
  const subheaderText = t("campaign.subheader");

  const showCisIdentifierField =
    !!campaignReferral &&
    !!campaign.customFieldFor(user) &&
    Object.keys(campaignReferral.customField).length > 0;

  const showReferredByField = !!campaignReferral && user.isExternalUser();
  const showReferredByDate =
    !!campaignReferral && user.isExternalUser() && campaign.postPurchaseTracking;

  return (
    <div className="card">
      <h2 className="card__title">{headerText}</h2>
      <div className="cis-account-container">
        <div className="cis-account">
          <span className="cis-account__header">{subheaderText}</span>
          <span className="cis-account__detail">
            <FormattedDate
              timestamp={joinedAt}
              timezoneOffset={selectedMarket.timezone_offset}
              type="long"
            />
          </span>
        </div>

        {showReferredByField && (
          <ReferredByField label={referredByFieldLabel}>
            {campaignReferral.referringFullName}
          </ReferredByField>
        )}

        {showCisIdentifierField && (
          <CisIdentifierField
            user={user}
            campaign={campaign}
            campaignReferral={campaignReferral}
            handleIdentifierEdit={handleIdentifierEdit}
            handleRevalidate={handleRevalidate}
          />
        )}

        {showReferredByDate && (
          <ReferredByDate
            date={campaignReferral.completedAt}
            label={referredByDateLabel}
            timezoneOffset={selectedMarket.timezone_offset}
          />
        )}
      </div>
    </div>
  );
};

export default CisUserCampaign;
