import React from "react";

import { translateFromEn } from "helpers/Helpers";
import { useTranslation } from "react-i18next";

import TooltipTh from "components/tooltip_th/TooltipTh";

import "./program-performance-table.scss";

const PartnerPerformanceTable = ({ partnerPerformance }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="program-performance-table-container">
      <table className="portal-table">
        <thead>
          <tr>
            <th>{t("partners.programPerformance.tableHeaders.month")}</th>
            <TooltipTh
              headerCopy={t("partners.programPerformance.tableHeaders.visits")}
              tooltipMessage={t("partners.programPerformance.tooltips.visits")}
            />
            <TooltipTh
              headerCopy={t("partners.programPerformance.tableHeaders.created")}
              tooltipMessage={t("partners.programPerformance.tooltips.created")}
            />
            <TooltipTh
              headerCopy={t("partners.programPerformance.tableHeaders.confirmed")}
              tooltipMessage={t("partners.programPerformance.tooltips.confirmed")}
            />
            <TooltipTh
              headerCopy={t("partners.programPerformance.tableHeaders.cancelled")}
              tooltipMessage={t("partners.programPerformance.tooltips.cancelled")}
            />
          </tr>
        </thead>
        <tbody>
          {partnerPerformance.monthly.map((month) => (
            <tr key={month.date}>
              <td>{translateFromEn(month.date, "MMMM yyyy", i18n.resolvedLanguage)}</td>
              <td>{month.visits}</td>
              <td>{month.created}</td>
              <td>{month.confirmed}</td>
              <td>{month.cancelled}</td>
            </tr>
          ))}
          <tr>
            <td>{t("partners.programPerformance.tableTotal")}</td>
            <td>{partnerPerformance.totals.visits}</td>
            <td>{partnerPerformance.totals.created}</td>
            <td>{partnerPerformance.totals.confirmed}</td>
            <td>{partnerPerformance.totals.cancelled}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PartnerPerformanceTable;
