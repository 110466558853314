import React, { useState } from "react";

import { capitalize } from "helpers/Helpers.js";
import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";
import Checkbox from "components/forms/checkbox/Checkbox";
import Select from "components/forms/select/Select";
import TextInput from "components/forms/text_input/TextInput";

const CreateReferrer = ({ selectedMarket, onReferrerCreated }) => {
  const { t } = useTranslation();

  const saveButtonText = t("global.save");
  const firstNameText = t("forms.labels.firstName");
  const lastNameText = t("forms.labels.lastName");
  const emailText = t("forms.labels.email");

  const formSelectCampaignText = t("userSearch.addReferrer.modal.form.selectCampaignText");
  const formEmailComplianceLabelText = t(
    "userSearch.addReferrer.modal.form.emailComplianceLabelText"
  );
  const formMarketingOptInLabelText = t(
    "userSearch.addReferrer.modal.form.marketingOptInLabelText"
  );
  const formTermAndConditionsLabelText = t(
    "userSearch.addReferrer.modal.form.termAndConditionsLabelText"
  );

  const [formState, setFormState] = useState({
    campaignId: "",
    firstName: "",
    lastName: "",
    email: "",
    customFields: [],
    termsOptIn: false,
    emailComplianceOptIn: false,
    marketingOptIn: false,
  });
  const [displayOptIns, setDisplayOptIns] = useState({
    showEmailComplianceOptIn: false,
    showMarketingOptIn: false,
    showTermsOptIn: false,
  });
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [selectedCampaignCustomFields, setSelectedCampaignCustomFields] = useState([]);
  const liveCampaigns = selectedMarket
    .visibleCampaigns()
    .filter((c) => c.live && c.programType !== "partner");
  const campaignOptions = liveCampaigns.map((campaign) => ({
    value: campaign.id,
    name: campaign.name,
  }));

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setFormErrors({});

    try {
      const createdReferrerResponse = await selectedMarket.users.createNewReferrer(
        formState.campaignId,
        formState.firstName,
        formState.lastName,
        formState.email,
        formState.customFields,
        formState.termsOptIn,
        formState.emailComplianceOptIn,
        formState.marketingOptIn
      );

      onReferrerCreated(createdReferrerResponse.id);
    } catch (e) {
      setFormErrors(e.errors);
    } finally {
      setLoading(false);
    }
  };

  const handleCampaignSelectorChange = (e) => {
    const campaignId = e.value;
    const selectedCampaign = selectedMarket.visibleCampaigns().find((c) => c.id === campaignId);
    let customFields = [];

    setDisplayOptIns(selectedCampaign.referrerRegistration);

    if (selectedCampaign.referrerCustomFieldsConfigured().length > 0) {
      setSelectedCampaignCustomFields(selectedCampaign.referrerCustomFieldsConfigured());
      customFields = selectedCampaign.referrerCustomFieldsConfigured().map((cf) => ({
        key: cf.label,
        value: "",
      }));
    } else {
      setSelectedCampaignCustomFields([]);
    }

    setFormState({ ...formState, campaignId, customFields });
  };

  const handleInputChange = (e) => {
    setFormState({ ...formState, [e.target.id]: e.target.value });
  };

  const handleCustomFieldInputChange = (e) => {
    const customFields = [...formState.customFields];
    customFields.find((cf) => cf.key === e.target.id).value = e.target.value;
    setFormState({ ...formState, customFields });
  };

  const handleCustomFieldSelectChange = (option, key) => {
    const customFields = [...formState.customFields];
    customFields.find((cf) => cf.key === key).value = option.value;
    setFormState({ ...formState, customFields });
  };

  const handleCheckboxChange = (value, checked, name) => {
    setFormState({ ...formState, [name]: checked });
  };

  return (
    <div>
      <form className="c-form" onSubmit={handleSubmit}>
        <div className="name">
          <TextInput
            labelText={firstNameText}
            id="firstName"
            value={formState.firstName}
            handleChange={handleInputChange}
            required
            focus
            errors={formErrors.first_name}
          />
          <TextInput
            labelText={lastNameText}
            id="lastName"
            required
            value={formState.lastName}
            handleChange={handleInputChange}
            errors={formErrors.last_name}
          />
        </div>
        <TextInput
          labelText={emailText}
          id="email"
          value={formState.email}
          required
          handleChange={handleInputChange}
          errors={formErrors.email}
        />
        <div className="field-group">
          <Select
            options={campaignOptions}
            handleOnChange={handleCampaignSelectorChange}
            title={formSelectCampaignText}
            titleBold
            selectedValue={formState.campaignId}
            buttonAppearance="secondary"
            id="campaign_id"
            errors={formErrors.campaign_id}
          />
        </div>

        {selectedCampaignCustomFields.map((selectedCampaignCustomField, index) => {
          switch (selectedCampaignCustomField.type) {
            case "list":
              return (
                <Select
                  key={`cf-${selectedCampaignCustomField.label}`}
                  options={selectedCampaignCustomField.items}
                  handleOnChange={(e) =>
                    handleCustomFieldSelectChange(e, selectedCampaignCustomField.label)
                  }
                  title={capitalize(selectedCampaignCustomField.label)}
                  titleBold
                  selectedValue={
                    formState.customFields.find(
                      (cf) => cf.key === selectedCampaignCustomField.label
                    )?.value || ""
                  }
                  buttonAppearance="secondary"
                  id={selectedCampaignCustomField.label}
                  errors={formErrors.custom_field || formErrors[`custom_fields.${index}`]}
                  errorKey="customField"
                />
              );
            case "text":
            default:
              return (
                <TextInput
                  key={`cf-${selectedCampaignCustomField.label}`}
                  labelText={capitalize(selectedCampaignCustomField.label)}
                  id={selectedCampaignCustomField.label}
                  value={
                    formState.customFields.find(
                      (cf) => cf.key === selectedCampaignCustomField.label
                    )?.value || ""
                  }
                  handleChange={handleCustomFieldInputChange}
                  errors={formErrors.custom_field || formErrors[`custom_fields.${index}`]}
                  errorKey="customField"
                />
              );
          }
        })}

        {displayOptIns.showEmailComplianceOptIn && (
          <div className="field-group">
            <Checkbox
              name="emailComplianceOptIn"
              checked={formState.emailComplianceOptIn}
              errors={formErrors.email_compliance_opt_in}
              label={formEmailComplianceLabelText}
              value={formState.emailComplianceOptIn}
              handleChange={handleCheckboxChange}
            />
          </div>
        )}

        {displayOptIns.showMarketingOptIn && (
          <div className="field-group">
            <Checkbox
              name="marketingOptIn"
              checked={formState.marketingOptIn}
              errors={formErrors.marketing_opt_in}
              label={formMarketingOptInLabelText}
              value={formState.marketingOptIn}
              handleChange={handleCheckboxChange}
            />
          </div>
        )}

        {displayOptIns.showTermsOptIn && (
          <div className="field-group">
            <Checkbox
              name="termsOptIn"
              checked={formState.termsOptIn}
              errors={formErrors.terms_opt_in}
              label={formTermAndConditionsLabelText}
              value={formState.termsOptIn}
              handleChange={handleCheckboxChange}
            />
          </div>
        )}

        <Button
          buttonElement="submit"
          appearance="tertiary"
          className="u-stack-2"
          fullWidth
          loading={isLoading}
        >
          {saveButtonText}
        </Button>
      </form>
    </div>
  );
};

export default CreateReferrer;
