import React from "react";

import { useTranslation } from "react-i18next";

import FormattedNumber from "components/formatted_number/FormattedNumber";
import Tooltip from "components/tooltip/Tooltip";

import "./performance_tiles.scss";

const PerformanceTile = ({ stats }) => {
  const { t } = useTranslation();
  const tiles = {
    confirmed_referrals: {
      title: t("reporting.dashboard.performanceTiles.confirmedReferrals"),
      toolTipContent: t("reporting.dashboard.performanceTiles.confirmedReferralsContent"),
      roundTo: 0,
    },
    referrers_who_shared: {
      title: t("reporting.dashboard.performanceTiles.referrersWhoShared"),
      toolTipContent: t("reporting.dashboard.performanceTiles.referrersWhoSharedContent"),
      roundTo: 0,
    },
    confirmed_referrals_per_sharer: {
      title: t("reporting.dashboard.performanceTiles.confirmedReferralsPerSharer"),
      toolTipContent: t("reporting.dashboard.performanceTiles.confirmedReferralsPerSharerContent"),
      roundTo: 2,
    },
    friend_visits_per_sharer: {
      title: t("reporting.dashboard.performanceTiles.friendVisitsPerSharer"),
      toolTipContent: t("reporting.dashboard.performanceTiles.friendVisitsPerSharerContent"),
      roundTo: 1,
    },
    friend_visits_to_confirmed_referrals: {
      title: t("reporting.dashboard.performanceTiles.friendVisitsToConfirmedReferral"),
      toolTipContent: t(
        "reporting.dashboard.performanceTiles.friendVisitsToConfirmedReferralContent"
      ),
      roundTo: 0,
    },
  };

  return (
    <div className="performance-tile__wrapper">
      {stats.map((v) => (
        <div key={v.id} className="performance-tile__item">
          <Tooltip content={tiles[v.id].toolTipContent}>
            <div className="performance-tile__item-inner">
              <span className="performance-tile__heading">
                <FormattedNumber
                  number={v.value}
                  displayType={v.display_type}
                  roundTo={tiles[v.id].roundTo}
                />
              </span>
              <span className="performance-tile__meta">{tiles[v.id].title}</span>
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default PerformanceTile;
