import React, { useCallback, useEffect, useRef, useState } from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { FaBars, FaChevronDown } from "react-icons/fa";

import "./select.scss";

const Select = (props) => {
  const { t } = useTranslation();

  const {
    options,
    selectedValue = null,
    selectedPlaceholder = t("forms.placeholders.select"),
    positionRight = false,
    title = "",
    titleBold = false,
    handleOnChange,
    buttonAppearance = "primary",
    errors = [],
    errorKey = null,
    id = "",
    disabled = false,
    fullWidth = false,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(false);
  const selectRef = useRef();
  const handlerClasses = classnames("c-select-handler", {
    "c-select-handler--disabled": disabled,
    "c-select-handler--fullWidth": fullWidth,
    "c-select-handler--active": isOpen,
    "c-select-handler--right": positionRight,
    "c-select-handler--form-select": buttonAppearance === "secondary",
    "c-select-handler--error": errors.length > 0,
  });
  const wrapperClasses = classnames("c-select-handler__wrapper", {
    "c-select-handler__wrapper--form-select": buttonAppearance === "secondary",
  });

  const onHandlerClick = () => {
    setIsOpen(!isOpen);
  };

  const onItemClick = (option) => {
    setIsOpen(false);
    setActiveItem(option);
    handleOnChange(option);
  };

  const handleClickOutside = ({ target }) => {
    if (selectRef.current && !selectRef.current.contains(target)) {
      setIsOpen(false);
    }
  };

  const getActiveItem = () => {
    if (!selectedValue) {
      return { name: selectedPlaceholder };
    }

    return activeItem;
  };

  const setOptionItem = useCallback(() => {
    const optionsFlat = options.flatMap((opt) => (opt.subOptions ? opt.subOptions : opt));

    return optionsFlat.find((option) => option.value === selectedValue);
  }, [options, selectedValue]);

  useEffect(() => {
    setActiveItem(setOptionItem());
    document.addEventListener("click", handleClickOutside, true);

    return () => document.removeEventListener("click", handleClickOutside, true);
  }, [setOptionItem]);

  const optionItem = (option) => (
    <li
      className={`c-select__item ${
        option.value === getActiveItem().value ? "c-select__item--active" : ""
      }`}
      key={option.value}
    >
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => onItemClick(option)}
        onClick={() => onItemClick(option)}
      >
        {option.name}
      </div>
    </li>
  );

  const buttonType = () =>
    buttonAppearance === "primary" ? (
      <button
        type="button"
        onClick={onHandlerClick}
        tabIndex={0}
        className={handlerClasses}
        disabled={disabled}
      >
        <i className="c-select-handler__icon">
          <FaBars />
        </i>
        <span className={`c-select-handler__title u-inline-1 ${titleBold ? "font-bold" : ""}`}>
          {title}
        </span>
        <span className="c-select-handler__selected-item">{getActiveItem().name}</span>
      </button>
    ) : (
      <>
        <span className={`c-select-handler__title u-stack-2 ${titleBold ? "font-bold" : ""}`}>
          {title}
        </span>
        <button
          type="button"
          onClick={onHandlerClick}
          tabIndex={0}
          className={handlerClasses}
          disabled={disabled}
        >
          <span className="c-select-handler__selected-item">{getActiveItem().name}</span>
          <span className="c-select-handler__chevron">
            <FaChevronDown />
          </span>
        </button>
      </>
    );

  return (
    <div ref={selectRef} className={wrapperClasses}>
      {buttonType()}
      {isOpen && (
        <ul className="c-select__list">
          {options.map((option) => {
            if (option.subOptions) {
              return (
                <React.Fragment key={option.name}>
                  <li className="c-select__list--title-option">{option.name}</li>
                  <li>
                    <ul>{option.subOptions.map((innerOption) => optionItem(innerOption))}</ul>
                  </li>
                </React.Fragment>
              );
            }

            return optionItem(option);
          })}
        </ul>
      )}
      {errors.map((error) => (
        <div className="c-select__error-message" key={error}>
          {t(`forms.errors.${errorKey || id}.${error.type}`)}
        </div>
      ))}
    </div>
  );
};

export default Select;
