import React from "react";

const ReferredByField = ({ children, label }) => (
  <div className="cis-account">
    <span className="cis-account__header">{label}</span>
    <span className="cis-account__detail">{children}</span>
  </div>
);

export default ReferredByField;
