import React from "react";

import { useAuth } from "@/AuthWrapper";
import { Permissions } from "bp-ui";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";

import SubNavigation from "components/sub_navigation/SubNavigation";

import AddCustomers from "./add_customers/AddCustomers.jsx";
import ForgetUsers from "./forget_users/ForgetUsers.jsx";
import ReferralManagement from "./referral_management/ReferralManagement.jsx";
import Signups from "./signups/Signups.jsx";
import TransactionTracking from "./transaction_tracking/TransactionTracking.jsx";

const BulkActions = ({ selectedMarket }) => {
  const { t } = useTranslation();
  const { currentAdmin } = useAuth();

  const {
    bulkActionTransactions,
    bulkActionReferrals,
    bulkActionSignups,
    bulkActionCustomers,
    bulkActionForgetUsers,
  } = Permissions;

  const availableLinks = [
    {
      text: t("bulkActions.navigation.transactionTracking"),
      path: "/users/bulk-actions/transaction-tracking",
      permissions: [bulkActionTransactions],
    },
    {
      text: t("bulkActions.navigation.referralManagement"),
      path: "/users/bulk-actions/referral-management",
      permissions: [bulkActionReferrals],
    },
    {
      text: t("bulkActions.navigation.signups"),
      path: "/users/bulk-actions/signups",
      permissions: [bulkActionSignups],
    },
    {
      text: t("bulkActions.navigation.addCustomers"),
      path: "/users/bulk-actions/add-customers",
      permissions: [bulkActionCustomers],
    },
    {
      text: t("bulkActions.navigation.forgetUsers"),
      path: "/users/bulk-actions/forget-users",
      permissions: [bulkActionForgetUsers],
    },
  ];

  const availableBulkActionLinks = [
    {
      text: t("bulkActions.navigation.transactionTracking"),
      path: "transaction-tracking",
      permissions: [bulkActionTransactions],
    },
    {
      text: t("bulkActions.navigation.referralManagement"),
      path: "referral-management",
      permissions: [bulkActionReferrals],
    },
    {
      text: t("bulkActions.navigation.signups"),
      path: "signups",
      permissions: [bulkActionSignups],
    },
    {
      text: t("bulkActions.navigation.addCustomers"),
      path: "add-customers",
      permissions: [bulkActionCustomers],
    },
    {
      text: t("bulkActions.navigation.forgetUsers"),
      path: "forget-users",
      permissions: [bulkActionForgetUsers],
    },
  ];

  const firstAuthorizedBulkActionsLink = () => {
    const authorizedLinks = currentAdmin.super
      ? availableBulkActionLinks
      : availableBulkActionLinks.filter((link) => currentAdmin.canPerformAnyOf(link.permissions));

    if (authorizedLinks.length > 0) {
      return authorizedLinks[0].path;
    }

    return ".";
  };

  return (
    <>
      <SubNavigation
        subdomain={selectedMarket.subdomain}
        availableLinks={availableLinks}
        isSecondary
      />
      <Routes>
        <Route path={""} element={<Navigate to={firstAuthorizedBulkActionsLink()} />} />
        <Route
          path="transaction-tracking"
          element={<TransactionTracking selectedMarket={selectedMarket} />}
        />
        <Route
          path="referral-management"
          element={<ReferralManagement selectedMarket={selectedMarket} />}
        />
        <Route path="signups" element={<Signups selectedMarket={selectedMarket} />} />
        <Route path="add-customers" element={<AddCustomers selectedMarket={selectedMarket} />} />
        <Route path="forget-users" element={<ForgetUsers selectedMarket={selectedMarket} />} />
      </Routes>
    </>
  );
};

export default BulkActions;
