import React, { useCallback, useEffect, useState } from "react";

import { cancelRequests } from "@/axiosInterceptor";
import { useTranslation } from "react-i18next";

import MultiSelectFilterContainer from "components/multi_select_filter/MultiSelectFilterContainer";

const PartnerFilter = ({ selectedMarket, onApply }) => {
  const { t } = useTranslation();

  const [allPartnerIds, setAllPartnerIds] = useState([]);
  const [appliedPartnerIds, setAppliedPartnerIds] = useState([]);
  const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);

  const [partners, setPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const findPartners = useCallback(async () => {
    setIsLoading(true);

    try {
      const res = await selectedMarket.users.getPartners();
      setPartners(res.data.payload);
      setIsLoading(false);
    } catch (e) {
      setErrors(true);
      setIsLoading(false);
    }
  }, [selectedMarket]);

  // On page load, get the partners
  useEffect(() => {
    findPartners();

    return () => cancelRequests();
  }, [findPartners]);

  // once we have partners, store the ids for later
  useEffect(() => {
    const ids = partners.map((partner) => partner.id);
    setAllPartnerIds(ids);
  }, [partners]);

  // set all partner ids as applied and selected so that all checkboxes start out checked.
  useEffect(() => {
    setAppliedPartnerIds(allPartnerIds);
    setSelectedPartnerIds(allPartnerIds);
  }, [allPartnerIds]);

  const options = partners.map((partner) => {
    const opt = {
      label: partner.business_name,
      value: partner.id,
    };
    return opt;
  });

  const handleClearAll = () => {
    setSelectedPartnerIds([]);
  };

  const handleSelectAllCheckbox = (_value, checked) => {
    if (checked) {
      setSelectedPartnerIds(allPartnerIds);
    } else {
      handleClearAll();
    }
  };

  const handleOptionChange = (value, checked) => {
    let newSelectedPartnerIds;
    if (checked) {
      newSelectedPartnerIds = [...new Set(selectedPartnerIds.concat(value))];
    } else {
      newSelectedPartnerIds = selectedPartnerIds.filter((partner) => partner !== value);
    }

    setSelectedPartnerIds(newSelectedPartnerIds);
  };

  const onCancelPartnerFilter = () => {
    setSelectedPartnerIds(appliedPartnerIds);
  };

  const onApplyPartnerFilter = () => {
    setAppliedPartnerIds(selectedPartnerIds);
    onApply(selectedPartnerIds);
  };

  const allLabel = t("components.multiSelectFilter.allSelected");
  const countLabel = t("components.multiSelectFilter.countSelected", {
    count: appliedPartnerIds.length,
  });
  const buttonCopy = appliedPartnerIds.length === options.length ? allLabel : countLabel;

  return (
    <MultiSelectFilterContainer
      selectedOptions={selectedPartnerIds}
      buttonCopy={buttonCopy}
      handleClearAll={handleClearAll}
      onCancel={onCancelPartnerFilter}
      onApply={onApplyPartnerFilter}
      buttonClassName="partner-dashboard__reporting-button partner-dashboard__filter-button"
      isLoading={isLoading}
      errorText={errors ? t("partners.programPerformance.partnerErrors") : undefined}
      options={options}
      handleSelectAll={handleSelectAllCheckbox}
      handleOptionChange={handleOptionChange}
      selectAllLabel={t("partners.programPerformance.partnerFilter.selectAll")}
    />
  );
};

export default PartnerFilter;
