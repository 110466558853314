import React from "react";

import { useTranslation } from "react-i18next";

import YesNo from "components/yes_no/YesNo";

const ReferralLinked = ({ order }) => {
  const { t } = useTranslation();

  return (
    <>
      <YesNo value={order.generatedReferral} />
      {order.referralIneligibilityReason && (
        <span>
          ,<br />
          {t(`referrals.ineligibilityReasons.${order.referralIneligibilityReason}`)}
        </span>
      )}
    </>
  );
};

export default ReferralLinked;
