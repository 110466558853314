import React from "react";

import { useTranslation } from "react-i18next";
import { FaCheck, FaTimes } from "react-icons/fa";

import NoResults from "components/customer_lookup/no_results/NoResults";
import VoucherCode from "components/customer_lookup/voucher_code/VoucherCode";
import Icon from "components/icon/Icon";

import "./user_vouchers.scss";

const UserVouchers = ({ vouchers }) => {
  const { t } = useTranslation();

  const headerText = t("vouchers.header");
  const tableHeaderManuallyIssuedText = t("vouchers.tableHeaders.manuallyIssued");
  const tableHeaderObtainedThroughText = t("vouchers.tableHeaders.obtainedThrough");
  const tableHeaderRedeemedText = t("vouchers.tableHeaders.redeemed");
  const tableHeaderRedeemedByText = t("vouchers.tableHeaders.redeemedBy");
  const tableHeaderVoucherText = t("vouchers.tableHeaders.voucher");

  return vouchers.length > 0 ? (
    <div className="user-vouchers card">
      <h2 className="card__title">{headerText}</h2>
      <div className="table-container">
        <table className="portal-table">
          <thead>
            <tr>
              <th>{tableHeaderVoucherText}</th>
              <th>{tableHeaderObtainedThroughText}</th>
              <th>{tableHeaderRedeemedByText}</th>
              <th>{tableHeaderRedeemedText}</th>
            </tr>
          </thead>
          <tbody>
            {vouchers.map((voucher) => (
              <tr className="single-result" key={voucher.id}>
                <td>
                  {voucher.voucherType}
                  <br />
                  <VoucherCode>{voucher.code}</VoucherCode>
                </td>
                {voucher.distributedByDataPresent() ? (
                  <td>
                    {voucher.distributedByFullName}
                    <br />
                    {voucher.distributedByEmail}
                  </td>
                ) : (
                  <td>{tableHeaderManuallyIssuedText}</td>
                )}
                <td>
                  {voucher.redeemerFullName || "-"}
                  <br />
                  {voucher.redeemerEmail}
                </td>
                <td>
                  {voucher.redeemed ? (
                    <Icon type={FaCheck} className="user-vouchers--has-redeemed" />
                  ) : (
                    <Icon type={FaTimes} className="user-vouchers--has-not-redeemed" />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <NoResults table={headerText} />
  );
};

export default UserVouchers;
