import React from "react";

import FormattedDate from "components/formatted_date/FormattedDate";

const ReferredByDate = ({ date, timezoneOffset, label }) => (
  <div className="cis-account">
    <span className="cis-account__header">{label}</span>
    <span className="cis-account__detail">
      <FormattedDate timestamp={date} timezoneOffset={timezoneOffset} type="long" />
    </span>
  </div>
);

export default ReferredByDate;
