import React from "react";

import { useTranslation } from "react-i18next";

import "./user_type.scss";

const UserType = ({ icon, descriptor }) => {
  const { t } = useTranslation();

  const userTypes = {
    friend: t("userResults.userType.friend"),
    referrer: t("userResults.userType.referrer"),
    partner: t("userResults.userType.partner"),
  };

  return (
    <div className={`user-type user-type-${descriptor}`}>
      {icon}
      {userTypes[descriptor]}
    </div>
  );
};

export default UserType;
