import { useAuth } from "@/AuthWrapper";
import { Permissions } from "bp-ui";

const VoucherCode = ({ children }) => {
  const { currentAdmin } = useAuth();
  const { revealVouchers } = Permissions;

  if (currentAdmin.can(revealVouchers)) {
    return children;
  }

  return "*".repeat(7);
};

export default VoucherCode;
