import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";
import addNotification from "components/notifications/Notifications";

import "./user_unsubscribe.scss";

const UserUnsubscribe = ({ user, selectedMarket, onSuccessfulUnsubscribe }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const errorNotificationTitle = t("global.error");
  const successNotificationTitle = t("global.success");

  const errorMessage = t("userProfile.unsubscribeModal.errorMessage");
  const promptText = t("userProfile.unsubscribeModal.prompt");

  const successMessage = `${user.firstName} ${user.lastName} ${t(
    "userProfile.unsubscribeModal.successMessage"
  )}`;

  const onUnsubscribe = async () => {
    setIsLoading(true);

    try {
      await selectedMarket.users.emailUnsubscribe(user.id);
      addNotification.success(successNotificationTitle, successMessage);
      setIsLoading(false);
      onSuccessfulUnsubscribe();
    } catch (e) {
      setIsLoading(false);
      addNotification.warning(errorNotificationTitle, errorMessage);
    }
  };

  return (
    <div className="u-stack-3">
      <Button appearance="tertiary" onClick={onUnsubscribe} loading={isLoading} fullWidth>
        {promptText}
      </Button>
    </div>
  );
};

export default UserUnsubscribe;
