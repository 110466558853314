import React from "react";

import i18next from "i18next";

import "./form.scss";

const Form = ({ children, onSubmit, errorKey, errors = [] }) => (
  <form className="c-form" onSubmit={onSubmit}>
    {children}
    {errors.map((error) => (
      <div className="c-form__error-message" key={error}>
        {i18next.t(`forms.errors.${errorKey}.${error.type}`)}
      </div>
    ))}
  </form>
);

export default Form;
