import React from "react";

import { getHistoryStatusText } from "helpers/Helpers";
import { useTranslation } from "react-i18next";

import ErrorLoader from "components/error_loader/ErrorLoader";
import FormattedDate from "components/formatted_date/FormattedDate";

import "./referrals_history.scss";

const ReferralsHistory = ({ importList, timezoneOffset, importListLoadingError }) => {
  const { t } = useTranslation();
  const shouldHide = importList.length === 0 && !importListLoadingError;

  return (
    shouldHide || (
      <div className="c-import-referrals-history">
        <h2 className="card__title">{t("bulkActions.referralManagement.history.header")}</h2>
        {importListLoadingError || (
          <div className="table-container">
            <table className="portal-table c-import-referrals-history--table">
              <thead>
                <tr>
                  <th>{t("bulkActions.referralManagement.history.tableHeaders.uploadedAt")}</th>
                  <th>{t("bulkActions.referralManagement.history.tableHeaders.uploadedBy")}</th>
                  <th>{t("bulkActions.referralManagement.history.tableHeaders.status")}</th>
                  <th>
                    {t("bulkActions.referralManagement.history.tableHeaders.confirmedReferrals")}
                  </th>
                  <th>
                    {t("bulkActions.referralManagement.history.tableHeaders.cancelledReferral")}
                  </th>
                  <th>
                    {t("bulkActions.referralManagement.history.tableHeaders.restoredReferrals")}
                  </th>
                  <th>
                    {t("bulkActions.referralManagement.history.tableHeaders.skippedReferrals")}
                  </th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {importList.map((importRecord) => (
                  <tr key={importRecord.id}>
                    <td>
                      <FormattedDate
                        timestamp={importRecord.uploaded_at}
                        timezoneOffset={timezoneOffset}
                        type="long_with_time"
                      />
                    </td>
                    <td>{importRecord.requested_by}</td>
                    <td>{getHistoryStatusText(importRecord.status)}</td>
                    <td>{importRecord.confirmed_referrals}</td>
                    <td>{importRecord.cancelled_referrals}</td>
                    <td>{importRecord.restored_referrals}</td>
                    <td>{importRecord.skipped_referrals}</td>
                    <td>
                      {importRecord.error_download_link && (
                        <a href={importRecord.error_download_link}>
                          {t(
                            "bulkActions.referralManagement.history.tableHeaders.skippedReferrals"
                          )}
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <ErrorLoader
          errorText={t("forms.errors.bulkActions.load_history")}
          showError={importListLoadingError}
        />
      </div>
    )
  );
};

export default ReferralsHistory;
