import React from "react";

import { Chat } from "assets/images/CustomIcons";
import { useTranslation } from "react-i18next";

import "./contact_bar.scss";

const ContactBar = ({ handleClick }) => {
  const { t } = useTranslation();

  const firstParagraph = t("reporting.dashboard.overview.contactBar.firstParagraph");
  const secondParagraph = t("reporting.dashboard.overview.contactBar.secondParagraph");

  return (
    <div
      className="c-contact-bar"
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <Chat />
      <div className="c-contact-bar__content">
        <p>{firstParagraph}</p>
        <p>{secondParagraph}</p>
      </div>
    </div>
  );
};

export default ContactBar;
