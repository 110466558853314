import React, { forwardRef, useEffect, useState } from "react";

import cn from "classnames";
import i18next from "i18next";
import { FaInfoCircle } from "react-icons/fa";

import Tooltip from "../../tooltip/Tooltip";
import "./text-input.scss";

const TextInput = forwardRef(function TextInput(
  {
    id,
    labelText,
    handleChange,
    required = false,
    plain = false,
    showRequiredIndicator = false,
    icon = null,
    className = "",
    placeholder = "",
    addInputStyling = true,
    value = "",
    focus = false,
    hideLabel = false,
    disableLastPassAutofill = true,
    errors = [],
    errorKey = null,
    handleOnKeyDown = () => {},
    handleOnBlur = () => {},
    inputType = "text",
    showTooltip = false,
    infoText = "",
    testId = null,
    tooltipPlain = true,
    tooltipDirection = "up",
    tooltipShift = "",
    tooltipSpacing = "",
    hint = null,
    labelClass = "",
  },
  ref
) {
  if (!ref) ref = React.createRef();

  useEffect(() => {
    if (focus) ref.current.focus();
  }, []);

  const [tipActive, setTipActive] = useState(false);

  return (
    <div
      className={cn({
        [className]: true,
        "text-input__required": showRequiredIndicator,
        "text-input__info": showTooltip,
        "field-error": errors.length,
        "field-group": !plain,
      })}
    >
      <div className="flex-between">
        <label className={hideLabel ? "no-label" : labelClass} htmlFor={id}>
          {labelText}
        </label>
        {infoText && (
          <div
            className={cn({
              "text-input__info-container": true,
              "text-input__tooltip-active": tipActive,
            })}
          >
            <Tooltip
              tipContentClassName={cn({
                "text-input__info-plain": tooltipPlain,
                "text-input__info": !tooltipPlain,
                [`info-left-${tooltipShift}`]: !tooltipPlain,
                [`info-spacing-${tooltipSpacing}`]: !tooltipPlain && tooltipSpacing,
              })}
              content={infoText}
              testId={testId && `${testId}-tooltip`}
              direction={tooltipDirection}
              onToggle={setTipActive}
            >
              <FaInfoCircle />
            </Tooltip>
          </div>
        )}
      </div>
      <div className={cn("input-group", { input: addInputStyling })}>
        {icon && <div className="input-group__icon">{icon}</div>}
        <input
          autoComplete="off"
          data-lpignore={disableLastPassAutofill}
          data-testid={testId}
          id={id}
          onBlur={handleOnBlur}
          onChange={handleChange}
          onKeyDown={handleOnKeyDown}
          placeholder={placeholder}
          ref={ref}
          required={required}
          type={inputType}
          value={value}
        />
      </div>
      {hint && <div className="text-input__hint">{hint}</div>}
      {errors.map((error) => (
        <div className="field-error-message" key={error.type}>
          {i18next.t(`forms.errors.${errorKey || id}.${error.type}`)}
        </div>
      ))}
    </div>
  );
});

export default TextInput;
