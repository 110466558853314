import React from "react";

import { Empty } from "assets/images/CustomIcons";

import "./no_results.scss";

const NoResults = ({ table }) => (
  <div className="no-results card">
    <p className="no-results__message">{table}</p>
    <Empty />
  </div>
);

export default NoResults;
