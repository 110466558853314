import React, { useState } from "react";

import Market from "models/Market";
import externalAxios from "services/external_axios/ExternalAxios";

import Button from "components/forms/button/Button";
import Form from "components/forms/form/Form";

import TextArea from "../text_area/TextArea";
import "./text_import.scss";

const TextImport = ({
  selectedMarket,
  importType,
  buttonLabel,
  onImportSuccess,
  csvHeaders,
  onImportValidation = () => {},
  placeholderText = "",
}) => {
  const [formState, setFormState] = useState({
    importText: "",
  });
  const [forceDisableSubmit, setForceDisableSubmit] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [formMessages, setFormMessages] = useState({});
  const [isLoading, setLoading] = useState(false);

  const combineAndSetFormErrors = (errorObject) => {
    const combinedErrors = Object.values(errorObject).flat();
    setFormErrors(combinedErrors);
  };

  const handleOnInput = (e) => {
    setForceDisableSubmit(false);
    setFormState({ ...formState, [e.target.id]: e.target.value });
  };

  const trimmedUniqueValues = () => {
    const filteredInputValues = formState.importText
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line !== null && line !== "");
    return [...new Set(filteredInputValues)];
  };

  const textValuesToCsv = (textValues) => {
    const csvText = textValues.join("\n");

    return new File([...csvHeaders, "\n", csvText], "file.csv", {
      type: "text/csv",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setForceDisableSubmit(false);
    setLoading(true);
    setFormErrors([]);
    setFormMessages({});

    try {
      const importValues = trimmedUniqueValues();

      const validationErrors = onImportValidation(importValues);
      if (validationErrors.length > 0) {
        setFormMessages({
          errors: validationErrors,
        });

        setLoading(false);
        setForceDisableSubmit(true);
        return;
      }

      const uploadUrlResponse = await selectedMarket.imports.generateUploadUrl(importType);

      await selectedMarket.imports.uploadFileToS3(
        externalAxios,
        uploadUrlResponse.presignedUrl,
        textValuesToCsv(importValues)
      );

      const fileImportResponse = await selectedMarket.imports.processFile(
        importType,
        uploadUrlResponse.secureFile,
        {}
      );

      if (fileImportResponse.messages) {
        setFormMessages(fileImportResponse.messages);
      }

      // Backend processes files asynchronously
      // and the recent one may not be included
      // in the importList right away
      setTimeout(() => {
        onImportSuccess();
        setLoading(false);
      }, 3000);
    } catch (err) {
      combineAndSetFormErrors(err);
      setLoading(false);
    }
  };

  return (
    <div className="c-file-import">
      <Form onSubmit={handleSubmit} errorKey="textImport">
        <div className="field-group">
          <TextArea
            id="importText"
            placeholderText={placeholderText}
            handleOnInput={handleOnInput}
            messages={formMessages}
            errors={formErrors}
            errorKey="textImport"
          />
        </div>
        <Button
          buttonElement="submit"
          appearance="tertiary"
          loading={isLoading}
          disabled={!formState.importText || forceDisableSubmit}
        >
          {buttonLabel}
        </Button>
      </Form>
    </div>
  );
};

export default TextImport;
