import React from "react";

import { Card } from "bp-ui";
import { t } from "i18next";

import { CampaignTimeline } from "components/customer_lookup/new_user/CampaignTimeline/CampaignTimeline";
import { CustomField } from "components/customer_lookup/new_user/CustomField/CustomField";
import FormattedDate from "components/formatted_date/FormattedDate";

import { VoucherList } from "./VoucherList.jsx";

export const UserCampaignDetails = ({
  selectedMarket,
  campaign,
  user,
  timezoneOffset,
  reloadData,
  timelineData,
}) => {
  const fields = [
    [
      t("referrerProfile.referrerInfoCard.locale"),
      t(`languages.${user.locale ? user.locale : selectedMarket.defaultLocale}`),
    ],
    [
      t("referrerProfile.referrerInfoCard.lastSeen"),
      <FormattedDate
        timestamp={user.lastSeenAt}
        timezoneOffset={timezoneOffset}
        type="numeral_short_with_time"
      />,
    ],
    [
      t("referrerProfile.referrerInfoCard.dateRegistered"),
      <FormattedDate
        timestamp={campaign.joinedAt}
        timezoneOffset={timezoneOffset}
        type="numeral_short_with_time"
      />,
    ],
    ...user.clientDatas?.map((clientData) => [clientData.key, clientData.value]),
  ];

  return (
    <>
      {fields.map((m) => (
        <Card.Detail key={m[0]} border={false} label={m[0]} className="flex-none w-1/5">
          {m[1]}
        </Card.Detail>
      ))}
      {campaign.customFields?.map((customField) => (
        <CustomField
          key={customField.label}
          selectedMarket={selectedMarket}
          campaign={campaign}
          user={user}
          customField={customField}
          reloadData={reloadData}
        />
      ))}

      {user.isExternalUser() && user.vouchers?.length > 0 && (
        <VoucherList vouchers={user.vouchers} selectedMarket={selectedMarket} />
      )}

      <CampaignTimeline timelineData={timelineData} campaign={campaign} />
    </>
  );
};
