import countryCodeObject from "../assets/countryCodes.json";

const passwordFormatError =
  "The password you entered isn't strong enough, consider adding numbers, symbols or more letters to make it stronger.";

export default {
  translation: {
    accountDetails: {
      accountCreationDate: "account creation date",
      accountIdentifier: "account identifier",
      accountType: "account type",
      accountStatus: "account status",
      eligibleForRewards: "eligible for rewards",
      internalIdentifier: "internal identifier",
      subAccountType: "sub-account type",
    },
    auth: {
      generic: "Unable to login at this time. Try again later.",
      unauthorized_ip_address: "Access denied from this IP address.",
    },
    bulkActions: {
      successNotification: "Your file has been uploaded successfully and will be processed",
      downloadCsvDescription:
        "Download our ready to use template to ensure you supply us with the correct data",
      downloadCsvButtonText: "Download CSV Template",
      notification: "You will be notified by email with the status of your import.",
      addCustomers: {
        fileImport: {
          buttonLabel: "Add Customers",
          checkboxLabel: "Emails are MD5 hashes",
        },
        header: "Add Customers",
        history: {
          header: "History",
          tableHeaders: {
            customers: "Customers",
            existing: "Existing",
            new: "New",
            numberOf: "Number Of",
            processedRows: "Processed Rows",
            status: "Status",
            uploadedAt: "Uploaded At",
            uploadedBy: "Uploaded By",
          },
        },
        modal: {
          emailDescription:
            'The email address of the referrer in either plain text (e.g. "john@doe.com") or an MD5 hash of a lower-case email address (e.g. "30123074929abe9168856af4f4bc904b").',
          openButtonText: "What are the required column headers?",
          title: "Required column headers",
        },
        subtitle:
          "Upload a list of customer emails which the platform will use to validate friends eligibility to get a voucher on the friend page. Emails can either be plain text or MD5 hashes of lower-case email addresses. You may need to contact your Client Success Manager to configure this feature if using for the first time.",
      },
      forgetUsers: {
        header: "Forget Users",
        history: {
          header: "History",
          tableHeaders: {
            forgottenUserCount: "Forgotten User Count",
            status: "Status",
            uploadedAt: "Uploaded At",
            uploadedBy: "Uploaded By",
          },
        },
        fileImport: {
          buttonLabel: "Import CSV",
        },
        modal: {
          emailDescription: 'The email address of the referrer e.g. "john@doe.com"',
          openButtonText: "What are the required column headers for CSV upload?",
          title: "Required column headers",
        },
        subtitle:
          "You can upload a list of user emails to be removed from our systems. Alternatively enter an individual user's email address, each on a new line.",
        textImport: {
          buttonLabel: "Submit",
          placeholder: "Manually enter a list of emails, each one on a new line.",
          invalidWarning: "The following email addresses are invalid:",
        },
      },
      navigation: {
        addCustomers: "Add customers",
        forgetUsers: "Forget users",
        referralManagement: "Reward management",
        signups: "Signups",
        transactionTracking: "Transaction tracking",
      },
      referralManagement: {
        history: {
          header: "History",
          tableHeaders: {
            status: "Status",
            uploadedAt: "Uploaded At",
            uploadedBy: "Uploaded By",
            skippedReferrals: "Skipped",
            confirmedReferrals: "Confirmed",
            cancelledReferral: "Cancelled",
            restoredReferrals: "Restored",
          },
        },
        campaignListPlaceholder: {
          withResults: "Select a campaign",
          noResults: "No pending referrals in any campaigns",
        },
        exportButton: {
          loading: "Generating, please wait...",
          text: "Export CSV",
          errorSubmittedMessage: "There was an error processing the export, please try again.",
        },
        fileImport: {
          buttonLabel: "Import CSV",
          checkboxLabel: "Validate reward categories?",
        },
        header: "Reward Management",
        referralCount: "{{count}} pending referrals",
        referralCount_one: "{{count}} pending referral",
        stepFirstHeader: "Step One",
        stepFirstSubtitle:
          "This CSV contains all of your referrals which are currently in a pending state. Previously confirmed or cancelled referrals will not appear here.",
        stepSecondHeader: "Step 2 - Change status",
        stepSecondDescription: {
          updateStatusColumn:
            "Change within the CSV the value of <code>{{statusColumnHeader}}</code> column to:",
          confirmedValueDescription: "to mark it as successful",
          cancelledValueDescription: "to mark it as unsuccessful.",
          unmodifiedRowsNote: "Don't touch records that don't need to be updated.",
        },
        stepThirdHeader: "Step 3 - Optional Columns",
        stepThirdDescription: {
          addRewardCategoryColumn: [
            "Add a Reward Category<br>",
            "Add an additional <code>{{rewardCategoryColumnHeader}}</code> column to the csv, placing it <strong>after</strong> the <code>{{statusColumnHeader}}</code> column.",
            "Any rows that don't have a reward category can be left blank for this column.",
          ],
          addClientDataColumns: [
            "Add Client Data<br>",
            "<strong>Add column(s):</strong> Add Client Data column(s) to the CSV after <code>{{statusColumnHeader}}</code> and (optional) <code>{{rewardCategoryColumnHeader}}</code>. The column title should be in the format <code>ClientData_{name}</code> e.g. <code>ClientData_Customer ID</code> for <code>Customer ID</code>.",
            "<strong>Updating data:</strong> To update existing data, make sure the titles of your new <code>Client Data</code> column(s) match any existing ones.",
            "<strong>No data:</strong> Rows without <code>Client Data</code> can be left empty",
            "<strong>Deletion:</strong> It is not possible to delete <code>Client Data</code> using this process.",
          ],
        },
        stepFourthHeader: "Step 4 - Upload",
        stepFourthDescription: {
          requiredColumns: "The CSV file must contain the following two columns:",
          optionalColumns: "The CSV file can also contain an optional third column:",
          requiredColumnSeparator: "or",
        },
        stepFifthHeader: "Step 5 - Complete",
        stepFifthSubtitle:
          "Once the file has been received, the system will process the data, distribute the appropriate rewards to customers.",
        subtitle:
          "Any customer making a successful referral will be marked as pending, so that you're able to ensure that the transaction is genuine before a reward is distributed.",
      },
      signups: {
        campaignSelectPlaceholder: "Select a campaign",
        campaignListPlaceholder: "There are no campaigns for this market",
        fileImport: {
          buttonLabel: "Upload",
          checkboxLabel:
            "Send an invite email to users that haven't signed up yet (only send invite emails if users have agreed to receive marketing emails)",
        },
        header: "Signups",
        history: {
          header: "History",
          tableHeaders: {
            campaign: "Campaign",
            status: "Status",
            uploadedAt: "Uploaded At",
            uploadedBy: "Uploaded By",
            successfulImports: "Successful import(s)",
            failedImports: "Failed import(s)",
          },
        },
        modal: {
          optional: {
            clientDataDescription:
              'Extra client specified metadata associated with the referrer signed up to the campaign, e.g. "ClientData_customer_id", "ClientData_phone_number". Always optional.',
            customFieldDescription:
              "If you are using a single custom field to identify a customer, then this field may contain content such as a membership ID or a phone number. If a user is required to enter this value during registration then it is required in this file.",
            customFieldsDescription:
              'If you are using multiple custom fields to identify a customer, then those fields may contain content such as a membership ID and a phone number, e.g. "CustomField_phone_number", "CustomField_membership_number". If a user is required to enter those values during registration then they are required in this file.',
            header: "Optional or required pending campaign configuration",
            lastNameDescription: 'The person\'s last name, e.g. "Smith". Always optional.',
            localeDescription:
              "The locale of the customer. When a customer receives any emails related to the referral campaign, it will be displayed in the locale provided here. This is optional. If one is not provided, it will use the default for the market.",
          },
          required: {
            emailDescription: 'The person\'s email address, e.g. "john@doe.com".',
            firstNameDescription: 'The person\'s first name, e.g. "Jane".',
          },
          response: {
            dashboardUrlDescription: "Each person's unique dashboard URL",
            header:
              "When submitted, you will receive an updated CSV containing the following additional columns:",
            shareUrlDescription: "Each person's unique share URL",
            statusDescription:
              "Specifies whether a signup has been created or a reason why one hasn't. This could be because they are already signed up to the campaign or have been signed up through the signup API already (in which case the existing Share URL is what is included).",
          },
          openButtonText: "What are the required column headers?",
          title: "Required column headers",
        },
        subtitle: "Upload a CSV containing details of the people whom you'd like to be signed up.",
        limit: "There is a limit of 200,000 rows per upload.",
      },
      transactionTracking: {
        fileImport: {
          buttonLabel: "Import Transactions",
          checkboxLabel: "Don't validate eligibility for referrals",
        },
        header: "Transaction Tracking",
        history: {
          header: "History",
          tableHeaders: {
            campaign: "Campaign",
            referrals: "Referrals",
            rejected: "Rejected",
            status: "Status",
            successful: "Successful",
            transactions: "Transactions",
            unsuccessful: "Unsuccessful",
            uploadedAt: "Uploaded At",
            uploadedBy: "Uploaded By",
          },
        },
        modal: {
          clientDataContent:
            "Extra client specified metadata associated with the customer who completed the transaction e.g. ClientData_mobile_number, ClientData_membership_number",
          customFieldContent:
            "If you are using a single custom field to identify friends, then this field may contain content such as a membership ID or a phone number. **Note:** if the friend already has a custom field value this will replace it, leaving this column blank will leave the old existing value in place. E.g. 07123456789",
          customFieldsContent:
            'If you are using multiple custom fields to identify friends, then those fields may contain content such as a membership ID and a phone number, e.g. "CustomField_phone_number", "CustomField_membership_number".',
          emailAddressContent: 'The email address of the customer e.g. "jane@smith.com"',
          fullNameHContent:
            'The full name of the customer. Format as "FirstName, LastName", e.g. "Jane, Smith"',
          localeContent:
            "The preferred locale of the customer - only applicable for multi-language markets",
          marketingOptinContent:
            "Whether the customer has opted into marketing emails, i.e. true or false. If omitted, the value defaults to false.",
          orderNumberContent:
            "This value can be any unique identifier you use to identify transactions, e.g. TRANSACTION12345678",
          orderValueContent:
            "The value of the transaction. Exclude currency symbols; format as XXX.YY, e.g. 12.89",
          orderTimestampContent:
            "When the transaction was completed, as a UNIX timestamp, e.g. 1321009871 (date should be no more than four years prior to tracking date)",
          optionalText: "Optional",
          title: "Required column headers",
          referrerEmailContent:
            'The email address of the referrer e.g. "john@doe.com" (only required if there is no VoucherCode)',
          voucherCodeContent:
            "The code allocated to the customer or used by the customer at check-out (only required if there is no ReferrerEmail)",
          rewardCategory:
            "If the campaign is set up with reward categories - i.e. to issue different types of reward for different types of transaction, you can enter the reward category here",
        },
        showRequiredModalColumnsButtonText: "What are the required column headers?",
        subtitle:
          "Each row in the CSV must include at least the voucher code used by the customer or the referrer's email address.",
      },
      textArea: {
        noLabelText: "no-label",
      },
    },
    campaign: {
      editor: {
        modalTitle: "Campaign: {{campaignName}}",
      },
      header: "Campaign",
      missingCustomFieldText: "Not provided",
      referredByDateLabel: "Referral Started",
      referredByFieldLabel: "Referred By",
      revalidateButtonText: "Revalidate",
      subheader: "Date Registered",
      validator: {
        buttonLabel: "Submit",
        errorMessage: "Failed as the user is not in the correct state.",
        formPrompt: "Please be aware that this may take some time to process.",
        modalTitle: "Submit for revalidation",
      },
    },
    countries: countryCodeObject.reduce(
      (array, { name }) => ({
        ...array,
        [name]: [...(array[name] || []), name][0],
      }),
      {}
    ),
    cis: {
      headerText: "Profile",
      noResultErrorMessage: "Failed to retrieve CIS Profile",
      retryButtonText: "Retry",
    },
    components: {
      textfield: {
        characters: "{{count}} characters",
        characters_one: "{{count}} character",
      },
      mobileNumberDropdown: {
        flagAltText: "Flag of {{country}}",
      },
      copyText: {
        tooltipMessage: "Copy link",
        notificationMessage: "Link copied!",
      },
      openNewTab: {
        tooltipMessage: "This will take you to your Partner Marketing Dashboard",
      },
      multiSelectFilter: {
        header: "Filter list",
        allSelected: "All Selected",
        countSelected: "{{count}} Selected",
      },
      checkboxDropdown: {
        noOptions: "No options",
      },
    },
    cta: {
      changeMarket: "Change market",
      signOut: "Sign Out",
    },
    fileImport: {
      fileSelect: {
        buttonText: "Browse",
        infoText: "Upload CSV file",
      },
    },
    fileSelect: {
      buttonText: "Browse",
      infoText: "Select a file",
    },
    forms: {
      errors: {
        generic: {
          invalid: "There was a problem submitting this form, please try again later.",
        },
        imageUpload: {
          format: "Please upload image in png or jpeg format",
          size: "Please upload a smaller image",
        },
        referral: {
          unsuccessful: "The creation of the referral was unsuccessful.",
          pending_count_unsuccessful: "Unable to get pending referral count by campaign.",
          duplicate_order: "This order is a duplicate",
          self_referral: "Referrers cannot refer themselves",
          no_referrer: "This referrer was not found",
          existing_referrer: "This campaign doesn't allow existing customers to be referred",
          referral_limit_reached: "This referrer has reached their referral limit",
          unique_referred_friends_reached: "No more than 5 unique friends can be referred",
        },
        businessName: {
          filled: "Please enter the business or brand name",
        },
        mobile: {
          filled: "Please enter the phone number",
          invalid: "Please enter a valid phone number",
        },
        mobileCountryCode: {
          filled: "Please enter the country code",
        },
        email: {
          format: "The email address you entered isn't valid. Please ensure it is correct.",
          not_unique: "This email already exists in another program. Please use a different one.",
          filled: "Please enter the email address",
        },
        confirmationCode: {
          invalid: "The code you entered isn't correct. Please check your email and try again.",
        },
        passwordConfirmation: {
          doesnt_match: "The passwords you entered do not match, please ensure they are correct.",
        },
        password: {
          invalid: passwordFormatError,
          format: passwordFormatError,
        },
        firstName: {
          min_size: "This name isn't long enough.",
          filled: "Please enter the first name",
          invalid: "Are you sure that you've entered your name correctly?",
        },
        lastName: {
          filled: "Please enter the last name",
          invalid: "Are you sure that you've entered your name correctly?",
        },
        signupReason: {
          key: "Please enter additional notes or information",
        },
        audienceSize: {
          int: "Please enter a number",
        },
        customField: {
          format: "This is not in the correct format.",
          filled: "You must enter a value",
          invalid: "This value is not accepted",
        },
        testInputId: {
          filled: "You must enter a value",
          format: "The value isn't in the correct format",
        },
        testAnotherInputId: {
          filled: "You must enter another value",
          format: "The value is in the wrong format",
        },
        campaign_id: {
          filled: "You must select a campaign",
        },
        campaignId: {
          key: "Please select a campaign",
        },
        registration: {
          not_allowed: "User is not allowed to register as a referrer.",
        },
        termsOptIn: {
          filled: "You must agree with the terms",
        },
        emailComplianceOptIn: {
          filled: "You must agree with the email compliance terms",
        },
        marketingOptIn: {
          filled: "You must agree with the marketing terms",
        },
        fileImport: {
          empty: "CSV file is empty",
          missing_headers:
            "CSV is missing headers, please review required columns above and include those as headers",
          unprocessable:
            "CSV is invalid, please review required columns above and resubmit the file",
          upload_error: "The upload service returned an error. Please try again later",
          included_in: "There was a problem submitting this form, please try again later",
          data_errors: "There was a problem with the CSV data and the file was not uploaded.",
        },
        textImport: {
          empty: "Value is empty",
          missing_headers: "There was a problem submitting this form, please try again later",
          data_errors: "There was a problem with the import data.",
        },
        bulkActions: {
          load_history: "There was a problem loading history. Please try again later",
        },
        expiryDate: {
          date: "Please enter a valid date",
          invalid: "Please ensure date is in the future and try again",
        },
        audienceLocation: {
          min_size: "Please select at least one location",
        },
        partnerAssets: {
          partnerAssets: "There was a problem submitting this form, please try again later",
          characterLimit: "Character limit reached",
        },
        partnerRewards: {
          setRewardsError: "There was a problem setting rewards, please try again later",
          getAvailableRewardsError:
            "There was a problem getting available rewards, please try again later",
        },
        locale: {
          type: "Please select a language",
          filled: "Please select a language",
        },
      },
      labels: {
        partner: {
          businessName: "Partner business or brand name",
          firstName: "Contact first name",
          lastName: "Contact last name",
          email: "Contact email address",
          mobile: "Contact phone number",
          jobTitle: "Contact job title",
          industrySector: "Industry sector",
          language: "Language",
          businessCountry: "Partner's location",
          twitter: "Partner's Twitter",
          instagram: "Partner's Instagram",
          facebook: "Partner's Facebook",
          linkedin: "Partner's LinkedIn",
          snapchat: "Partner's Snapchat",
          youtube: "Partner's YouTube",
          website: "Partner's website",
          signupReason: "Additional information or notes about the partner",
          audienceSize: "Partner's audience size",
          audienceLocation: "Where is the partner's audience?",
          worldSector: "Select a world sector",
        },
        businessName: "Business or brand name",
        firstName: "First name",
        lastName: "Last name",
        email: "Email address",
        orderNumber: "Order number",
        rewardCategory: "Reward category",
        noRewardCategory: "No reward category",
        selectRewardCategory: "Select a reward category",
        mobile: "Phone number",
        expiryDate: "Enter new expiry date",
        mandatoryField: "* indicates required information",
        campaign: "Select campaign",
        campaignPlaceholder: "Select a campaign",
      },
      headers: {
        addPartner: "Add Partner",
        editProfile: "Edit partner profile",
      },
      placeholders: {
        select: "Select an option",
      },
      hints: {
        expiryDate: "Format: dd/mm/yyyy",
      },
      infoText: {
        twitter: "Please enter the partner's Twitter username",
        instagram: "Please enter the partner's Instagram username",
        facebook: "Please enter the partner's Facebook username",
        linkedin: "Please enter the partner's LinkedIn username",
        youtube: "Please enter the partner's YouTube username",
        snapchat: "Please enter the partner's Snapchat username",
        signupReason: "Enter any additional useful information or notes about the partner",
        audienceSize: "What is the size of the partner's audience across all touchpoints",
        audienceLocation: "Where is the partner's audience primarily located?",
      },
    },
    fulfilments: {
      header: "Fulfilments",
      tableHeaders: {
        name: "name",
        dateEarned: "date earned",
        source: "source",
        expiryDate: "expiry date",
        status: "status",
      },
      source: {
        referral: "Referral",
      },
      infoBox: "Any time you see the <1></1> icon you can hover over it to learn more",
      actions: {
        errorRewardEmailResenMessage: "There was a problem sending the email for this reward",
        resentOption: "Resend reward email",
        successRewardEmailResentMessage: "Reward email resent",
        changeExpiry: "Change expiry",
        expire: "Expire fulfilment",
        unexpire: "Un-expire fulfilment",
      },
      changeExpiryDateText: "Change expiry",
      unexpireModalText: "Unexpire",
      expireModalText: "Are you sure?",
      expireModalSubtitle:
        "If you click on expire, the fulfilment related to this referral will be expired.",
      expireModalButton: "Expire",
      notifications: {
        successfulExpiryDateUpdate: "Expiry date has been extended",
        successfulExpiry: "Fulfilment has been expired",
        expiryErrorMessage: "There was a problem updating this fulfilment",
      },
      paymentProviderStatuses: {
        PAYPAL_BLOCKED: "This payout request has been blocked.",
        PAYPAL_CURRENCY_NOT_SUPPORTED_FOR_RECEIVER:
          "This currency cannot be accepted for this recipient's account. You can resend this payout with a different currency.",
        PAYPAL_FAILED: "This payout request has failed, so funds were not deducted.",
        PAYPAL_INVALID_EMAIL: "Invalid Email",
        PAYPAL_ONHOLD: "This payout request is being reviewed and is on hold.",
        PAYPAL_PENDING: "This payout request was received and will be processed.",
        PAYPAL_PENDING_RECIPIENT_NON_HOLDING_CURRENCY_PAYMENT_PREFERENCE:
          "This payout is pending because the recipient has set their account preferences to review credits in this currency. The recipient has been notified. Check back later for the status of this payout.",
        PAYPAL_RECEIVER_ACCOUNT_LIMITATION:
          "Reason: The recipient's account is currently under limitation. Any payments made to this account will be on hold until the issue is resolved.",
        PAYPAL_RECEIVER_ACCOUNT_LOCKED:
          "Reason: We were not able to send a payout because the recipient's account is inactive or restricted.",
        PAYPAL_RECEIVER_COUNTRY_NOT_ALLOWED:
          "Reason: We can't send this payout because the recipient lives in a country where payouts are not allowed.",
        PAYPAL_RECEIVER_STATE_RESTRICTED:
          "Reason: We can't send this payout because the recipient lives in a state where payouts are not allowed.",
        PAYPAL_RECEIVER_UNCONFIRMED:
          "Reason: The recipient's email or phone number is unconfirmed. Any payments made to this account will be marked as Processing until the recipient confirms their account information.",
        PAYPAL_RECEIVER_UNREGISTERED:
          "Reason: The recipient of this payout does not have an account. A link to sign up for an account was sent to the recipient.",
        PAYPAL_RECEIVER_YOUTH_ACCOUNT:
          "Reason: We were not able to send a payout because the recipient has a youth account.",
        PAYPAL_RECEIVING_LIMIT_EXCEEDED:
          "Reason: The recipient cannot accept this payout, because it exceeds the amount they can receive at this time.",
        PAYPAL_REGULATORY_BLOCKED:
          "This transaction is blocked due to regulatory compliance restrictions.",
        PAYPAL_RETURNED:
          "The recipient has not claimed this payout, so the funds have been returned.",
        PAYPAL_REFUNDED: "This payout request was refunded.",
        PAYPAL_REVERSED: "This payout request was reversed.",
        PAYPAL_RISK_DECLINE: "Reason: This payout was declined due to PayPal risk concerns.",
        PAYPAL_UNCLAIMED:
          "The recipient of this payout does not have a PayPal account. A link to sign up for a PayPal account was sent to the recipient.",
        HYPERWALLET_CREATED: "This payout request was received and will be processed.",
        HYPERWALLET_SCHEDULED: "This payout request was received and will be processed.",
        HYPERWALLET_PENDING_ACCOUNT_ACTIVATION: "Pending account activation.",
        HYPERWALLET_PENDING_ID_VERIFICATION: "Pending ID verification.",
        HYPERWALLET_PENDING_TAX_VERIFICATION: "Pending tax verification.",
        HYPERWALLET_PENDING_TRANSFER_METHOD_ACTION: "Pending transfer method action",
        HYPERWALLET_PENDING_TRANSACTION_VERIFICATION: "Pending transaction verification",
        HYPERWALLET_IN_PROGRESS: "This payout request was received and will be processed.",
        HYPERWALLET_UNCLAIMED: "Unclaimed",
        HYPERWALLET_CANCELLED: "Cancelled",
        HYPERWALLET_FAILED: "This payout request has failed, so funds were not deducted.",
        HYPERWALLET_RECALLED: "Recalled",
        HYPERWALLET_RETURNED:
          "The recipient has not claimed this payout, so the funds have been returned.",
        HYPERWALLET_EXPIRED: "Expired",
        HYPERWALLET_CONSTRAINT_VIOLATIONS:
          "The request includes values that don't meet our requirements.",
      },
    },
    global: {
      add: "Add",
      back: "Back",
      cancel: "Cancel",
      campaigns: "Campaigns",
      close: "Close",
      confirm: "Confirm",
      edit: "Edit",
      email: "Email",
      error: "Error",
      customers: "Customers",
      download: "Download",
      no: "No",
      none: "None",
      ok: "Ok",
      or: "or",
      referrals: "Referrals",
      reporting: "Reporting",
      integrations: "Integrations",
      save: "Save",
      search: "Search",
      submitted: "Submitted",
      success: "Success",
      undo: "Undo",
      yes: "Yes",
      apply: "Apply",
      all: "All",
      clearAll: "Clear All",
      expandSection: "Expand section",
      collapseSection: "Collapse section",
      filter: "Filter:",
      selectAll: "Select all",
      deselectAll: "Deselect all",
    },
    industrySector: [
      "Abrasives and Nonmetallic Minerals Manufacturing",
      "Accomodation Services",
      "Accounting",
      "Administration of Justice",
      "Administrative and Support Services",
      "Advertising Services",
      "Agricultural Chemical Manufacturing",
      "Agriculture, Construction, Mining Machinery Manufacturing",
      "Air, Water, and Waste Program Management",
      "Airlines and Aviation",
      "Alternative Dispute Resolution",
      "Alternative Medicine",
      "Ambulance Services",
      "Amusement Parks and Arcades",
      "Animal Feed Manufacturing",
      "Animation and Post-production",
      "Apparel Manufacturing",
      "Appliances, Electrical, and Electronics Manufacturing",
      "Architectural and Structural Metal Manufacturing",
      "Architecture and Planning",
      "Armed Forces",
      "Artificial Rubber and Synthetic Fiber Manufacturing",
      "Artists and Writers",
      "Audio and Video Equipment Manufacturing",
      "Automation Machinery Manufacturing",
      "Aviation and Aerospace Component Manufacturing",
      "Baked Goods Manufacturing",
      "Banking",
      "Bars, Taverns, and Nightclubs",
      "Bed-and-Breakfasts, Hostels, Homestays",
      "Beverage Manufacturing",
      "Biomass Electric Power Generation",
      "Biotechnology Research",
      "Blogs",
      "Boilers, Tanks, and Shipping Container Manufacturing",
      "Book and Periodical Publishing",
      "Book Publishing",
      "Breweries",
      "Broadcast Media Production and Distribution",
      "Building Construction",
      "Building Equipment Contractors",
      "Building Finishing Contractors",
      "Building Structure and Exterior Contractors",
      "Business Consulting and Services",
      "Business Content",
      "Business Intelligence Platforms",
      "Business Skills Training",
      "Cable and Satellite Programming",
      "Capital Markets",
      "Caterers",
      "Chemical Manufacturing",
      "Chemical Raw Materials Manufacturing",
      "Child Day Care Services",
      "Chiropractors",
      "Circuses and Magic Shows",
      "Civic and Social Organizations",
      "Civil Engineering",
      "Claims Adjusting, Actuarial Services",
      "Clay and Refractory Products Manufacturing",
      "Coal Mining",
      "Collection Agencies",
      "Commercial and Industrial Equipment Rental",
      "Commercial and Industrial Machinery Maintenance",
      "Commercial and Service Industry Machinery Manufacturing",
      "Communications Equipment Manufacturing",
      "Community Development and Urban Planning",
      "Community Services",
      "Computer and Network Security",
      "Computer Games",
      "Computer Hardware Manufacturing",
      "Computer Networking Products",
      "Computers and Electronics Manufacturing",
      "Conservation Programs",
      "Construction",
      "Construction Hardware Manufacturing",
      "Consumer Goods Rental",
      "Consumer Services",
      "Correctional Institutions",
      "Cosmetology and Barber Schools",
      "Courts of Law",
      "Credit Intermediation",
      "Cutlery and Handtool Manufacturing",
      "Dairy Product Manufacturing",
      "Dance Companies",
      "Data Infrastructure and Analytics",
      "Data Security Software Products",
      "Defense and Space Manufacturing",
      "Dentists",
      "Design Services",
      "Desktop Computing Software Products",
      "Distilleries",
      "E-Learning Providers",
      "Economic Programs",
      "Education",
      "Education Administration Programs",
      "Electric Lighting Equipment Manufacturing",
      "Electric Power Generation",
      "Electric Power Transmission, Control, and Distribution",
      "Electrical Equipment Manufacturing",
      "Electronic and Precision Equipment Maintenance",
      "Embedded Software Products",
      "Emergency and Relief Services",
      "Engines and Power Transmission Equipment Manufacturing",
      "Entertainment Providers",
      "Environmental Quality Programs",
      "Environmental Services",
      "Equipment Rental Services",
      "Events Services",
      "Executive Offices",
      "Executive Search Services",
      "Fabricated Metal Products",
      "Facilities Services",
      "Family Planning Centers",
      "Farming",
      "Farming, Ranching, Forestry",
      "Fashion Accessories Manufacturing",
      "Financial Services",
      "Fine Arts Schools",
      "Fire Protection",
      "Fisheries",
      "Flight Training",
      "Food and Beverage Manufacturing",
      "Food and Beverage Retail",
      "Food and Beverage Services",
      "Footwear and Leather Goods Repair",
      "Footwear Manufacturing",
      "Forestry and Logging",
      "Fossil Fuel Electric Power Generation",
      "Freight and Package Transportation",
      "Fruit and Vegetable Preserves Manufacturing",
      "Fundraising",
      "Funds and Trusts",
      "Furniture and Home Furnishings Manufacturing",
      "Gambling Facilities and Casinos",
      "Geothermal Electric Power Generation",
      "Glass Product Manufacturing",
      "Glass, Ceramics and Concrete Manufacturing",
      "Golf Courses and Country Clubs",
      "Government Administration",
      "Government Relations Services",
      "Graphic Design",
      "Ground Passenger Transportation",
      "Health and Human Services",
      "Higher Education",
      "Highway, Street, and Bridge Construction",
      "Historical Sites",
      "Holding Companies",
      "Home Health Care Services",
      "Horticulture",
      "Hospitality",
      "Hospitals",
      "Hospitals and Health Care",
      "Hotels and Motels",
      "Household and Institutional Furniture Manufacturing",
      "Household Appliance Manufacturing",
      "Household Services",
      "Housing and Community Development",
      "Housing Programs",
      "Human Resources Services",
      "HVAC and Refrigeration Equipment Manufacturing",
      "Hydroelectric Power Generation",
      "Individual and Family Services",
      "Industrial Machinery Manufacturing",
      "Industry Associations",
      "Information and Cultural Products",
      "Information Services",
      "Insurance",
      "Insurance Agencies and Brokerages",
      "Insurance and Employee Benefit Funds",
      "Insurance Carriers",
      "Interior Design",
      "International Affairs",
      "International Trade and Development",
      "Internet Marketplace Platforms",
      "Internet News",
      "Internet Publishing",
      "Interurban and Rural Bus Services",
      "Investment Advice",
      "Investment Banking",
      "Investment Management",
      "IT Services and IT Consulting",
      "IT System Custom Software Development",
      "IT System Data Services",
      "IT System Design Services",
      "IT System Installation and Disposal",
      "IT System Operations and Maintenance",
      "IT System Testing and Evaluation",
      "IT System Training and Support",
      "Janitorial Services",
      "Landscaping Services",
      "Language Schools",
      "Laundry and Drycleaning Services",
      "Law Enforcement",
      "Law Practice",
      "Leasing Non-residential Real Estate",
      "Leasing Real Estate",
      "Leasing Real Estate Agents and Brokers",
      "Leasing Residential Real Estate",
      "Leather Product Manufacturing",
      "Legal Services",
      "Legislative Offices",
      "Libraries",
      "Lime and Gypsum Products Manufacturing",
      "Loan Brokers",
      "Machinery Manufacturing",
      "Magnetic and Optical Media Manufacturing",
      "Manufacturing",
      "Maritime Transportation",
      "Market Research",
      "Marketing Services",
      "Mattress and Blinds Manufacturing",
      "Measuring and Control Instrument Manufacturing",
      "Meat Products Manufacturing",
      "Media Production",
      "Medical and Diagnostic Laboratories",
      "Medical Equipment Manufacturing",
      "Medical Practices",
      "Mental Health Care",
      "Metal Ore Mining",
      "Metal Treatments",
      "Metal Valve, Ball, and Roller Manufacturing",
      "Metalworking Machinery Manufacturing",
      "Military and International Affairs",
      "Mining",
      "Mobile Computing Software Products",
      "Mobile Food Services",
      "Mobile Gaming Apps",
      "Motor Vehicle Manufacturing",
      "Motor Vehicle Parts Manufacturing",
      "Movie and Video Distribution",
      "Movies and Sound Recording",
      "Museums",
      "Museums, Historical Sites, and Zoos",
      "Musicians",
      "Nanotechnology Research",
      "Natural Gas Distribution",
      "Natural Gas Extraction",
      "Newspaper Publishing",
      "Non-profit Organizations",
      "Nonmetallic Mineral Mining",
      "Nonresidential Building Construction",
      "Nuclear Electric Power Generation",
      "Nursing and Residential Care Facilities",
      "Office Administration",
      "Office Furniture and Fixtures Manufacturing",
      "Oil and Coal Product Manufacturing",
      "Oil and Gas",
      "Oil Extraction",
      "Oil, Gas, and Mining",
      "Online and Mail Order Retail",
      "Online Audio and Video Media",
      "Operations Consulting",
      "Optometrists",
      "Outpatient Care Centers",
      "Outsourcing and Offshoring Consulting",
      "Packaging and Containers Manufacturing",
      "Paint, Coating, and Adhesive Manufacturing",
      "Paper and Forest Product Manufacturing",
      "Pension Funds",
      "Performing Arts",
      "Performing Arts and Spectator Sports",
      "Periodical Publishing",
      "Personal and Laundry Services",
      "Personal Care Product Manufacturing",
      "Personal Care Services",
      "Pet Services",
      "Pharmaceutical Manufacturing",
      "Philanthropic Fundraising Services",
      "Photography",
      "Physical, Occupational and Speech Therapists",
      "Physicians",
      "Pipeline Transportation",
      "Plastics and Rubber Product Manufacturing",
      "Plastics Manufacturing",
      "Political Organizations",
      "Postal Services",
      "Primary and Secondary Education",
      "Primary Metal Manufacturing",
      "Printing Services",
      "Professional and Technical Services",
      "Professional Organizations",
      "Public Assistance Programs",
      "Public Health",
      "Public Policy Offices",
      "Public Relations and Communications Services",
      "Public Safety",
      "Racetracks",
      "Radio and Television Broadcasting",
      "Rail Transportation",
      "Railroad Equipment Manufacturing",
      "Ranching",
      "Ranching and Fisheries",
      "Real Estate and Equipment Rental Services",
      "Recreational Facilities",
      "Religious Institutions",
      "Renewable Energy Semiconductor Manufacturing",
      "Repair and Maintenance",
      "Research Services",
      "Residential Building Construction",
      "Restaurants",
      "Retail",
      "Retail Apparel and Fashion",
      "Retail Appliances, Electrical, and Electronic Equipment",
      "Retail Art Supplies",
      "Retail Books and Printed News",
      "Retail Building Materials and Garden Equipment",
      "Retail Florists",
      "Retail Furniture and Home Furnishings",
      "Retail Gasoline",
      "Retail Groceries",
      "Retail Health and Personal Care Products",
      "Retail Luxury Goods and Jewelry",
      "Retail Motor Vehicles",
      "Retail Musical Instruments",
      "Retail Office Equipment",
      "Retail Office Supplies and Gifts",
      "Retail Recyclable Materials &amp; Used Merchandise",
      "Reupholstery and Furniture Repair",
      "Rubber Products Manufacturing",
      "Satellite Telecommunications",
      "Savings Institutions",
      "School and Employee Bus Services",
      "Seafood Product Manufacturing",
      "Secretarial Schools",
      "Securities and Commodity Exchanges",
      "Security and Investigations",
      "Security Guards and Patrol Services",
      "Security Systems Services",
      "Semiconductor Manufacturing",
      "Services for the Elderly and Disabled",
      "Sheet Music Publishing",
      "Shipbuilding",
      "Shuttles and Special Needs Transportation Services",
      "Sightseeing Transportation",
      "Skiing Facilities",
      "Soap and Cleaning Product Manufacturing",
      "Social Networking Platforms",
      "Software Development",
      "Solar Electric Power Generation",
      "Sound Recording",
      "Space Research and Technology",
      "Specialty Trade Contractors",
      "Spectator Sports",
      "Sporting Goods Manufacturing",
      "Sports and Recreation Instruction",
      "Sports Teams and Clubs",
      "Spring and Wire Product Manufacturing",
      "Staffing and Recruiting",
      "Steam and Air-Conditioning Supply",
      "Strategic Management Services",
      "Subdivision of Land",
      "Sugar and Confectionery Product Manufacturing",
      "Taxi and Limousine Services",
      "Technical and Vocational Training",
      "Telecommunications",
      "Telecommunications Carriers",
      "Telephone Call Centers",
      "Temporary Help Services",
      "Textile Manufacturing",
      "Theater Companies",
      "Think Tanks",
      "Tobacco Manufacturing",
      "Translation and Localization",
      "Transportation Equipment Manufacturing",
      "Transportation Programs",
      "Transportation, Logistics and Storage",
      "Travel Arrangements",
      "Truck Transportation",
      "Trusts and Estates",
      "Turned Products and Fastener Manufacturing",
      "Urban Transit Services",
      "Utilities",
      "Utilities Administration",
      "Utility System Construction",
      "Vehicle Repair and Maintenance",
      "Venture Capital and Private Equity Principals",
      "Veterinary Services",
      "Vocational Rehabilitation Services",
      "Warehousing and Storage",
      "Waste Collection",
      "Waste Treatment and Disposal",
      "Water Supply and Irrigation Systems",
      "Water, Waste, Steam, and Air Conditioning Services",
      "Wellness and Fitness Services",
      "Wholesale",
      "Wholesale Alcoholic Beverages",
      "Wholesale Apparel and Sewing Supplies",
      "Wholesale Appliances, Electrical, and Electronics",
      "Wholesale Building Materials",
      "Wholesale Chemical and Allied Products",
      "Wholesale Computer Equipment",
      "Wholesale Drugs and Sundries",
      "Wholesale Food and Beverage",
      "Wholesale Footwear",
      "Wholesale Furniture and Home Furnishings",
      "Wholesale Hardware, Plumbing, Heating Equipment",
      "Wholesale Import and Export",
      "Wholesale Luxury Goods and Jewelry",
      "Wholesale Machinery",
      "Wholesale Metals and Minerals",
      "Wholesale Motor Vehicles and Parts",
      "Wholesale Paper Products",
      "Wholesale Petroleum and Petroleum Products",
      "Wholesale Photography Equipment and Supplies",
      "Wholesale Raw Farm Products",
      "Wholesale Recyclable Materials",
      "Wind Electric Power Generation",
      "Wineries",
      "Wireless Services",
      "Women's Handbag Manufacturing",
      "Wood Product Manufacturing",
      "Writing and Editing",
      "Zoos and Botanical Gardens",
    ],
    worldSector: [
      "Africa",
      "Australasia",
      "East Asia",
      "Europe",
      "Central America",
      "North America",
      "South America",
      "South Asia",
    ],
    languages: {
      ar: "Arabic (ar)",
      ms: "Bahasa Melayu (ms)",
      eu: "Basque (eu)",
      bg: "Bulgarian (bg)",
      ca: "Catalan (ca)",
      cs: "Czech (cs)",
      "zh-CN": "Chinese - Simplified (zh-CN)",
      "zh-TW": "Chinese - Traditional (zh-TW)",
      da: "Danish (da)",
      nl: "Dutch (nl)",
      en: "English (en)",
      "en-US": "English - United States (en-US)",
      fi: "Finnish (fi)",
      fr: "French (fr)",
      "fr-CA": "French - Canadian (fr-CA)",
      gl: "Galician (gl)",
      hu: "Hungarian (hu)",
      de: "German (de)",
      "de-informal": "German - Informal (de-informal)",
      "de-AT-informal": "German - Austrian (de-AT-informal)",
      "de-CH-informal": "German - Swiss (de-CH-informal)",
      el: "Greek (el)",
      id: "Indonesian (id)",
      it: "Italian (it)",
      ja: "Japanese (ja)",
      ko: "Korean (ko)",
      nb: "Norwegian (nb)",
      pl: "Polish (pl)",
      pt: "Portuguese (pt)",
      "pt-BR": "Portuguese - Brazilian (pt-BR)",
      ro: "Romanian (ro)",
      ru: "Russian (ru)",
      es: "Spanish (es)",
      sv: "Swedish (sv)",
      tl: "Tagalog (tl)",
      th: "Thai (th)",
      tr: "Turkish (tr)",
      vi: "Vietnamese (vi)",
    },
    marketSelector: {
      title: "Select a Market",
      textInput: {
        label: "Filter markets",
        placeholder: "Search...",
      },
    },
    orders: {
      header: "Orders",
      tableHeaders: {
        codeUsed: "Code Used",
        madeOn: "Made On",
        referralLinked: "Referral Linked",
        reference: "Reference",
        value: "Value",
      },
    },
    referrals: {
      header: "Referrals made",
      tableHeaders: {
        friendName: "friend name",
        referredOn: "referred on",
        reward: "reward",
        status: "status",
      },
      cancelReferralModalText: "Cancel referral",
      delayedConfirmationModal: {
        cancelReferral: {
          confirmationText: "Yes, Cancel Referral",
          title: "Are you sure you want to cancel this referral?",
          undoMessage: "The cancellation has been undone.",
        },
        closeModalText: "No, Go Back!",
        confirmReferral: {
          confirmationText: "Yes, Confirm Referral",
          content: "This change is irreversible.",
          title: "Are you sure you want to confirm this referral?",
          undoMessage: "The confirmation has been undone.",
        },
        restoreReferral: {
          confirmationText: "Yes, Revert Referral",
          title: "Are you sure you want to revert this referral to a pending status?",
          undoMessage: "The revert has been undone.",
        },
        issueReward: {
          confirmationText: "Yes, Issue Reward",
          title: "Are you sure you want to issue reward for this referral?",
          undoMessage: "Reward has not been issued.",
        },
        selectRewardCategory: "Select a reward category",
        selectRewardType: "Select a reward",
        undoCounterText: "too late!",
      },
      notifications: {
        errorCancelled: "There was a problem cancelling this referral",
        errorConfirming: "There was a problem confirming this referral",
        errorRevertedToPending: "There was a problem reverting this referral to pending",
        successCancelled: "Referral successfully cancelled",
        successConfirmed: "Referral successfully confirmed",
        successRevertedToPending: "Referral successfully reverted to pending",
        noConfirmReferralWarning: "Cannot manually confirm a CIS referral",
        successIssueReward: "Reward successfully issued",
        errorIssueReward: "There was a problem issuing the reward",
      },
      optionsLabels: {
        cancelReferral: "Cancel referral",
        confirmReferral: "Confirm referral",
        noRewardCategory: "No reward category",
        revertToPending: "Revert to pending",
        issueReward: "Issue new reward",
      },
      ineligibilityReasons: {
        ordered_previously: "Ordered previously",
        no_referrer: "No referrer",
        self_referral: "Self referral",
        existing_referrer: "Existing referrer",
        referral_limit_reached: "Referral limit reached",
        friend_voucher_already_redeemed: "Friend voucher already redeemed",
        email_required: "Email required",
        name_required: "Name required",
        terms_opt_in_required: "Terms opt in required",
        unique_referred_friends_reached: "Unique referred friends reached",
      },
    },
    reporting: {
      dashboard: {
        errorLoadingData: "There was a problem loading report data",
        errorTimeLineData: "There was a problem loading timeline data",
        loadingText: "Loading",
        modal: {
          title: "Let us help",
        },
        overview: {
          contactBar: {
            modal: {
              emailSubject: "Get in touch",
              firstParagraph:
                "Buyapowa's account managers are experts in advocacy and referral marketing, and they're here to help your campaign achieve its full potential.",
              reachOutAt: "Reach out at",
              secondParagraph:
                "They'll be happy to discuss anything from <strong>fine-tuning your offer, promoting your campaign</strong> and our <strong>Signup API</strong>, all the way through to <strong>design tips, psychological insights</strong> and more.",
            },
            firstParagraph: "Get in touch with your success manager",
            secondParagraph: "We can help you improve these numbers",
          },
          legendText: {
            first: "Your existing customers",
            second: "Your customers' friends",
          },
          metricColumns: {
            confirmedReferrals: "Confirmed referrals",
            confirmedReferralsTooltipContent:
              "The number of referrals that were confirmed within the selected period. Rewards are only paid after a referral is confirmed.",
            newReferrerRegistrations: "New referrer registrations",
            newReferrerRegistrationsTooltipContent:
              "The number of customers who registered for your referral programme in the selected period.",
            pendingReferrals: "Pending referrals",
            pendingReferralsTooltipContent:
              "The number of pending referrals that were created within the selected period. A pending referral requires further validation before it becomes a confirmed referral.",
            sharers: "Sharers",
            sharersTooltipContent:
              "For a referrer to count as a sharer during the selected period, they had to press a share button or copy the link",
            title: "Between",
            uniqueFriendVisits: "Unique friend visits",
            uniqueFriendVisitsTooltipContent:
              "The number of friends who visited your programme after being invited by a registered referrer",
          },
          totalConfirmedReferrals: "Total confirmed referrals",
          totalRegisteredReferrers: "Total registered referrers",
          totals: {
            since: "Since",
          },
        },
        performanceTiles: {
          confirmedReferrals: "Confirmed referrals",
          confirmedReferralsContent:
            "This is the number of referred customers that were confirmed as referrals (based on your business rules) during the select date range.",
          confirmedReferralsPerSharer: "Confirmed referrals per sharer",
          confirmedReferralsPerSharerContent:
            "This is the ratio between the number of referrals that were confirmed during this period and the number of customers who shared at least once.",
          friendVisitsPerSharer: "Friend visits per sharer",
          friendVisitsPerSharerContent:
            "This is the ratio between the number of visits from friends (people your customers invited) and customers who shared at least once.",
          friendVisitsToConfirmedReferral: "Friend visits to confirmed referral",
          friendVisitsToConfirmedReferralContent:
            "This is the percentage of friends (people your customers invited) that became confirmed referrals during the selected period.",
          referrersWhoShared: "Referrers who shared",
          referrersWhoSharedContent:
            "Of all the customers who signed up to your referral programme, this is the percentage who then went on to share with their friends at least once. For them to count as a sharer, they have to had pressed the share button or copied the link.",
        },
        tablist: {
          overview: "Overview",
          timeline: "Timeline",
        },
        topbar: {
          buttonDownloadReportLoadingText: "Generating, please wait...",
          buttonDownloadReportText: "Download",
          campaignSelector: {
            campaignHandlerTitle: "Campaign",
            deselectAll: "Deselect all",
            campaignSelectorText: {
              multiple: "Multiple Selected",
              none: "Select a campaign",
            },
          },
          dateRangeSelector: {
            applyLinkText: "Apply",
            customRangeSelector: {
              title: "Selected range",
              calendarMonth: {
                sunday: "S",
                monday: "M",
                tuesday: "T",
                wednesday: "W",
                thursday: "T",
                friday: "F",
                saturday: "S",
              },
            },
            helpText: {
              firstLine:
                "Select a custom date range or one of the predefined options on the right.",
              secondLine:
                " If your campaign is new, the data may take a little while to normalise.",
            },
            presetsSelector: {
              lastMonth: "Last month",
              lastSevenDays: "Last 7 days",
              lastThirtyDays: "Last 30 days",
              lastYear: "Last year",
              thisMonth: "This month",
              thisYear: "This year",
              today: "Today",
              yesterday: "Yesterday",
            },
          },
          downloadErrorContent:
            "The download took too long and failed to finish. Please select a smaller date range or try again.",
          downloadErrorTitle: "The download failed",
          tooltipContent: "This report will contain the data from the period you have selected",
        },
      },
      navigation: {
        noMobileWarningTitle: "Reports are not accessible on mobile devices.",
        noMobileWarningSubtitle: "Please re-visit this page using your desktop or laptop.",
        operationalReports: "Operational Reports",
        reportingDashboard: "Dashboard",
        partnerReports: "Partner Marketing",
        referrerReports: "Referral Marketing",
        rewardReports: "Reward Payout",
      },
      management: {
        downloadCard: {
          buttonGenerateReport: {
            loading: "Generating, please wait...",
            hasReport: "Download",
            noReport: "Generate & Download",
          },
          generatedText: "Last generated",
          neverGenaratedText:
            "Never generated before. Select a date range, then click to 'Generate & Download'. If the download fails try to reduce the date range.",
          generateAgainButtonText: "Generate Again",
          changeDateText:
            "To view different dates, select a new date range, then click 'Generate Again'. If the download fails, try to reduce the date range.",
          errorSubmittedMessage:
            "Please ensure that the start date is before the end date and the range is within the allowed limits of the report.",
          dateText: "Date",
        },
        header: "Operational Reports",
        navigation: {
          partner: {
            allDistributedVouchers: "All distributed vouchers",
            allDistributedVouchersDescription:
              "A list of every voucher distributed from this campaign.",
            allDistributedVouchersRecommendedRange: "Recommended range 1 year",
            customerSignups: "Customer signups",
            customerSignupsDescription:
              "A full list of every customer who has signed up to become a referrer, includes marketing opt-in details, share URLs and any custom fields.",
            customerSignupsRecommendedRange: "Recommended range 1 year",
            friends: "Referees",
            friendsDescription:
              "All referees who filled in their details on the referee landing page (if applicable), plus referees who have transacted on your site via a referral.",
            friendsRecommendedRange: "Recommended range 90 days",
            friendVouchers: "Referee vouchers",
            friendVouchersDescription:
              "To retrieve all the codes which have been distributed to an audience, including post-referral vouchers.",
            friendVouchersRecommendedRange: "Recommended range 90 days",
            optOuts: "Opt-outs",
            optOutsDescription: "A list of all users who have unsubscribed from emails.",
            optOutsRecommendedRange: "Recommended range 1 year",
            fulfilments: "Fulfilments",
            fulfilmentsDescription:
              "Retrieve all fulfilments that have been completed in the given time range.",
            fulfilmentsRecommendedRange: "Recommended range 90 days",
            rewardChoiceFulfilments: "Reward Choice Fulfilments",
            rewardChoiceFulfilmentsDescription:
              "Retrieve status and detail of all fulfilments created in the given time range.",
            rewardChoiceFulfilmentsRecommendedRange: "Recommended range 90 days",
            rewardStreamLegacyReferralData: "RewardStream legacy referral data",
            rewardStreamLegacyReferralDataDescription:
              "A list of referrals (similar to who referred whom report) formatted in a way that closely matches what was available in RewardStream.",
            rewardStreamLegacyReferralDataRecommendedRange: "Recommended range 90 days",
            topReferrers: "Top partners",
            topReferrersDescription:
              "A list of the top 100 partners for the campaign, ordered by the number of referrals.",
            topReferrersRecommendedRange: "Recommended range 1 year",
            whoReferredWhom: "Who referred whom",
            whoReferredWhomDescription:
              "Retrieve a list of all referrals, status of referral, the date that records were last updated and whether the referral was via the pixel or a voucher.",
            whoReferredWhomRecommendedRange: "Recommended range 90 days",
          },
          referrer: {
            allDistributedVouchers: "All distributed vouchers",
            allDistributedVouchersDescription:
              "A list of every voucher distributed from this campaign.",
            allDistributedVouchersRecommendedRange: "Recommended range 1 year",
            customerSignups: "Customer signups",
            customerSignupsDescription:
              "A full list of every customer who has signed up to become a referrer, includes marketing opt-in details, share URLs and any custom fields.",
            customerSignupsRecommendedRange: "Recommended range 1 year",
            friends: "Friends",
            friendsDescription:
              "All friends who filled in their details on the friend landing page (if applicable), plus friends who have transacted on your site via a referral.",
            friendsRecommendedRange: "Recommended range 90 days",
            friendVouchers: "Friend vouchers",
            friendVouchersDescription:
              "To retrieve all the codes which have been distributed to friends, including post-referral vouchers.",
            friendVouchersRecommendedRange: "Recommended range 90 days",
            optOuts: "Opt-outs",
            optOutsDescription: "A list of all users who have unsubscribed from emails.",
            optOutsRecommendedRange: "Recommended range 1 year",
            fulfilments: "Fulfilments",
            fulfilmentsDescription:
              "Retrieve all fulfilments that have been completed in the given time range.",
            fulfilmentsRecommendedRange: "Recommended range 90 days",
            rewardChoiceFulfilments: "Reward Choice Fulfilments",
            rewardChoiceFulfilmentsDescription:
              "Retrieve status and detail of all fulfilments created in the given time range.",
            rewardChoiceFulfilmentsRecommendedRange: "Recommended range 90 days",
            rewardStreamLegacyReferralData: "RewardStream legacy referral data",
            rewardStreamLegacyReferralDataDescription:
              "A list of referrals (similar to who referred whom report) formatted in a way that closely matches what was available in RewardStream.",
            rewardStreamLegacyReferralDataRecommendedRange: "Recommended range 90 days",
            topReferrers: "Top referrers",
            topReferrersDescription:
              "A list of the top 100 referrers for the campaign, ordered by the number of referrals.",
            topReferrersRecommendedRange: "Recommended range 1 year",
            whoReferredWhom: "Who referred whom",
            whoReferredWhomDescription:
              "Retrieve a list of all referrals, status of referral, the date that records were last updated and whether the referral was via the pixel or a voucher.",
            whoReferredWhomRecommendedRange: "Recommended range 90 days",
          },
          reward: {
            allDistributedVouchers: "All distributed vouchers",
            allDistributedVouchersDescription:
              "A list of every voucher distributed from this campaign.",
            allDistributedVouchersRecommendedRange: "Recommended range 1 year",
            customerSignups: "Customer signups",
            customerSignupsDescription:
              "A full list of every customer who has signed up to become a referrer, includes marketing opt-in details, share URLs and any custom fields.",
            customerSignupsRecommendedRange: "Recommended range 1 year",
            friends: "Payees",
            friendsDescription:
              "All payees who filled in their details on the payee landing page (if applicable), plus payees who have transacted on your site via a referral.",
            friendsRecommendedRange: "Recommended range 90 days",
            friendVouchers: "Payee vouchers",
            friendVouchersDescription:
              "To retrieve all the codes which have been distributed to payees, including post-referral vouchers.",
            friendVouchersRecommendedRange: "Recommended range 90 days",
            optOuts: "Opt-outs",
            optOutsDescription: "A list of all users who have unsubscribed from emails.",
            optOutsRecommendedRange: "Recommended range 1 year",
            fulfilments: "Fulfilments",
            fulfilmentsDescription:
              "Retrieve all fulfilments that have been completed in the given time range.",
            fulfilmentsRecommendedRange: "Recommended range 90 days",
            rewardChoiceFulfilments: "Reward Choice Fulfilments",
            rewardChoiceFulfilmentsDescription:
              "Retrieve status and detail of all fulfilments created in the given time range.",
            rewardChoiceFulfilmentsRecommendedRange: "Recommended range 90 days",
            rewardStreamLegacyReferralData: "RewardStream legacy referral data",
            rewardStreamLegacyReferralDataDescription:
              "A list of referrals (similar to who referred whom report) formatted in a way that closely matches what was available in RewardStream.",
            rewardStreamLegacyReferralDataRecommendedRange: "Recommended range 90 days",
            topReferrers: "Top referrers",
            topReferrersDescription:
              "A list of the top 100 referrers for the campaign, ordered by the number of referrals.",
            topReferrersRecommendedRange: "Recommended range 1 year",
            whoReferredWhom: "Who referred whom",
            whoReferredWhomDescription:
              "Retrieve a list of all referrals, status of referral, the date that records were last updated and whether the referral was via the pixel or a voucher.",
            whoReferredWhomRecommendedRange: "Recommended range 90 days",
          },
        },
        selectTitle: "Campaign",
      },
    },
    rewards: {
      header: "Rewards",
      tableHeaders: {
        address: "Address",
        dateEarned: "Date Earned",
        item: "Item",
        source: "Source",
        status: "Status",
      },
      actions: {
        claimSubmitted: "Claim submitted",
        clickToClaimOption: "Click to claim",
        errorRewardEmailResenMessage: "There was a problem sending the email for this reward",
        errorSubmittedMessage: "There was a problem updating this claim",
        resentOption: "Resend reward email",
        successRewardEmailResentMessage: "Reward email resent",
        successSubmittedMessage: "Claim successfully submitted",
      },
    },
    statuses: {
      cancelled: "cancelled",
      complete: "complete",
      failed: "failed",
      fulfilled: "fulfilled",
      pending: "pending",
      processed: "processed",
      processing: "processing",
      redeemed: "redeemed",
      waiting: "waiting",
      expired: "expired",
      paused: "paused",
    },
    subscriptions: {
      header: "Subscriptions",
      tableHeaders: {
        activationDate: "Activation Date",
        identifier: "Identifier",
        plan: "Plan",
        status: "Status",
      },
    },
    partners: {
      header: "Partners",
      tableHeader: {
        businessName: "name",
        email: "email",
        contactName: "contact name",
      },
      programPerformance: {
        header: "Performance",
        info: "This dashboard shows the performance of all partners",
        tableHeaders: {
          month: "Month",
          visits: "Visits",
          created: "Created",
          confirmed: "Confirmed",
          cancelled: "Cancelled",
          rewardsConfirmed: "Rewards Confirmed",
        },
        tooltips: {
          visits: "How many people have visited your partners' landing page",
          created: "How many referrals were created through your partner(s)",
          confirmed: "How many referrals were confirmed through your partner(s)",
          cancelled: "How many referrals have been cancelled",
        },
        tableTotal: "Total",
        partnerFilter: {
          selectAll: "All Partners",
        },
        downloadCsvButton: {
          loading: "Generating, please wait...",
          text: "Download Report (CSV)",
        },
        partnerErrors: "There was a problem loading filters. Please try again later.",
      },
    },
    addPartner: {
      prompt: "Add partner",
      modal: {
        successMessage: "Partner created successfully",
      },
    },
    editContent: {
      prompt: "Edit content",
      title: "Edit partner content",
      description: "To edit content for multiple partners please select from the list below",
    },
    partnerProfile: {
      profile: "Profile",
      createFormIntro: "Add details of the partner",
      editFormIntro: "Edit details of the partner",
      audienceInfo:
        "This information helps you to understand the size of the partner's audience/contacts",
      formCampaignInfo: "Choose which campaigns you want the partner to be part of",
      contentInfo: "This is where you can tailor the content on the partner's profile page",
      rewardsInfo: "Choose which rewards are available to the partner and their audience",
      campaignsInfo: "See which campaign(s) the partner belongs to",
      createReferral: "Create referral",
      editProfile: {
        prompt: "Edit partner profile",
        successTitle: "Success",
        successMessage: "Success! You have successfully updated the partner profile!",
      },
      editSettings: "Edit settings",
      audience: "Partner's audience",
      firstSeen: "First seen",
      lastSeen: "Last seen",
      dateEnrolled: "Date registered",
      contactName: "Contact name",
      displayName: "Partner display name",
      displayNameTooltip:
        "This is how the partner's business or brand name will be displayed on their profile page.",
      quote: "Partner quote",
      quoteTooltip: "This is the quote that will appear on the partner's profile page",
      offerHeader: "Partner profile page title",
      offerHeaderTooltip: "This is the title that will appear on the partner's profile page",
      assets: "Partner content",
      informationContent: "Profile page content",
      assetsDescription:
        "Complete the partner's profile page by adding the required copy and images",
      bannerImage: "Banner image",
      logoImage: "Logo",
      addAssets: {
        prompt: "Add partner content",
        editPrompt: "Edit partner content",
        sectionOneHeading: "Partner profile page copy",
        sectionOneDescription: "Please add or edit content for the partner's profile page",
        sectionTwoHeading: "Partner profile page images",
        sectionTwoDescription:
          "Please upload a logo and banner image for the partner's profile page",
        logoImporterHeader: "Upload the partner's logo",
        logoImporterInfo: "Dimension recommendations: 48 x 48 pixels",
        bannerImporterHeader: "Upload the partner's banner image",
        bannerImporterInfo: "Dimension recommendations: 350 x 415 pixels",
        importerButtonText: "Browse files",
        successTitle: "The content was published successfully",
        successMessage:
          "You can now view and edit the partner's profile and explore other features",
        viewProfile: "View profile",
        congratulations: "Congratulations!",
        quotePlaceholder: "Please enter a quote from the partner",
        offerHeaderPlaceholder: "Please provide the title for the partner's profile page",
        assetsModal: {
          header: "Add content for",
          notice: "There is no content defined for",
          instruction: "Select the locale to use as a base to start drafting content for",
          copyButton: "Copy from",
        },
      },
      activate: "Activate",
      createReferrals: "Create referrals",
      campaigns: "Active campaign(s)",
      campaignDetail: "Campaign detail",
      campaignName: "Campaign name",
      campaignDateRegistered: "Partner's registration date",
      campaignShareUrlText: "Partner's unique offer link",
      campaignDashboardUrlText: "Partner's dashboard link",
      campaignCopyButtonText: "Copy",
      rewards: {
        title: "Rewards",
        setRewards: "Set rewards",
        editRewards: "Edit rewards",
        errorLoadingRewards: "Error loading rewards, please refresh the page.",
        instructionsTitle: "Manage rewards",
        instructions: "Add, edit or remove rewards.",
        partnerReward: "Partner reward",
        audienceReward: "Audience reward",
        unconfiguredWarning:
          "All reward options are available by default until a selection is made",
        form: {
          partnerRewardLabel: "Partner reward",
          audienceRewardLabel: "Audience reward",
          rewardPlaceholder: "Select reward(s)",
        },
      },
      errorLoadingText: "There was a problem loading partner data",
      loadingText: "This page has a lot of referrals and may take a while to load",
      performance: "Performance",
      performanceSubHeading: "This report shows the Partner's performance",
      downloadPerformanceCsv: "Download Report (CSV)",
    },
    userProfile: {
      confirmAlreadyExistingFriend: {
        content: "Please confirm you'd like to track a new referral against this existing friend",
      },
      createReferralButtonText: "Create referral",
      createReferralModal: {
        title: "Create referral",
        friendExists: "This friend already exists",
      },
      errorLoadingText: "There was a problem loading user data",
      editDetailsText: "Edit details",
      loadingText: "This page has a lot of referrals and may take a while to load",
      identifier: "Identifier",
      issueNewRewardText: "Issue new reward",
      issueNewRewardModal: {
        campaignText: "Campaign",
        errorMessage: "There was a problem creating this reward, please try again later",
        errorLimitMessage:
          "You have reached the limit of rewards you can issue over a period of 24h",
        prompt: "Are you sure you want to issue this reward?",
        recipientText: "Recipient",
        rewardInfo: {
          isCustomer:
            "If this campaign rewards the friend who was referred, you may need to issue a reward to them also.",
          isExternalUser:
            "If this campaign rewards the referrer who made the referral, you may need to issue a reward to them also.",
        },
        rewardTypes: {
          archived: "archived",
          inactive: "inactive",
        },
        rewardText: "Reward",
        subtitle: "Only 10 rewards can be issued manually per day",
        successMessage: "Reward successfully issued to",
        title: "Issue reward",
      },
      firstSeen: "first seen",
      lastSeen: "last seen",
      shareURL: "Share URL",
      successReferralCreationMessage: "Referral created successfully",
      unsubscribeFromEmailsText: "Unsubscribe from emails",
      unsubscribeModal: {
        closeModalText: "No, Cancel",
        errorMessage: "There was a problem updating this user",
        prompt: "Yes, Unsubscribe",
        successMessage: "unsubscribed successfully",
        title: "Are you sure you want to unsubscribe {{fullName}} from all emails?",
      },
    },
    referrerProfile: {
      title: "User Profile",
      issueNewRewardText: "Issue new reward",
      createReferralButtonText: "Create referral",
      editDetailsButton: "Edit Details",
      editButton: "Edit",
      editCustomFieldTitle: "Campaign: ",
      friendSectionTitle: "Friend Details",
      referrerSectionTitle: "Referral Marketing",
      manualSectionTitle: "Manual Payouts Received",
      rewardSectionTitle: "Reward Payouts",
      partnerSectionTitle: "Partner Marketing",
      newUiBanner: {
        prompt: "Try our new Enhanced Customer Management",
        on: "On",
        off: "Off",
        demoLinkLabel: "Watch tutorial",
        demoLink: "https://app.supademo.com/demo/cm012i9q00sacw28qa55q6yh8",
      },
      referrerInfoCard: {
        loading: "Loading...",
        name: "Name",
        rewardsEarned: "Rewards Earned",
        referredBy: "Referred By",
        emailAddress: "Email",
        emailStatus: "Campaign emails status",
        emailSubscribed: "Subscribed",
        emailUnsubscribed: "Unsubscribed",
        locale: "Locale",
        lastSeen: "Last Seen",
        dateRegistered: "Date Registered",
        campaign: "Campaign",
        shareUrl: "Share URL",
        copyShareUrl: "Copy Share URL",
        dashboardUrl: "Dashboard URL",
        copyDashboardUrl: "Copy Dashboard URL",
        referralsMade: "Referrals Made",
        rewardCapNotSet: "Not set",
        cappingTypeTitle: "Capping Type",
        cap: "cap",
        cappingPeriod: {
          lifetime: "Lifetime",
          calendar_year: "Yearly",
          twelve_months: "Past twelve months",
          referral_year: "Referral year",
        },
        cappingType: {
          monetary: "value",
          volume: "volume",
        },
      },
      referralInfoCard: {
        campaign: "Campaign",
        emailAddress: "Email",
        rewardName: "Reward Name & Type",
        rewardValue: "Reward Value",
        rewardValueNotSet: "Not set",
        rewardCode: "Reward Code",
        fulfilmentDate: "Fulfilment Date",
        rewardStatus: "Reward Status",
        referralName: "Name",
        referralDate: "Referral Date",
        referralStatus: "Referral Status",
        rewardFulfilmentLog: "Reward Fulfilment Log",
        referralHistoryLog: "Referral History Log",
        orderNumber: "Order Number",
        orderDate: "Order date",
      },
      subscribeEmail: {
        successMessage: "subscribed successfully",
        errorMessage: "There was a problem updating this user",
      },
      unsubscribeModal: {
        closeText: "No",
        errorMessage: "There was a problem updating this user",
        prompt: "Yes, unsubscribe",
        title: "Unsubscribe from campaign emails",
        subtitle: "Are you sure you want to unsubscribe {{fullName}} from all emails?",
      },
      subscribeModal: {
        closeText: "No",
        errorMessage: "There was a problem updating this user",
        prompt: "Yes, subscribe",
        title: "Subscribe to campaign emails",
        subtitle: "Are you sure you want to subscribe {{fullName}} to all emails?",
      },
      statuses: {
        cancelled: "Cancelled",
        complete: "Complete",
        expired: "Expired",
        failed: "Failed",
        paused: "Paused",
        pending: "Pending",
        processing: "Processing",
        redeemed: "Redeemed",
      },
      referralTimeline: {
        error: "Error",
        paymentProviderError: "Payment provider error",
        reward_failed: "Reward failed",
        reward_failed_description: "This reward failed to be issued",
        reward_complete: "Reward complete",
        reward_complete_description: "This reward was successfully issued",
        reward_processing: "Reward processing",
        reward_processing_description: "This reward is being processed",
        reward_pending: "Reward pending",
        reward_pending_description: "This reward is pending",
        reward_paused: "Reward paused",
        reward_paused_description: "This reward is paused",
        reward_redeemed: "Reward redeemed",
        reward_redeemed_description: "This reward has been redeemed",
        reward_expired: "Reward expired",
        reward_expired_description: "This reward has expired",
        reward_cancelled: "Reward cancelled",
        reward_cancelled_description: "This reward has been cancelled",
        referral_failed: "Referral failed",
        referral_failed_description: "This referral failed to process",
        referral_complete: "Referral complete",
        referral_complete_description: "This referral was successfully processed",
        referral_processing: "Referral processing",
        referral_processing_description: "This referral is being processed",
        referral_pending: "Referral pending",
        referral_pending_description: "This referral is pending",
        referral_paused: "Referral paused",
        referral_paused_description: "This referral is paused",
        referral_expired: "Referral expired",
        referral_expired_description: "This referral has expired",
        referral_cancelled: "Referral cancelled",
        referral_cancelled_description: "This referral has been cancelled",
        timeProcessed: "Time processed",
        timeProcessed_description: "The time the reward was processed",
        transactionId: "Transaction ID",
        actionDescriptor: "Please select an action available to you",
        actionDescriptor_description: "The actions available for this reward",
        confirm: "Confirm",
        dataUnavailable: "Data unavailable",
        dataUnavailableDescription:
          "Detailed history is unavailable for transactions before July 2024",
        dataUnavailableSupportInfo: "Please refer to available reports for further information",
        emailTitle_signed_up_processed: "Instruction email sent",
        emailTitle_signed_up_delivered: "Instruction email delivered",
        emailTitle_signed_up_open: "Instruction email opened",
        emailTitle_signed_up_click: "Instruction email clicked",
        emailTitle_signed_up_bounce: "Instruction email bounced",
        emailTitle_signed_up_dropped: "Instruction email dropped",
        emailTitle_friend_post_purchase_tracking_processed: "Post purchase email sent",
        emailTitle_friend_post_purchase_tracking_delivered: "Post purchase email delivered",
        emailTitle_friend_post_purchase_tracking_open: "Post purchase email opened",
        emailTitle_friend_post_purchase_tracking_click: "Post purchase email clicked",
        emailTitle_friend_post_purchase_tracking_bounce: "Post purchase email bounced",
        emailTitle_friend_post_purchase_tracking_dropped: "Post purchase email dropped",
        emailTitle_friend_reward_processed: "Reward email sent",
        emailTitle_friend_reward_delivered: "Reward email delivered",
        emailTitle_friend_reward_open: "Reward email opened",
        emailTitle_friend_reward_click: "Reward email clicked",
        emailTitle_friend_reward_bounce: "Reward email bounced",
        emailTitle_friend_reward_dropped: "Reward email dropped",
        emailTitle_reward_processed: "Reward email sent",
        emailTitle_reward_delivered: "Reward email delivered",
        emailTitle_reward_open: "Reward email opened",
        emailTitle_reward_click: "Reward email clicked",
        emailTitle_reward_bounce: "Reward email bounced",
        emailTitle_reward_dropped: "Reward email dropped",
        emailTitle_manual_reward_processed: "Manual reward email sent",
        emailTitle_manual_reward_delivered: "Manual reward email delivered",
        emailTitle_manual_reward_open: "Manual reward email opened",
        emailTitle_manual_reward_click: "Manual reward email clicked",
        emailTitle_manual_reward_bounce: "Manual reward email bounced",
        emailTitle_manual_reward_dropped: "Manual reward email dropped",
        emailTitle_referrer_inactive_nurture_processed: "Inactive referrer email sent",
        emailTitle_referrer_inactive_nurture_delivered: "Inactive referrer email delivered",
        emailTitle_referrer_inactive_nurture_open: "Inactive referrer email opened",
        emailTitle_referrer_inactive_nurture_click: "Inactive referrer email clicked",
        emailTitle_referrer_inactive_nurture_bounce: "Inactive referrer email bounced",
        emailTitle_referrer_inactive_nurture_dropped: "Inactive referrer email dropped",
        emailTitle_referrer_nurture_processed: "Referrer nurture email sent",
        emailTitle_referrer_nurture_delivered: "Referrer nurture email delivered",
        emailTitle_referrer_nurture_open: "Referrer nurture email opened",
        emailTitle_referrer_nurture_clicked: "Referrer nurture email clicked",
        emailTitle_referrer_nurture_bounce: "Referrer nurture email bounced",
        emailTitle_referrer_nurture_dropped: "Referrer nurture email dropped",
        emailTitle_click_to_claim_processed: "Click to claim email sent",
        emailTitle_click_to_claim_delivered: "Click to claim email delivered",
        emailTitle_click_to_claim_open: "Click to claim email opened",
        emailTitle_click_to_claim_click: "Click to claim email clicked",
        emailTitle_click_to_claim_bounce: "Click to claim email bounced",
        emailTitle_click_to_claim_dropped: "Click to claim email dropped",
        emailTitle_incentive_processed: "Incentive email sent",
        emailTitle_incentive_delivered: "Incentive email delivered",
        emailTitle_incentive_open: "Incentive email opened",
        emailTitle_incentive_click: "Incentive email clicked",
        emailTitle_incentive_bounce: "Incentive email bounced",
        emailTitle_incentive_dropped: "Incentive email dropped",
        emailTitle_referred_but_no_reward_yet_processed:
          "Referral tracked - awaiting approval email sent",
        emailTitle_referred_but_no_reward_yet_delivered:
          "Referral tracked - awaiting approval email delivered",
        emailTitle_referred_but_no_reward_yet_open:
          "Referral tracked - awaiting approval email opened",
        emailTitle_referred_but_no_reward_yet_click:
          "Referral tracked - awaiting approval email clicked",
        emailTitle_referred_but_no_reward_yet_bounce:
          "Referral tracked - awaiting approval email bounced",
        emailTitle_referred_but_no_reward_yet_dropped:
          "Referral tracked - awaiting approval email dropped",
        emailTitle_click_to_claim_friend_processed: "Click to claim friend email sent",
        emailTitle_click_to_claim_friend_delivered: "Click to claim friend email delivered",
        emailTitle_click_to_claim_friend_open: "Click to claim friend email opened",
        emailTitle_click_to_claim_friend_click: "Click to claim friend email clicked",
        emailTitle_click_to_claim_friend_bounce: "Click to claim friend email bounced",
        emailTitle_click_to_claim_friend_dropped: "Click to claim email dropped",
        emailTitle_generic_processed: "Reward email resent",
        emailTitle_pending_referral_processed: "Pending referral email sent",
        emailTitle_pending_referral_delivered: "Pending referral email delivered",
        emailTitle_pending_referral_open: "Pending referral email opened",
        emailTitle_pending_referral_click: "Pending referral email clicked",
        emailTitle_pending_referral_bounce: "Pending referral email bounced",
        emailTitle_pending_referral_dropped: "Pending referral email dropped",
        selectPlaceholder: "Select an action",
        campaignEmailLogTitle: "Campaign Email Log",
      },
    },
    fulfilmentFailureReasons: {
      referral_volume_limit_reached: "Referral volume limit reached",
    },
    userResults: {
      noResultMessage:
        "Sorry, we found <strong>0 results</strong> for <strong>{{searchTerm}}</strong>. Please try a different search.",
      tableHeader: {
        name: "name",
        email: "email",
        identifier: "identifier",
        type: "type",
        referrals: "referrals",
      },
      userType: {
        friend: "friend",
        referrer: "referrer",
      },
    },
    users: {
      navigation: {
        bulkActions: "Bulk actions",
        customerManagement: "Customer management",
        partnerManagement: "Partner management",
      },
    },
    userSearch: {
      addReferrer: {
        prompt: "Add referrer",
        modal: {
          successMessage: "Referrer created successfully",
          form: {
            selectCampaignText: "Select campaign",
            emailComplianceLabelText:
              "Check this box to confirm that this referrer confirms to only send referral messages to individuals who they have a personal or family relationship with",
            marketingOptInLabelText:
              "Check this box to confirm that you have the consent of this referrer to opt them into Marketing",
            termAndConditionsLabelText:
              "Check this box to confirm that this referrer accepted the Terms & Conditions",
          },
        },
      },
      searchResult: "{{count}} results",
      searchResult_one: "{{count}} result",
      searchInstructionPrompt:
        "Search for customer name, email, order ID or any custom field you configured.",
    },
    vouchers: {
      header: "Vouchers",
      tableHeaders: {
        manuallyIssued: "Manually Issued",
        obtainedThrough: "Obtained through",
        redeemed: "Redeemed?",
        redeemedBy: "Redeemed by",
        voucher: "Voucher",
      },
      labels: {
        from: "from",
        redeemed: "Redeemed",
      },
    },
    paragon: {
      integrations: "Integrations",
      connectionError: "There was a problem connecting to this service.",
      connected: "Connected",
      notConnected: "Not connected",
      connectedDescription: "Manage your campaign more effectively via Integration settings.",
      notConnectedDescription:
        "Connect and sync your {{integrationName}} accounts, contacts and leads.",
      connect: "Connect",
      settings: "Integration settings",
    },
  },
};
