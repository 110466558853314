import React from "react";

import { useTranslation } from "react-i18next";

import NoResults from "components/customer_lookup/no_results/NoResults";
import UserReferral from "components/customer_lookup/user/user_referrals/user_referral/UserReferral";

import "./user-referrals.scss";

const UserReferrals = ({
  referrals,
  timezoneOffset,
  selectedMarket,
  isCis = false,
  reloadView = () => {},
}) => {
  const { t } = useTranslation();
  const referralsText = t("global.referrals");
  const headerText = t("referrals.header");
  const tableHeaderFriendNameText = t("referrals.tableHeaders.friendName");
  const tableHeaderReferredOnText = t("referrals.tableHeaders.referredOn");
  const tableHeaderRewardText = t("referrals.tableHeaders.reward");
  const tableHeaderStatusText = t("referrals.tableHeaders.status");

  return referrals.length > 0 ? (
    <div className="user-referrals card">
      <h2 className="card__title">{headerText}</h2>
      <div className="table-container">
        <table className="portal-table table-referrals">
          <thead>
            <tr>
              <th>{tableHeaderFriendNameText}</th>
              <th>{tableHeaderReferredOnText}</th>
              <th>{tableHeaderRewardText}</th>
              <th>{tableHeaderStatusText}</th>
            </tr>
          </thead>
          <tbody>
            {referrals.map((referral) => (
              <UserReferral
                key={referral.id}
                referral={referral}
                timezoneOffset={timezoneOffset}
                selectedMarket={selectedMarket}
                isCis={isCis}
                reloadView={reloadView}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <NoResults table={referralsText} />
  );
};

export default UserReferrals;
