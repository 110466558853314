import React, { useState } from "react";

import countryCodesJSON from "assets/countryCodes.json";
import classnames from "classnames";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import i18next from "i18next";
import Select from "react-select";

import "./mobile_phone_dropdown.scss";

const MobilePhoneDropdown = ({
  id,
  mobileCountryCode,
  mobile,
  phoneNumberErrorKey,
  countryCodeErrorKey,
  handleMobileChange,
  handleCountryCodeChange,
  labelText = "",
  phoneNumberErrors = [],
  countryCodeErrors = [],
  showRequiredIndicator = false,
}) => {
  const options = countryCodesJSON.map(({ name, countryCode, dialCode }) => ({
    value: dialCode,
    label: `${name} (${dialCode})`,
    countryCode,
    icon: getUnicodeFlagIcon(countryCode),
  }));
  const setInitialCountryCode = () => {
    const country = options.find((element) => element.countryCode === mobileCountryCode);

    return country?.value ?? "";
  };
  const [countryAreaCode, setCountryAreaCode] = useState(setInitialCountryCode());
  const handleMobileCountryChange = (select) => {
    setCountryAreaCode(select.value);
    handleCountryCodeChange(select.countryCode);
    document.getElementById(id).focus();
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--b-background-color)",
      boxShadow: "none",
      borderRadius: "10px",
      border: state.isFocused ? "2px solid var(--b-text-color)" : "2px solid transparent",
      transition: "none",
      "&:hover": {
        border: state.isFocused ? "2px solid var(--b-text-color)" : "2px solid transparent",
      },
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: "15px 17px",
      width: "130px",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    menu: (provided) => ({
      ...provided,
      borderRadius: "10px",
      marginTop: "5px",
      boxShadow: "2px 2px 10px  0 rgba(var(--b-black), 0.2)",
      padding: "20px 0",
    }),

    container: () => ({
      marginRight: "5px",
    }),

    menuList: (provided) => ({
      ...provided,
      maxHeight: "400px",
      padding: "0em",
    }),

    placeholder: (provided) => ({
      ...provided,
      margin: "0",
    }),

    singleValue: (provided) => ({
      ...provided,
      color: "rgba(var(--b-dark-blue), 1)",
    }),

    input: (provided) => ({
      ...provided,
      padding: "0",
      margin: "0",
    }),

    option: (provided, state) => ({
      ...provided,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "50px",
      padding: "0 20px",
      color: "rgba(var(--b-dark-blue), 1)",
      backgroundColor: state.isFocused ? "rgba(var(--b-blue), 0.08)" : "inherit",
      "&:hover": {
        backgroundColor: "rgba(var(--b-blue), 0.08)",
      },
      fontWeight: state.isSelected ? "600" : "400",
    }),
  };

  return (
    <div className="field-group">
      <div
        className={classnames("flex-between", { "input-label__required": showRequiredIndicator })}
      >
        <label>{labelText}</label>
      </div>
      <div className="flex-between">
        <Select
          options={options}
          value={{ label: countryAreaCode }}
          onChange={handleMobileCountryChange}
          styles={customStyles}
          getOptionLabel={(e) => (
            <div>
              <span>{e.icon} </span>
              <span>{e.label}</span>
            </div>
          )}
        />
        <div className="input-group input">
          <input
            type="text"
            id={id}
            data-testid="mobile"
            value={mobile}
            onChange={handleMobileChange}
            data-lpignore
          />
        </div>
      </div>
      {countryCodeErrors.map((error) => (
        <div className="field-error-message" key={error.type}>
          {i18next.t(`forms.errors.${countryCodeErrorKey}.${error.type}`)}
        </div>
      ))}
      {phoneNumberErrors.map((error) => (
        <div className="field-error-message" key={error.type}>
          {i18next.t(`forms.errors.${phoneNumberErrorKey}.${error.type}`)}
        </div>
      ))}
    </div>
  );
};

export default MobilePhoneDropdown;
