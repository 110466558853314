import React from "react";

import { Card, Link } from "bp-ui";
import { t } from "i18next";
import { FaCheck } from "react-icons/fa";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

export const VoucherList = ({ vouchers, selectedMarket }) => {
  return (
    <>
      {vouchers.length > 0 && (
        <>
          <div className="w-full border-0 border-solid border-b border-grey h-px ml-0 m-0 py-0" />
          <h2 className="text-xl mt-0 mb-0">{t("vouchers.header")}</h2>
          <div className={"grid w-full"}>
            {vouchers.map((voucher) => (
              <div
                key={voucher.code}
                className="grid grid-cols-10 content-center gap-6 border-0 border-solid border-t border-grey py-3"
              >
                <div className="col-span-2 grid grid-cols-12">
                  <div className="col-span-3 ml-0">
                    <FaMoneyCheckDollar className="text-2xl" />
                  </div>
                  <div className="col-span-9 pt-0.5 break-all">{voucher.code}</div>
                </div>
                <div className={"col-span-3 pt-0.5"}>{voucher.voucherPoolName}</div>
                <div className={"col-span-3 pt-0.5"}>
                  {voucher.distributedById && (
                    <div>
                      {t("vouchers.labels.from")}
                      <Link
                        className="underline text-dark-blue text-sm"
                        to={`/${selectedMarket.subdomain}/users/search/${voucher.distributedById}`}
                        elementToUse={NavLink}
                      >
                        {voucher.distributedByFullName}
                      </Link>
                    </div>
                  )}
                </div>
                <div className={"col-span-2 pt-0.5"}>
                  {voucher.redeemed && (
                    <div
                      role={"status"}
                      className={
                        "flex flex-none mr-1 text-base font-bold text-dark-green align-top"
                      }
                    >
                      <div className={"flex mt-1"}>
                        <FaCheck />
                      </div>
                      <div className={"flex mt-0"}>&nbsp;{t("vouchers.labels.redeemed")}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};
