import React, { useEffect, useState } from "react";

import { CSVHeaderTable, DetailList, DetailListItem, InfoPanel, Modal, Statuses } from "bp-ui";
import Market from "models/Market";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";

import FileImport from "components/bulk_actions/file_import/FileImport";
import TransactionTrackingHistory from "components/bulk_actions/transaction_tracking/transaction_tracking_history/TransactionTrackingHistory";
import { CSVDownloadButton } from "components/csv_download_button/CSVDownloadButton";
import addNotification from "components/notifications/Notifications";

import "./transaction_tracking.scss";

const TransactionTracking = ({ selectedMarket }) => {
  const { t } = useTranslation();
  const [showRequiredColumnsModal, setShowRequiredColumnsModal] = useState(false);
  const [checkHistory, setCheckHistory] = useState(true);
  const [importList, setImportList] = useState([]);
  const [importListLoadingError, setImportListLoadingError] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const transactionsHistory = await selectedMarket.imports.getList("transactions");
        setImportList(transactionsHistory);
        setImportList(transactionsHistory);
      } catch (err) {
        setImportListLoadingError(true);
      }
    };

    getData();
  }, [checkHistory, selectedMarket?.imports]);

  const toggleModal = () => {
    setShowRequiredColumnsModal(!showRequiredColumnsModal);
  };

  const onImportSuccess = () => {
    addNotification.success(t("global.success"), t("bulkActions.successNotification"));
    setCheckHistory(!checkHistory);
  };

  const ModalContent = () => {
    const headers = [
      "ReferrerEmail",
      "VoucherCode",
      "FullName",
      "EmailAddress",
      "OrderValue",
      "OrderNumber",
      "OrderTimestamp",
      "MarketingOptin",
      "ClientData_key",
      "Locale",
      "RewardCategory",
      "CustomField",
      "CustomField_key",
    ];
    const filename = "transaction-tracking.csv";

    return (
      <>
        <DetailList>
          <DetailListItem
            title="ReferrerEmail"
            description={t("bulkActions.transactionTracking.modal.referrerEmailContent")}
          />

          <DetailListItem
            title="VoucherCode"
            description={t("bulkActions.transactionTracking.modal.voucherCodeContent")}
          />

          <DetailListItem
            title="FullName"
            description={t("bulkActions.transactionTracking.modal.fullNameHContent")}
          />

          <DetailListItem
            title="EmailAddress"
            description={t("bulkActions.transactionTracking.modal.emailAddressContent")}
          />
        </DetailList>

        <hr className="border-lighter-grey border-1/2 my-8" />
        <h3 className="text-xl text-dark-blue font-normal mb-8">
          {t("bulkActions.transactionTracking.modal.optionalText")}
        </h3>

        <DetailList>
          <DetailListItem
            title="OrderValue"
            description={t("bulkActions.transactionTracking.modal.orderValueContent")}
          />

          <DetailListItem
            title="OrderNumber"
            description={t("bulkActions.transactionTracking.modal.orderNumberContent")}
          />

          <DetailListItem
            title="OrderTimestamp"
            description={t("bulkActions.transactionTracking.modal.orderTimestampContent")}
          />

          <DetailListItem
            title="MarketingOptin"
            description={t("bulkActions.transactionTracking.modal.marketingOptinContent")}
          />

          <DetailListItem
            title={"ClientData_{key}"}
            description={t("bulkActions.transactionTracking.modal.clientDataContent")}
          />

          <DetailListItem
            title="Locale"
            description={t("bulkActions.transactionTracking.modal.localeContent")}
          />

          <DetailListItem
            title="RewardCategory"
            description={t("bulkActions.transactionTracking.modal.rewardCategory")}
          />

          <DetailListItem
            title="CustomField"
            description={t("bulkActions.transactionTracking.modal.customFieldContent")}
          />

          <DetailListItem
            title="CustomField_{key}"
            description={t("bulkActions.transactionTracking.modal.customFieldsContent")}
          />
        </DetailList>

        <InfoPanel statusType={Statuses.DEFAULT} className="mb-4">
          <p className="font-semibold text-sm m-0">{t("bulkActions.downloadCsvDescription")}</p>
          <CSVHeaderTable headers={headers} />
        </InfoPanel>

        <div className="flex mt-4">
          <CSVDownloadButton filename={filename} headers={headers} data={[]} icon={<FaDownload />}>
            {t("bulkActions.downloadCsvButtonText")}
          </CSVDownloadButton>
        </div>
      </>
    );
  };

  return (
    <div className="c-transaction-tracking mx-auto my-10 card">
      <h2 className="card__title">{t("bulkActions.transactionTracking.header")}</h2>

      <p className="mb-8">
        {t("bulkActions.transactionTracking.subtitle")} <br />
        <strong>{t("bulkActions.notification")}</strong>
      </p>

      <a
        href="/required-headers"
        className="underline font-semibold text-mid-blue"
        onClick={(e) => {
          e.preventDefault();
          toggleModal();
        }}
      >
        {t("bulkActions.transactionTracking.showRequiredModalColumnsButtonText")}
      </a>

      <hr className="border-lighter-grey border-1/2 my-8" />

      <FileImport
        selectedMarket={selectedMarket}
        importType="transactions"
        buttonLabel={t("bulkActions.transactionTracking.fileImport.buttonLabel")}
        checkBoxOptionKey="force_referrals"
        checkBoxDefault={false}
        checkBoxLabel={t("bulkActions.transactionTracking.fileImport.checkboxLabel")}
        onImportSuccess={onImportSuccess}
      />

      <Modal
        className="top-24 max-w-1/2"
        onClose={() => toggleModal()}
        open={showRequiredColumnsModal}
        title={t("bulkActions.transactionTracking.modal.title")}
        closeButtonAriaLabel={t("global.close")}
      >
        <ModalContent />
      </Modal>

      <TransactionTrackingHistory
        importList={importList}
        timezoneOffset={selectedMarket.timezone_offset}
        importListLoadingError={importListLoadingError}
      />
    </div>
  );
};

export default TransactionTracking;
