import React from "react";

import classnames from "classnames";
import { FaSpinner } from "react-icons/fa";

import "./spinner.scss";

const Spinner = ({ loadingText, showSpinner, fullHeight = true }) =>
  showSpinner && (
    <div
      className={classnames(
        "c-spinner",
        fullHeight ? "c-spinner-full-height" : "c-spinner-fit-height"
      )}
      role="alert"
      aria-busy={true}
    >
      <FaSpinner />
      <p>{loadingText}</p>
    </div>
  );

export default Spinner;
