import React from "react";

import { useTranslation } from "react-i18next";

import FormattedDate from "components/formatted_date/FormattedDate";
import Calendars from "components/reports/date_picker/date_range_selector/custom_range/calendars/Calendars";
import "components/reports/date_picker/date_range_selector/custom_range/custom_range_selector.scss";

const CustomRangeSelector = ({ newStartDate, newEndDate, updateNewDateRange, timezoneOffset }) => {
  const { t } = useTranslation();

  const title = `${t("reporting.dashboard.topbar.dateRangeSelector.customRangeSelector.title")}: `;

  return (
    <div className="custom-range-selector">
      <div className="new-date-range">
        {title}
        <FormattedDate
          timestamp={Math.floor(newStartDate.toMillis()) / 1000}
          timezoneOffset={timezoneOffset}
          type="short"
        />
        {" - "}
        <FormattedDate
          timestamp={Math.floor(newEndDate.toMillis()) / 1000}
          timezoneOffset={timezoneOffset}
          type="short"
        />
      </div>
      <Calendars
        newStartDate={newStartDate}
        newEndDate={newEndDate}
        updateNewDateRange={updateNewDateRange}
        timezoneOffset={timezoneOffset}
      />
    </div>
  );
};

export default CustomRangeSelector;
