import React, { useState } from "react";

import classnames from "classnames";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { FaInfoCircle } from "react-icons/fa";
import Select, { components } from "react-select";

import { CheckboxChecked, CheckboxEmpty } from "components/forms/icons/checkbox/Checkbox";
import Tooltip from "components/tooltip/Tooltip";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--b-background-color)",
    boxShadow: "none",
    borderRadius: "10px",
    border: state.isFocused ? "2px solid var(--b-text-color)" : "2px solid transparent",
    transition: "none",

    "&:hover": {
      border: state.isFocused ? "2px solid var(--b-text-color)" : "2px solid transparent",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "15px 17px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingRight: "20px",
    color: "rgba(var(--b-dark-blue), 1)",

    "&:hover": {
      color: "rgba(var(--b-dark-blue), 1)",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "10px",
    marginTop: "5px",
    boxShadow: "2px 2px 10px  0 rgba(var(--b-black), 0.2)",
    padding: "20px 0",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "400px",
    padding: "0em",
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: "0",
  }),
  input: (provided) => ({
    ...provided,
    padding: "0",
    margin: "0",
    color: "rgba(var(--b-dark-blue), 1)",
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "50px",
    padding: "0 20px",
    color: "rgba(var(--b-dark-blue), 1)",
    backgroundColor: state.isFocused ? "rgba(var(--b-blue), 0.08)" : "inherit",
    "&:hover": {
      backgroundColor: "rgba(var(--b-blue), 0.08)",
    },
    fontWeight: state.isSelected ? "600" : "400",
  }),
  optionCheckbox: () => ({
    backgroundColor: "rgba(var(--b-blue), 0.08)",
  }),
  multiValue: (provided) => ({
    ...provided,
    margin: "0",
    backgroundColor: null,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "100%",
    padding: "0",
    paddingLeft: "3px",
  }),
  multiValueRemove: () => ({
    display: "none",
  }),
};

const Option = ({ children, ...props }) => (
  <components.Option {...props}>
    {children}
    {props.isSelected ? <CheckboxChecked /> : <CheckboxEmpty />}
  </components.Option>
);

const MultiValueLabel = ({ children, ...props }) => {
  const delimeter = () => {
    const values = props.selectProps.value;

    if (values) {
      return values[values.length - 1].label === props.data.label ? "" : ",";
    }

    return "";
  };

  return (
    <components.MultiValueLabel {...props}>
      {`${children}${delimeter()}`}
    </components.MultiValueLabel>
  );
};

const NoOptionsMessage = (props) => {
  const { t } = useTranslation();

  return (
    <components.NoOptionsMessage {...props}>
      {t("components.checkboxDropdown.noOptions")}
    </components.NoOptionsMessage>
  );
};

const CheckboxDropdown = ({
  name,
  labelText,
  options,
  value,
  handleOnChange,
  className = "",
  placeholder = "Select your choice",
  tooltipText = null,
  tooltipDirection = "up",
  required = false,
  disabled = false,
  errors = [],
  errorKey = "",
}) => {
  const [tipActive, setTipActive] = useState(false);

  const handleOnChangeWithName = (selected) => {
    handleOnChange(name, selected);
  };

  return (
    <div className={classnames({ "c-dropdown__disabled": disabled }, className)}>
      <div className="c-dropdown__header">
        <div className="flex-between">
          <label
            className={classnames(required ? "c-dropdown__label-required" : "c-dropdown__label")}
          >
            {labelText}
          </label>
          {tooltipText && (
            <div
              className={classnames("c-dropdown__info-container", {
                "c-dropdown__tooltip-active": tipActive,
              })}
            >
              <Tooltip
                tipContentClassName="c-dropdown__info-plain"
                content={tooltipText}
                direction={tooltipDirection}
                onToggle={setTipActive}
              >
                <FaInfoCircle />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <Select
        inputId={name}
        styles={{ ...customStyles }}
        components={{ MultiValueLabel, Option, NoOptionsMessage }}
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={handleOnChangeWithName}
        menuPlacement="auto"
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        isDisabled={disabled}
        isMulti
        captureMenuScroll
        openMenuOnFocus
      />
      {errors.map((error) => (
        <div className="field-error-message" key={error.type}>
          {i18next.t(`forms.errors.${errorKey || name}.${error.type}`)}
        </div>
      ))}
    </div>
  );
};

export default CheckboxDropdown;
