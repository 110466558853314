import React from "react";

import countryCodesJSON from "assets/countryCodes.json";
import { fullName } from "helpers/Helpers";
import { useTranslation } from "react-i18next";

import FormattedDate from "components/formatted_date/FormattedDate";
import AssetsSection from "components/partner_management/partner_profile/assets/AssetsSection";
import "components/partner_management/partner_profile/partner_profile.scss";
import EditPartner from "components/partner_management/profile_form/edit_partner/EditPartner";

const Profile = ({ market, partnerId, partner, reloadPartner }) => {
  const { t } = useTranslation();
  const mobileCountry = countryCodesJSON.find(
    (country) => country.countryCode === partner.profile.mobileCountryCode
  );
  const getWebsitePrefix = (string) =>
    string.slice(0, 4) === "http" ? partner.profile.website : `https://${partner.profile.website}`;
  const website =
    partner.profile.website?.length >= 4 ? getWebsitePrefix(partner.profile.website) : "";

  return (
    <div className="card">
      <div className="c-partner-profile__details">
        <div className="c-partner-profile__header">
          <div className="c-partner-profile__title">
            <span className="c-partner-profile__title--primary">
              {partner.profile.businessName}
            </span>
            <span className="c-partner-profile__title--secondary">{partner.profile.email}</span>
          </div>
          <div className="c-partner-profile__inner-buttons">
            <EditPartner
              selectedMarket={market}
              partnerId={partnerId}
              partnerProfile={partner.profile}
              reloadPartner={reloadPartner}
            />
          </div>
        </div>

        <div className="website">
          <a href={website}>{partner.profile.website}</a>
        </div>
      </div>
      <div className="c-partner-profile__dates">
        {partner.profile.dateEnrolled && partner.profile.timezoneOffset && (
          <div className="c-partner-profile__meta-block">
            <span className="c-partner-profile__meta-header">
              {t("partnerProfile.dateEnrolled")}
            </span>
            <span>
              <FormattedDate
                timestamp={partner.profile.dateEnrolled}
                timezoneOffset={partner.profile.timezoneOffset}
                type="long"
              />
            </span>
          </div>
        )}
        {partner.profile.firstSeen && partner.profile.timezoneOffset && (
          <div className="c-partner-profile__meta-block">
            <span className="c-partner-profile__meta-header">{t("partnerProfile.firstSeen")}</span>
            <span>
              <FormattedDate
                timestamp={partner.profile.firstSeen}
                timezoneOffset={partner.profile.timezoneOffset}
                type="long"
              />
            </span>
          </div>
        )}
        {partner.profile.lastSeen && partner.profile.timezoneOffset && (
          <div className="c-partner-profile__meta-block">
            <span className="c-partner-profile__meta-header">{t("partnerProfile.lastSeen")}</span>
            <span>
              <FormattedDate
                timestamp={partner.profile.lastSeen}
                timezoneOffset={partner.profile.timezoneOffset}
                type="long"
              />
            </span>
          </div>
        )}
      </div>

      <div className="c-partner-profile__profile">
        <h2>{t("partnerProfile.profile")}</h2>
        <div className="c-partner-profile__profile-details">
          <div className="c-partner-profile__meta-block">
            <span className="c-partner-profile__meta-header">
              {t("partnerProfile.contactName")}
            </span>
            <span>{fullName(partner.profile.firstName, partner.profile.lastName)}</span>
          </div>
          <div className="c-partner-profile__meta-block">
            <span className="c-partner-profile__meta-header">
              {t("forms.labels.partner.email")}
            </span>
            <span>{partner.profile.email}</span>
          </div>
          {partner.profile.mobile && (
            <div className="c-partner-profile__meta-block">
              <span className="c-partner-profile__meta-header">
                {t("forms.labels.partner.mobile")}
              </span>
              <span>{`${mobileCountry?.dialCode}${partner.profile.mobile}`}</span>
            </div>
          )}
          {partner.profile.jobTitle && (
            <div className="c-partner-profile__meta-block">
              <span className="c-partner-profile__meta-header">
                {t("forms.labels.partner.jobTitle")}
              </span>
              <span>{partner.profile.jobTitle}</span>
            </div>
          )}
          {partner.profile.industrySector && (
            <div className="c-partner-profile__meta-block">
              <span className="c-partner-profile__meta-header">
                {t("forms.labels.partner.industrySector")}
              </span>
              <span>{partner.profile.industrySector}</span>
            </div>
          )}
          {partner.profile.businessCountry && (
            <div className="c-partner-profile__meta-block">
              <span className="c-partner-profile__meta-header">
                {t("forms.labels.partner.businessCountry")}
              </span>
              <span>{partner.profile.businessCountry}</span>
            </div>
          )}
          {partner.profile.twitter && (
            <div className="c-partner-profile__meta-block">
              <span className="c-partner-profile__meta-header">
                {t("forms.labels.partner.twitter")}
              </span>
              <span>{partner.profile.twitter}</span>
            </div>
          )}
          {partner.profile.instagram && (
            <div className="c-partner-profile__meta-block">
              <span className="c-partner-profile__meta-header">
                {t("forms.labels.partner.instagram")}
              </span>
              <span>{partner.profile.instagram}</span>
            </div>
          )}
          {partner.profile.facebook && (
            <div className="c-partner-profile__meta-block">
              <span className="c-partner-profile__meta-header">
                {t("forms.labels.partner.facebook")}
              </span>
              <span>{partner.profile.facebook}</span>
            </div>
          )}
          {partner.profile.linkedin && (
            <div className="c-partner-profile__meta-block">
              <span className="c-partner-profile__meta-header">
                {t("forms.labels.partner.linkedin")}
              </span>
              <span>{partner.profile.linkedin}</span>
            </div>
          )}
          {partner.profile.youtube && (
            <div className="c-partner-profile__meta-block">
              <span className="c-partner-profile__meta-header">
                {t("forms.labels.partner.youtube")}
              </span>
              <span>{partner.profile.youtube}</span>
            </div>
          )}
          {partner.profile.snapchat && (
            <div className="c-partner-profile__meta-block">
              <span className="c-partner-profile__meta-header">
                {t("forms.labels.partner.snapchat")}
              </span>
              <span>{partner.profile.snapchat}</span>
            </div>
          )}
        </div>

        <div className="c-partner-profile__profile">
          <h2>{t("partnerProfile.audience")}</h2>

          <p className="c-partner-profile__description">{t("partnerProfile.audienceInfo")}</p>

          <div className="c-partner-profile__profile-details">
            {partner.profile.audienceSize && (
              <div className="c-partner-profile__meta-block">
                <span className="c-partner-profile__meta-header">
                  {t("forms.labels.partner.audienceSize")}
                </span>
                <span>{partner.profile.audienceSize}</span>
              </div>
            )}
            {partner.profile.audienceLocation.length > 0 && (
              <div className="c-partner-profile__meta-block">
                <span className="c-partner-profile__meta-header">
                  {t("forms.labels.partner.audienceLocation")}
                </span>
                <span>{partner.profile.audienceLocation.join(", ")}</span>
              </div>
            )}
          </div>
        </div>

        <AssetsSection market={market} partner={partner} reloadPartner={reloadPartner} />
      </div>
    </div>
  );
};

export default Profile;
