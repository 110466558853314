import React from "react";

import { CSVLink } from "react-csv";
import { FiDownload } from "react-icons/fi";

import Button from "components/forms/button/Button";

const DownloadToCsv = ({
  headers,
  data,
  disabled = false,
  buttonCopy = "Download Report (CSV)",
  filename = "download.csv",
}) => {
  if (disabled) {
    return (
      <Button
        appearance="primary"
        size="small"
        buttonElement="link"
        disabled
        icon={<FiDownload />}
        href="#"
        onClick={(e) => e.preventDefault()}
        role="button"
      >
        {buttonCopy}
      </Button>
    );
  }

  return (
    <CSVLink
      className="c-button c-button--primary c-button--small"
      data={data}
      headers={headers}
      filename={filename}
      target="_blank"
      role="button"
    >
      <>
        <i className="c-button__icon">
          <FiDownload />
        </i>
        <span className="button__content">{buttonCopy}</span>
      </>
    </CSVLink>
  );
};

export default DownloadToCsv;
