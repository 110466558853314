import { useEffect, useRef, useState } from "react";

import { sortObjectAlphabetically } from "helpers/Helpers";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import Button from "components/forms/button/Button";
import Checkbox from "components/forms/checkbox/Checkbox";
import CampaignHandler from "components/reports/campaign_handler/CampaignHandler";

import "./campaign_selector.scss";

const CampaignSelector = ({
  selectedMarket,
  selectedCampaigns,
  selectedStartDate,
  selectedEndDate,
  campaignFilter = () => true,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const campaignHandlerTitle = `${t(
    "reporting.dashboard.topbar.campaignSelector.campaignHandlerTitle"
  )}:`;
  const deselectAllButtonText = t("reporting.dashboard.topbar.campaignSelector.deselectAll");
  const multipleSelectedText = t(
    "reporting.dashboard.topbar.campaignSelector.campaignSelectorText.multiple"
  );
  const noneSelectedText = t(
    "reporting.dashboard.topbar.campaignSelector.campaignSelectorText.none"
  );

  const sortedCampaigns = sortObjectAlphabetically(
    selectedMarket.visibleCampaigns(),
    "name"
  ).filter((f) => campaignFilter(f));
  const [showSelector, setShowSelector] = useState(false);
  const originalSelectedCampaignSlugs = selectedCampaigns.map((c) => c.slug);
  const [selectedCampaignSlugs, setSelectedCampaignSlugs] = useState(originalSelectedCampaignSlugs);

  const selectedCampaignsField = () => {
    if (selectedCampaignSlugs.length > 1) {
      return multipleSelectedText;
    }

    if (selectedCampaignSlugs.length === 0) {
      return noneSelectedText;
    }

    return selectedMarket.campaignBySlug(selectedCampaignSlugs[0]).name;
  };
  const campaignSelectorRef = useRef();
  const selectedRange = {
    start_date: selectedStartDate,
    end_date: selectedEndDate,
  };

  const toggleSelector = () => setShowSelector(!showSelector);

  const handleCampaignSelect = (value, checked) => {
    let newSelectedCampaignSlugs;
    if (checked) {
      newSelectedCampaignSlugs = [...new Set(selectedCampaignSlugs.concat(value))];
    } else {
      newSelectedCampaignSlugs = selectedCampaignSlugs.filter((campaign) => campaign !== value);
    }

    setSelectedCampaignSlugs(newSelectedCampaignSlugs);
  };

  const deselectAllCampaigns = () => {
    setSelectedCampaignSlugs([]);
  };

  const differentSelectedCampaigns = () => {
    if (selectedCampaignSlugs.length !== originalSelectedCampaignSlugs.length) {
      return true;
    }

    return !selectedCampaignSlugs.every((e) => originalSelectedCampaignSlugs.includes(e));
  };

  const handleClickOutside = ({ target }) => {
    if (campaignSelectorRef.current && !campaignSelectorRef.current.contains(target)) {
      setShowSelector(false);
    }

    let campaigns;

    if (selectedCampaignSlugs.length > 0) {
      campaigns = selectedCampaignSlugs;
    } else {
      campaigns = selectedMarket.visibleCampaigns().map((c) => c.slug);
    }

    if (
      campaignSelectorRef.current &&
      !campaignSelectorRef.current.contains(target) &&
      differentSelectedCampaigns()
    ) {
      navigate(
        `/${selectedMarket.subdomain}/reports/referrer/dashboard?${qs.stringify({ ...selectedRange, campaigns })}`
      );
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => document.removeEventListener("click", handleClickOutside, true);
  });

  return (
    <div
      role="select"
      aria-label="campaign-selector"
      className="c-campaign-selector"
      ref={campaignSelectorRef}
    >
      <CampaignHandler
        icon={<FaBars />}
        title={campaignHandlerTitle}
        onClick={toggleSelector}
        isActive={showSelector}
      >
        <span className="c-campaign-selector__selector--active">{selectedCampaignsField()}</span>
      </CampaignHandler>
      {showSelector && (
        <ul className="c-campaign-selector-list">
          {sortedCampaigns.length > 1 && (
            <li className="c-campaign-selector-list__item-action">
              <Button
                onClick={deselectAllCampaigns}
                disabled={selectedCampaignSlugs.length === 0}
                appearance="secondary"
                size="tiny"
              >
                {deselectAllButtonText}
              </Button>
            </li>
          )}
          {sortedCampaigns.map((campaign) => (
            <li key={campaign.id}>
              <Checkbox
                label={campaign.name}
                name={campaign.name}
                value={campaign.slug}
                handleChange={handleCampaignSelect}
                checked={selectedCampaignSlugs.includes(campaign.slug)}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CampaignSelector;
