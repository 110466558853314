import React from "react";

import classnames from "classnames";

import "./error_loader.scss";

const ErrorLoader = ({ errorText, showError, hasCardWrapper = false }) => {
  const errorClassNames = classnames("c-error-loader", {
    card: hasCardWrapper,
  });

  return (
    showError && (
      <div className={errorClassNames}>
        <p>{errorText}</p>
      </div>
    )
  );
};

export default ErrorLoader;
