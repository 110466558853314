import React from "react";

import "./icon.scss";

const Icon = ({ type: Type, className = "" }) => (
  <i className={`c-icon ${className}`}>
    <Type />
  </i>
);

export default Icon;
