import React, { useEffect, useState } from "react";

import EmailValidator from "email-validator";
import Market from "models/Market";
import { useTranslation } from "react-i18next";

import FileImport from "components/bulk_actions/file_import/FileImport";
import ForgetUsersHistory from "components/bulk_actions/forget_users/forget_users_history/ForgetUsersHistory";
import TextImport from "components/bulk_actions/text_import/TextImport";
import Button from "components/forms/button/Button";
import Modal from "components/modals/Modal";

import "./forget_users.scss";

const ForgetUsers = ({ selectedMarket }) => {
  const { t } = useTranslation();
  const [showRequiredColumnsModal, setShowRequiredColumnsModal] = useState(false);
  const [checkHistory, setCheckHistory] = useState(true);
  const [importList, setImportList] = useState([]);
  const [importListLoadingError, setImportListLoadingError] = useState(false);

  const orText = t("global.or");

  const header = t("bulkActions.forgetUsers.header");
  const subtitle = t("bulkActions.forgetUsers.subtitle");

  const modalOpenButtonText = t("bulkActions.forgetUsers.modal.openButtonText");
  const modalTitle = t("bulkActions.forgetUsers.modal.title");
  const modalEmailDescription = t("bulkActions.forgetUsers.modal.emailDescription");

  const fileImportButtonLabel = t("bulkActions.forgetUsers.fileImport.buttonLabel");

  const textImportButtonLabel = t("bulkActions.forgetUsers.textImport.buttonLabel");
  const textImportPlaceholder = t("bulkActions.forgetUsers.textImport.placeholder");
  const textImportInvalidWarning = t("bulkActions.forgetUsers.textImport.invalidWarning");

  const toggleModal = () => {
    setShowRequiredColumnsModal(!showRequiredColumnsModal);
  };

  const modalTable = (
    <>
      <table className="portal-table">
        <tbody>
          <tr>
            <td>Email</td>
            <td>{modalEmailDescription}</td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const onTextImportValidation = (emails) => {
    const badEmails = emails.filter((email) => !EmailValidator.validate(email));
    if (badEmails.length > 0) {
      return [textImportInvalidWarning, ...badEmails];
    }

    return [];
  };

  const onImportSuccess = () => {
    setCheckHistory(!checkHistory);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const forgetUsersHistory = await selectedMarket.imports.getList("forget_users");
        setImportList(forgetUsersHistory.slice(0, 5));
      } catch (err) {
        setImportListLoadingError(true);
      }
    };

    getData();
  }, [checkHistory, selectedMarket?.imports]);

  return (
    <div className="c-forget-users card">
      <h2 className="card__title">{header}</h2>
      <p>{subtitle}</p>
      <Button appearance="link" onClick={toggleModal}>
        {modalOpenButtonText}
      </Button>
      <div className="c-forget-users__submit-wrapper">
        <FileImport
          selectedMarket={selectedMarket}
          importType="forget_users"
          buttonLabel={fileImportButtonLabel}
          onImportSuccess={onImportSuccess}
        />
        <h2>{orText}</h2>
        <TextImport
          selectedMarket={selectedMarket}
          importType="forget_users"
          buttonLabel={textImportButtonLabel}
          csvHeaders={["Email"]}
          placeholderText={textImportPlaceholder}
          onImportValidation={onTextImportValidation}
          onImportSuccess={onImportSuccess}
        />
      </div>
      <Modal
        className="c-forget-users__required-modal"
        buttonClassName="c-button--tertiary"
        closeModal={toggleModal}
        openModal={showRequiredColumnsModal}
        title={modalTitle}
        fullWidth
        closeButtonAriaLabel={t("global.close")}
      >
        {modalTable}
      </Modal>
      <ForgetUsersHistory
        importList={importList}
        timezoneOffset={selectedMarket.timezone_offset}
        importListLoadingError={importListLoadingError}
      />
    </div>
  );
};

export default ForgetUsers;
