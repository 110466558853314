import React from "react";

import classnames from "classnames";

import "./badge.scss";

const Badge = ({ content, type = "primary" }) => {
  const badgeClasses = classnames("c-badge", {
    [`c-badge--${type}`]: type,
  });

  return (
    <div className={badgeClasses}>
      <span>{content}</span>
    </div>
  );
};

export default Badge;
