import React, { useState } from "react";

import Fulfilment from "models/Fulfilment";
import Market from "models/Market";
import { Trans, useTranslation } from "react-i18next";
import { FiInfo } from "react-icons/fi";

import Status from "components/customer_lookup/row_atoms/status/Status";
import UserFulfilmentActions from "components/customer_lookup/user/user_fulfilments/user_fulfilment_actions/UserFulfilmentActions";
import UserFulfilmentExpire from "components/customer_lookup/user/user_fulfilments/user_fulfilment_expire/UserFulfilmentExpire";
import UserFulfilmentExpiryChange from "components/customer_lookup/user/user_fulfilments/user_fulfilment_expiry_change/UserFulfilmentExpiryChange";
import UserFulfilmentUnexpire from "components/customer_lookup/user/user_fulfilments/user_fulfilment_unexpire/UserFulfilmentUnexpire";
import FormattedDate from "components/formatted_date/FormattedDate";
import InfoBox from "components/info_box/InfoBox";
import Modal from "components/modals/Modal";
import addNotification from "components/notifications/Notifications";

import "./user_fulfilments.scss";

const UserFulfilments = ({ fulfilments, userId, selectedMarket, reloadView }) => {
  const { t } = useTranslation();

  const shouldHide = fulfilments.length === 0;
  const anyWithActions = fulfilments.some((f) => f.hasActions());

  const [showChangeExpiryModal, setShowChangeExpiryModal] = useState(false);
  const [showUnexpireModal, setShowUnexpireModal] = useState(false);
  const [showExpireModal, setShowExpireModal] = useState(false);
  const [selectedFulfilmentId, setSelectedFulfilmentId] = useState(null);

  const openChangeExpiryModal = (fulfilmentId) => {
    setShowChangeExpiryModal(true);
    setSelectedFulfilmentId(fulfilmentId);
  };
  const closeChangeExpiryModal = () => {
    setShowChangeExpiryModal(false);
    setSelectedFulfilmentId(null);
  };

  const openUnexpireModal = (fulfilmentId) => {
    setShowUnexpireModal(true);
    setSelectedFulfilmentId(fulfilmentId);
  };
  const closeUnexpireModal = () => {
    setShowUnexpireModal(false);
    setSelectedFulfilmentId(null);
  };

  const openExpireModal = (fulfilmentId) => {
    setShowExpireModal(true);
    setSelectedFulfilmentId(fulfilmentId);
  };
  const closeExpireModal = () => {
    setShowExpireModal(false);
    setSelectedFulfilmentId(null);
  };
  const triggerUpdateEventAndReload = (fn) => () => {
    fn();
    addNotification.success(
      t("global.success"),
      t("fulfilments.notifications.successfulExpiryDateUpdate")
    );
    reloadView();
  };

  const triggerExpireEventAndReload = (fn) => () => {
    fn();
    addNotification.success(t("global.success"), t("fulfilments.notifications.successfulExpiry"));
    reloadView();
  };
  const onSuccessfulChangeExpiry = triggerUpdateEventAndReload(closeChangeExpiryModal);
  const onSuccessfulUnexpiry = triggerUpdateEventAndReload(closeUnexpireModal);
  const onSuccessfulExpiry = triggerExpireEventAndReload(closeExpireModal);

  return (
    shouldHide || (
      <div className="c-user-fulfilments card">
        <h2 className="card__title">{t("fulfilments.header")}</h2>

        <InfoBox type="primary">
          <div className="c-user-fulfilments info-box-content">
            <Trans i18nKey="fulfilments.infoBox">
              Any time you see the <FiInfo /> icon you can hover over it to learn more
            </Trans>
          </div>
        </InfoBox>

        <div className="table-container">
          <table className="portal-table c-user-fulfilments--table">
            <thead>
              <tr className="break-word">
                <th>{t("fulfilments.tableHeaders.name")}</th>
                <th>{t("fulfilments.tableHeaders.dateEarned")}</th>
                <th>{t("fulfilments.tableHeaders.source")}</th>
                <th>{t("fulfilments.tableHeaders.expiryDate")}</th>
                <th>{t("fulfilments.tableHeaders.status")}</th>
                {anyWithActions && <th />}
              </tr>
            </thead>
            <tbody>
              {fulfilments.map((fulfilment) => (
                <tr className="" key={fulfilment.id}>
                  <td>{fulfilment.name}</td>
                  <td>
                    <FormattedDate
                      timestamp={fulfilment.createdAt}
                      timezoneOffset={selectedMarket.timezone_offset}
                      type="long"
                    />
                  </td>
                  {/* source is an email address or "Referral" */}
                  <td>
                    {fulfilment.source === "Referral"
                      ? t(`fulfilments.source.${fulfilment.source.toLowerCase()}`)
                      : fulfilment.source}
                  </td>
                  <td>
                    {fulfilment.showExpiryDate && fulfilment.expiryDate ? (
                      <FormattedDate
                        timestamp={fulfilment.expiryDate}
                        timezoneOffset={selectedMarket.timezone_offset}
                        type="long"
                      />
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>
                    <Status
                      status={fulfilment.status}
                      timezoneOffset={selectedMarket.timezone_offset}
                    />
                  </td>
                  {anyWithActions && (
                    <td>
                      <UserFulfilmentActions
                        fulfilment={fulfilment}
                        userId={userId}
                        selectedMarket={selectedMarket}
                        openChangeExpiryModal={openChangeExpiryModal}
                        openUnexpireModal={openUnexpireModal}
                        openExpireModal={openExpireModal}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Modal
          closeModal={closeChangeExpiryModal}
          closeModalText={t("global.cancel")}
          openModal={showChangeExpiryModal}
          title={t("fulfilments.changeExpiryDateText")}
          id="change-expiry-date"
          showCloseButton={false}
        >
          <UserFulfilmentExpiryChange
            fulfilmentId={selectedFulfilmentId}
            selectedMarket={selectedMarket}
            onSuccessfulUpdate={onSuccessfulChangeExpiry}
            onCancel={closeChangeExpiryModal}
            cancelText={t("global.cancel")}
          />
        </Modal>
        <Modal
          closeModal={closeUnexpireModal}
          closeModalText={t("global.cancel")}
          openModal={showUnexpireModal}
          title={t("fulfilments.unexpireModalText")}
          id="unexpire-fulfilment"
          showCloseButton={false}
        >
          <UserFulfilmentUnexpire
            fulfilmentId={selectedFulfilmentId}
            selectedMarket={selectedMarket}
            onSuccessfulUpdate={onSuccessfulUnexpiry}
            onCancel={closeUnexpireModal}
            cancelText={t("global.cancel")}
          />
        </Modal>
        <Modal
          closeModal={closeExpireModal}
          closeModalText={t("global.cancel")}
          openModal={showExpireModal}
          title={t("fulfilments.expireModalText")}
          id="expire-fulfilment"
          showCloseButton={false}
          subTitle={t("fulfilments.expireModalSubtitle")}
          header
          headerType="danger"
        >
          <UserFulfilmentExpire
            fulfilmentId={selectedFulfilmentId}
            selectedMarket={selectedMarket}
            onSuccessfulUpdate={onSuccessfulExpiry}
            onCancel={closeExpireModal}
            cancelText={t("global.cancel")}
            errorNotificationTitle={t("global.error")}
            errorMessage={t("fulfilments.notifications.expiryErrorMessage")}
          />
        </Modal>
      </div>
    )
  );
};

export default UserFulfilments;
