import React from "react";

import Market from "models/Market";
import { Translation } from "react-i18next";

import Button from "components/forms/button/Button";
import TextInput from "components/forms/text_input/TextInput";

import "../user_fulfilments.scss";

class UserFulfilmentUnexpire extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expiryDate: "",
      loading: false,
      formErrors: {},
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ loading: true, formErrors: {} });

    const { fulfilmentId = null, selectedMarket, onSuccessfulUpdate } = this.props;
    const { expiryDate } = this.state;

    try {
      await selectedMarket.users.unexpireFulfilment(fulfilmentId, expiryDate);
      onSuccessfulUpdate();
    } catch (e) {
      if (e && e.errors) {
        this.setState({ formErrors: e.errors });
      }
      this.setState({ loading: false });
    }
  };

  render() {
    const { expiryDate, loading, formErrors } = this.state;
    const { cancelText, onCancel } = this.props;

    return (
      <Translation>
        {(t) => (
          <form className="c-form">
            <TextInput
              labelText={t("forms.labels.expiryDate")}
              id="expiryDate"
              value={expiryDate}
              handleChange={this.handleChange}
              required
              focus
              errors={formErrors.expiry_date}
              hint={t("forms.hints.expiryDate")}
            />
            <div className="expiry-container-actions">
              <Button onClick={onCancel} appearance="secondary" outline fullWidth loading={loading}>
                {cancelText}
              </Button>
              <Button appearance="tertiary" fullWidth loading={loading} onClick={this.handleSubmit}>
                {t("global.save")}
              </Button>
            </div>
          </form>
        )}
      </Translation>
    );
  }
}

export default UserFulfilmentUnexpire;
