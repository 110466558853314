import React from "react";

import Market from "models/Market";
import SearchResult from "models/SearchResult";
import { Trans, useTranslation } from "react-i18next";

import UserResult from "components/customer_lookup/search/user_results/user_result/UserResult";

import "./user_results.scss";

const UserResults = ({
  selectedMarket,
  hasSearched = false,
  searchTerm = "",
  searchResults = [],
}) => {
  const { t } = useTranslation();

  const tableHeaderName = t("userResults.tableHeader.name");
  const tableHeaderEmail = t("userResults.tableHeader.email");
  const tableHeaderIdentifier = t("userResults.tableHeader.identifier");
  const tableHeaderType = t("userResults.tableHeader.type");
  const tableHeaderReferrals = t("userResults.tableHeader.referrals");

  if (searchResults.length === 0 && hasSearched) {
    return (
      <p className="status-message">
        <Trans t={t} i18nKey="userResults.noResultMessage" values={{ searchTerm }} />
      </p>
    );
  }

  if (searchResults.length > 0 && hasSearched) {
    return (
      <div className="table-container">
        <table className="portal-table hover-highlight search-results">
          <thead>
            <tr>
              <th scope="col">
                {tableHeaderName} &amp; {tableHeaderEmail}
              </th>
              <th scope="col">{tableHeaderIdentifier}</th>
              <th scope="col">{tableHeaderType}</th>
              <th scope="col">{tableHeaderReferrals}</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((searchResult) => (
              <UserResult
                key={searchResult.id}
                searchResult={searchResult}
                selectedMarket={selectedMarket}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

export default UserResults;
