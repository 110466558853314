import React from "react";

import { FaBullhorn, FaUser } from "react-icons/fa";

import Icon from "components/icon/Icon";

const UserType = ({ type }) =>
  type === "customer" ? <Icon type={FaBullhorn} /> : <Icon type={FaUser} />;

export default UserType;
