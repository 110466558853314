import React from "react";

import { Interval } from "luxon";

import "components/reports/date_picker/date_range_selector/custom_range/calendars/calendar_day/calendar_day.scss";

const CalendarDay = ({
  startDay,
  day,
  updateNewDateRange,
  currentDate,
  newStartDate,
  newEndDate,
}) => {
  const className = ["day"];
  const date = currentDate.set({ day });

  if (date.hasSame(date.startOf("month"), "day", { useLocaleWeeks: true })) {
    className.push(`start-day--${startDay}`);
    className.push("first-day");
  }

  if (date.hasSame(date.endOf("month"), "day", { useLocaleWeeks: true })) {
    className.push("last-day");
  }

  if (date.hasSame(newStartDate, "day", { useLocaleWeeks: true })) {
    className.push("selected-start-date");
  }

  if (date.hasSame(newEndDate, "day", { useLocaleWeeks: true })) {
    className.push("selected-end-date");
  }

  if (Interval.fromDateTimes(newStartDate, newEndDate.endOf("day")).contains(date)) {
    className.push("within-range");
  }
  if (date.weekday === 6) {
    className.push("saturday");
  }

  if (date.weekday === 7) {
    className.push("sunday");
  }

  return (
    <div
      className={className.join(" ")}
      onClick={() => updateNewDateRange(date)}
      onKeyDown={() => updateNewDateRange(date)}
      role="button"
      key={day}
      tabIndex={0}
    >
      <span>{day}</span>
    </div>
  );
};

export default CalendarDay;
