import React, { forwardRef } from "react";

import i18next from "i18next";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

import "./checkbox.scss";

const Checkbox = forwardRef(
  (
    {
      label,
      value,
      handleChange,
      onFocus = null,
      checked = false,
      name = "",
      errors = [],
      errorKey = null,
    },
    ref
  ) => {
    const handleOnChange = (e) => {
      handleChange(e.target.value, e.target.checked, e.target.name);
    };

    return (
      <>
        <label className="c-checkbox">
          <input
            type="checkbox"
            className="c-checkbox__item"
            name={name}
            aria-checked={checked}
            aria-label={`checkbox-label--${name}`}
            checked={checked}
            onChange={handleOnChange}
            value={value}
            tabIndex="0"
            onFocus={onFocus}
            ref={ref}
          />
          <div className="c-checkbox-icon">
            <i className="c-checkbox-icon__checked">
              <MdCheckBox />
            </i>
            <i className="c-checkbox-icon__un-checked">
              <MdCheckBoxOutlineBlank />
            </i>
          </div>
          <span id={`checkbox-label--${name}`} className="c-checkbox__label">
            {label}
          </span>
        </label>
        {errors.map((error) => (
          <div className="c-select__error-message" key={error}>
            {i18next.t(`forms.errors.${errorKey || name}.${error.type}`)}
          </div>
        ))}
      </>
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
