import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { FiFilter } from "react-icons/fi";

import ErrorLoader from "components/error_loader/ErrorLoader";
import Button from "components/forms/button/Button";
import "components/multi_select_filter/multi_select_filter.scss";
import CampaignHandler from "components/reports/campaign_handler/CampaignHandler";
import Spinner from "components/spinner/Spinner";

import MultiSelectFilterList from "./MultiSelectFilterList";

const MultiSelectFilterContainer = ({
  selectedOptions,
  buttonCopy,
  handleClearAll,
  onCancel,
  onApply,
  options,
  handleSelectAll,
  handleOptionChange,
  buttonClassName = undefined,
  isLoading = false,
  errorText = undefined,
  selectAllLabel = "Select All",
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleCancel = () => {
    onCancel();
    setIsOpen(false);
  };

  const handleApply = () => {
    onApply();
    setIsOpen(false);
  };

  const handleOpen = () => {
    if (isOpen) {
      handleCancel();
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className="c-multi-select-filter">
      <CampaignHandler
        icon={<FiFilter />}
        title={t("global.filter")}
        onClick={handleOpen}
        isActive={isOpen}
        className={buttonClassName}
      >
        <span className="c-multi-select-filter--active">{buttonCopy}</span>
      </CampaignHandler>

      {isOpen && (
        <div className="c-multi-select-filter-content">
          <div className="c-multi-select-filter-content-head">
            <h2 className="c-multi-select-filter-content-head-heading">
              {t("components.multiSelectFilter.header")}
            </h2>

            <Button
              onClick={handleClearAll}
              disabled={selectedOptions.length === 0}
              appearance="link"
            >
              {t("global.clearAll")}
            </Button>
          </div>

          {isLoading && (
            <div className="c-multi-select-filter-content-list">
              <Spinner showSpinner loadingText="" fullHeight={false} />
            </div>
          )}

          {!!errorText && (
            <div className="c-multi-select-filter-content-list">
              <ErrorLoader errorText={errorText} showError={!!errorText} />
            </div>
          )}

          {!isLoading && !errorText && (
            <>
              <MultiSelectFilterList
                selectedOptions={selectedOptions}
                options={options}
                handleSelectAll={handleSelectAll}
                handleOptionChange={handleOptionChange}
                selectAllLabel={selectAllLabel}
              />

              <div className="c-multi-select-filter-content-foot">
                <Button onClick={handleCancel} className="c-campaign-handler" size="small">
                  {t("global.cancel")}
                </Button>

                <Button
                  onClick={handleApply}
                  appearance="primary"
                  size="small"
                  disabled={selectedOptions.length < 1}
                >
                  {t("global.apply")}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelectFilterContainer;
