import React from "react";

import { OpenWindowButton } from "assets/images/CustomIcons";

import Tooltip from "components/tooltip/Tooltip";

import "./tooltip_link.scss";

const TooltipLink = ({ href, tooltipMessage }) => (
  <div className="tooltip-link-container">
    <Tooltip content={tooltipMessage}>
      <a href={href} target="_blank" rel="noreferrer" className="tooltip-link__icon">
        <OpenWindowButton />
      </a>
    </Tooltip>
  </div>
);

export default TooltipLink;
