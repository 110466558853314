import React, { useRef } from "react";

import { useTranslation } from "react-i18next";

import Button from "components/forms/button/Button";
import Form from "components/forms/form/Form";
import Modal from "components/modals/Modal";
import Fields from "components/partner_management/profile_form/profile_form_modal/fields/Fields";

const ProfileFormModal = ({
  selectedMarket,
  form,
  setFormFields,
  setFormErrors,
  resetForm,
  action,
  openModal,
  toggleModal,
  handleFormSubmit,
  validateEmail,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      // TODO: sc-31158 handle other errors besides email.
      if (form.formErrors?.email?.length > 0) {
        ref.current.focus();
        return;
      }
      await handleFormSubmit();
      resetForm();
      toggleModal();
    } catch (exception) {
      setFormErrors(exception.errors);
      if (form.formErrors?.email?.length > 0) {
        ref.current.focus();
      }
    }
  };

  return (
    <Modal openModal={openModal} closeModal={toggleModal} showCloseButton={false} fullWidth>
      <Form onSubmit={handleSubmit} errorKey="creation" errors={form.formErrors?.creation}>
        <div className="partner-form__modal-header">
          <span>{t("forms.labels.mandatoryField")}</span>
        </div>
        <Fields
          selectedMarket={selectedMarket}
          action={action}
          form={form}
          setFormFields={setFormFields}
          validateEmail={validateEmail}
          ref={ref}
        />
        <div className="partner-form__modal-footer">
          <Button buttonElement="submit" size="large" appearance="primary">
            {t("global.save")}
          </Button>
          <Button
            buttonElement="button"
            size="large"
            appearance="secondary"
            outline
            onClick={toggleModal}
          >
            {t("global.cancel")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ProfileFormModal;
