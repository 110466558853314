import React, { Component } from "react";

import i18next from "i18next";
import BaseUser from "models/BaseUser";
import Market from "models/Market";

import Button from "components/forms/button/Button";
import Modal from "components/modals/Modal";

class CisUserCampaignValidator extends Component {
  state = {
    revalidationErrors: false,
    isLoading: false,
  };

  handleSubmit = async () => {
    const { handleToggleSave, selectedMarket, user } = this.props;
    this.setState({ isLoading: true });

    try {
      await selectedMarket.cisUsers.resendForValidation(user.cisId);

      handleToggleSave();
    } catch (error) {
      this.setState({ revalidationErrors: true });
    }

    this.setState({ isLoading: false });
  };

  render() {
    const { handleToggleClose, toggleModalState } = this.props;
    const { revalidationErrors, isLoading } = this.state;

    return (
      <Modal
        closeModal={handleToggleClose}
        closeModalText={i18next.t("global.cancel")}
        openModal={toggleModalState}
        title={i18next.t("campaign.validator.modalTitle")}
      >
        <form className="resend-for-validation-form" onSubmit={(e) => e.preventDefault()}>
          <p>{i18next.t("campaign.validator.formPrompt")}</p>

          {revalidationErrors && (
            <div className="messages">
              <div className="error">{i18next.t("campaign.validator.errorMessage")}</div>
            </div>
          )}
          <Button
            appearance="tertiary"
            className="u-stack-3"
            onClick={this.handleSubmit}
            fullWidth
            loading={isLoading}
          >
            {i18next.t("campaign.validator.buttonLabel")}
          </Button>
        </form>
      </Modal>
    );
  }
}

export default CisUserCampaignValidator;
