import React from "react";

import { FaCheck, FaHourglassEnd, FaTimes } from "react-icons/fa";

const ReferralStatus = ({ status }) => {
  if (status.isFulfilled()) {
    return <FaCheck />;
  }
  if (status.isCancelled()) {
    return <FaTimes />;
  }
  return <FaHourglassEnd />;
};

export default ReferralStatus;
