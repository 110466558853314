import React from "react";

import "./counter.scss";

function decreaseVisualFocus(int) {
  if (int === 0) return "decrease-visual-focus";
  return "";
}

const Counter = ({ icon, count, status }) => (
  <div className={`counter status-${status} ${decreaseVisualFocus(count)}`}>
    {icon}
    {count}
  </div>
);

export default Counter;
