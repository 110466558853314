import React, { useCallback, useEffect, useState } from "react";

import { Download } from "assets/images/CustomIcons";
import { useTranslation } from "react-i18next";

import Badge from "components/badge/Badge";
import FormattedDate from "components/formatted_date/FormattedDate";
import Button from "components/forms/button/Button";
import addNotification from "components/notifications/Notifications";
import DateRangeSelected from "components/reports/date_picker/date_range_selected/DateRangeSelected";

import "./report_download_card.scss";

const ReportDownloadCard = ({
  title,
  description,
  type,
  selectedMarket,
  selectedCampaignId,
  recommendedRange,
  defaultStartDate,
  defaultEndDate,
}) => {
  const { t } = useTranslation();

  const generatedText = `${t("reporting.management.downloadCard.generatedText")}: `;
  const neverGenaratedText = t("reporting.management.downloadCard.neverGenaratedText");
  const changeDateText = t("reporting.management.downloadCard.changeDateText");

  const generateReportLoadingText = t(
    "reporting.management.downloadCard.buttonGenerateReport.loading"
  );
  const generateReportHasReportText = t(
    "reporting.management.downloadCard.buttonGenerateReport.hasReport"
  );
  const generateReportNoReportText = t(
    "reporting.management.downloadCard.buttonGenerateReport.noReport"
  );
  const dateText = t("reporting.management.downloadCard.dateText");

  const generateAgainButtonText = t("reporting.management.downloadCard.generateAgainButtonText");
  const errorNotificationTitle = t("global.error");
  const errorSubmittedMessage = t("reporting.management.downloadCard.errorSubmittedMessage");

  const [isLoading, setIsLoading] = useState(false);
  const [generatedReport, setGeneratedReport] = useState(null);
  const [downloadedReport, setDownloadedReport] = useState(null);
  const [cancelPoller, setCancelPoller] = useState(null);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const setPoller = useCallback(
    async (reportId) => {
      const [reportPoller, cancel] =
        await selectedMarket.platformReports.generateCampaignReportPoller(reportId);

      setCancelPoller(() => cancel);

      const downloadReport = await reportPoller;

      setCancelPoller(null);

      return downloadReport;
    },
    [selectedMarket?.platformReports]
  );

  const handleGenerateDownload = useCallback(async () => {
    setIsLoading(true);
    let report;

    try {
      const newReportId = await selectedMarket.platformReports.build(
        type,
        selectedCampaignId,
        String(startDate),
        String(endDate)
      );
      report = await setPoller(newReportId);
      setDownloadedReport(report);
      window.location.href = report.url;
      setIsLoading(false);
    } catch (e) {
      addNotification.warning(errorNotificationTitle, errorSubmittedMessage);
      setIsLoading(false);
    }
  }, [selectedCampaignId, selectedMarket?.platformReports, setPoller, startDate, endDate, type]);

  const downloadReportFile = () => {
    setIsLoading(true);
    setDownloadedReport(generatedReport);
    window.location.href = generatedReport.url;
    setIsLoading(false);
  };

  const getReportStatus = useCallback(async () => {
    try {
      const generatedReportResponse = await selectedMarket.platformReports.recentCampaignReport(
        type,
        selectedCampaignId
      );

      if (!generatedReportResponse.ready) {
        setIsLoading(true);
        setPoller(generatedReportResponse.id);
      }

      setGeneratedReport(generatedReportResponse);
    } catch (e) {
      setGeneratedReport(false);
    }
  }, [selectedCampaignId, selectedMarket?.platformReports, setPoller, type]);

  const action = (newStartDate, newEndDate) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  useEffect(() => {
    getReportStatus();

    return () => {
      if (cancelPoller != null) {
        cancelPoller();
      }
    };
  }, [selectedCampaignId, downloadedReport]);

  return (
    <div className="c-report-download-card">
      <div className="u-stack-4">
        <div className="c-report-download-card__header u-stack-4">
          <div className="c-report-download-card__header__title">{title}</div>
          <div className="c-report-download-card__header__badge">
            <Badge type="primary" content={recommendedRange} />
          </div>
        </div>

        <span className="c-report-download-card__description u-stack-4">{description}</span>
        <span className="c-report-download-card__last-downloaded">
          {!generatedReport ? (
            neverGenaratedText
          ) : (
            <>
              <span className="c-report-download-card__last-downloaded__date">
                {generatedText}
                <FormattedDate
                  timestamp={generatedReport.generatedAt}
                  timezoneOffset={selectedMarket.timezone_offset}
                  type="short"
                />
                {generatedReport.startsAt && generatedReport.endsAt && (
                  <span>
                    {` (${dateText}: `}
                    <FormattedDate
                      timestamp={generatedReport.startsAt}
                      timezoneOffset={selectedMarket.timezone_offset}
                      type="short"
                    />
                    {" - "}
                    <FormattedDate
                      timestamp={generatedReport.endsAt}
                      timezoneOffset={selectedMarket.timezone_offset}
                      type="short"
                    />
                    {") "}
                  </span>
                )}
              </span>
              <span className="c-report-download-card__last-downloaded__info">
                {changeDateText}
              </span>
            </>
          )}
        </span>
      </div>
      <div>
        <div className="c-report-download-card__date_selector u-stack-3">
          <DateRangeSelected
            selectedStartDate={startDate}
            selectedEndDate={endDate}
            timezoneOffset={selectedMarket.timezone_offset}
            action={action}
          />
        </div>
        <div className="c-report-download-card__report_download">
          <Button
            appearance="primary"
            size="small"
            icon={<Download />}
            loading={isLoading}
            onClick={generatedReport ? downloadReportFile : handleGenerateDownload}
            fullWidth
          >
            {!isLoading &&
              (generatedReport ? generateReportHasReportText : generateReportNoReportText)}
            {isLoading && generateReportLoadingText}
          </Button>
          {!isLoading && generatedReport && (
            <Button appearance="secondary" size="small" onClick={handleGenerateDownload} fullWidth>
              {generateAgainButtonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportDownloadCard;
