import React from "react";

import { useNavigate } from "react-router-dom";

const Partner = ({ partner, subdomain }) => {
  const navigate = useNavigate();

  const {
    business_name: businessName,
    first_name: firstName,
    last_name: lastName,
    email,
  } = partner;
  return (
    <tr
      role={"link"}
      className="single-result clickable"
      onClick={() => navigate(`/${subdomain}/users/partners/${partner.id}`)}
    >
      <td>
        <span className="full-name">{businessName}</span>
      </td>
      <td>{email}</td>
      <td>
        {firstName} {lastName}
      </td>
    </tr>
  );
};

export default Partner;
