import React from "react";

import { getHistoryStatusText } from "helpers/Helpers";
import { useTranslation } from "react-i18next";

import ErrorLoader from "components/error_loader/ErrorLoader";
import FormattedDate from "components/formatted_date/FormattedDate";

import "./transaction_tracking_history.scss";

const TransactionTrackingHistory = ({ importList, timezoneOffset, importListLoadingError }) => {
  const { t } = useTranslation();
  const shouldHide = importList.length === 0 && !importListLoadingError;

  if (shouldHide) {
    return "";
  }

  const headerText = t("bulkActions.transactionTracking.history.header");
  const tableHeadersupStatus = t("bulkActions.transactionTracking.history.tableHeaders.status");
  const tableHeadersuploadedAt = t(
    "bulkActions.transactionTracking.history.tableHeaders.uploadedAt"
  );
  const tableHeadersuploadedBy = t(
    "bulkActions.transactionTracking.history.tableHeaders.uploadedBy"
  );

  const referralsText = t("bulkActions.transactionTracking.history.tableHeaders.referrals");
  const rejectedText = t("bulkActions.transactionTracking.history.tableHeaders.rejected");
  const successfulText = t("bulkActions.transactionTracking.history.tableHeaders.successful");
  const transactionsText = t("bulkActions.transactionTracking.history.tableHeaders.transactions");
  const unsuccessfulText = t("bulkActions.transactionTracking.history.tableHeaders.unsuccessful");

  return (
    <div className="c-import-history">
      <h2 className="card__title">{headerText}</h2>
      {importListLoadingError || (
        <div className="table-container">
          <table className="portal-table c-import-history--table">
            <thead>
              <tr>
                <th>{tableHeadersuploadedAt}</th>
                <th>{tableHeadersuploadedBy}</th>
                <th>{tableHeadersupStatus}</th>
                <th>
                  {successfulText}
                  <br />
                  {referralsText}
                </th>
                <th>
                  {unsuccessfulText}
                  <br />
                  {referralsText}
                </th>
                <th>
                  {rejectedText}
                  <br />
                  {transactionsText}
                </th>
              </tr>
            </thead>
            <tbody>
              {importList.map((importRecord) => (
                <tr key={importRecord.id}>
                  <td>
                    <FormattedDate
                      timestamp={importRecord.uploaded_at}
                      timezoneOffset={timezoneOffset}
                      type="long_with_time"
                    />
                  </td>
                  <td>{importRecord.created_by}</td>
                  <td>{getHistoryStatusText(importRecord.status)}</td>
                  <td>{importRecord.successful_referral_count}</td>
                  <td>{importRecord.unsuccessful_referral_count}</td>
                  <td>{importRecord.rejected_transaction_count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <ErrorLoader
        errorText={t("forms.errors.bulkActions.load_history")}
        showError={importListLoadingError}
      />
    </div>
  );
};

export default TransactionTrackingHistory;
