import React from "react";

import { getHistoryStatusText } from "helpers/Helpers";
import { useTranslation } from "react-i18next";

import ErrorLoader from "components/error_loader/ErrorLoader";
import FormattedDate from "components/formatted_date/FormattedDate";

import "./add_customers_history.scss";

const AddCustomersHistory = ({ importList, timezoneOffset, importListLoadingError }) => {
  const { t } = useTranslation();
  const shouldHide = importList.length === 0 && !importListLoadingError;

  if (shouldHide) {
    return "";
  }

  const header = t("bulkActions.addCustomers.history.header");
  const tableHeaderStatus = t("bulkActions.addCustomers.history.tableHeaders.status");
  const tableHeaderUploadedAt = t("bulkActions.addCustomers.history.tableHeaders.uploadedAt");
  const tableHeaderUploadedBy = t("bulkActions.addCustomers.history.tableHeaders.uploadedBy");

  const tableHeaderCustomers = t("bulkActions.addCustomers.history.tableHeaders.customers");
  const tableHeaderExisting = t("bulkActions.addCustomers.history.tableHeaders.existing");
  const tableHeaderNew = t("bulkActions.addCustomers.history.tableHeaders.new");
  const tableHeaderNumberOf = t("bulkActions.addCustomers.history.tableHeaders.numberOf");
  const tableHeaderProcessedRows = t("bulkActions.addCustomers.history.tableHeaders.processedRows");

  return (
    <div className="c-import-history">
      <h2 className="card__title">{header}</h2>
      {importListLoadingError || (
        <div className="table-container">
          <table className="portal-table c-import-history--table">
            <thead>
              <tr>
                <th>{tableHeaderUploadedAt}</th>
                <th>{tableHeaderUploadedBy}</th>
                <th>{tableHeaderStatus}</th>
                <th>
                  {tableHeaderNew}
                  <br />
                  {tableHeaderCustomers}
                </th>
                <th>
                  {tableHeaderExisting}
                  <br />
                  {tableHeaderCustomers}
                </th>
                <th>
                  {tableHeaderNumberOf}
                  <br />
                  {tableHeaderProcessedRows}
                </th>
              </tr>
            </thead>
            <tbody>
              {importList.map((importRecord) => (
                <tr key={importRecord.id}>
                  <td>
                    <FormattedDate
                      timestamp={importRecord.uploaded_at}
                      timezoneOffset={timezoneOffset}
                      type="long_with_time"
                    />
                  </td>
                  <td>{importRecord.created_by}</td>
                  <td>{getHistoryStatusText(importRecord.status)}</td>
                  <td>{importRecord.total_imported_users}</td>
                  <td>{importRecord.number_of_existing_users}</td>
                  <td>{importRecord.total_rows_processed}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <ErrorLoader
        errorText={t("forms.errors.bulkActions.load_history")}
        showError={importListLoadingError}
      />
    </div>
  );
};

export default AddCustomersHistory;
