import React from "react";

import classnames from "classnames";

import "./info_box.scss";

const InfoBox = ({ type = "primary", children = [] }) => {
  const infoBoxClasses = classnames("c-info-box", {
    [`c-info-box--${type}`]: type,
  });

  return <div className={infoBoxClasses}>{children}</div>;
};
export default InfoBox;
