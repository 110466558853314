import React from "react";

import { useTranslation } from "react-i18next";

import CopyText from "components/copy_text/CopyText";
import FormattedDate from "components/formatted_date/FormattedDate";
import "components/partner_management/partner_profile/partner_profile.scss";
import TooltipLink from "components/tooltip_link/TooltipLink";

const Campaigns = ({ timezoneOffset, campaignUrls = [] }) => {
  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="c-partner-profile__profile">
        <h2>{t("partnerProfile.campaigns")}</h2>

        <p className="c-partner-profile__description">{t("partnerProfile.campaignsInfo")}</p>

        {campaignUrls.map((m) => (
          <React.Fragment key={m}>
            <h3 className="sub-heading">{t("partnerProfile.campaignDetail")}</h3>
            <div key={m.campaign_name} className="c-partner-profile__list">
              <dl>
                <dt className="c-partner-profile__meta-header">
                  {t("partnerProfile.campaignName")}
                </dt>
                <dd>{m.campaign_name}</dd>
                <dt className="c-partner-profile__meta-header">
                  {t("partnerProfile.campaignDateRegistered")}
                </dt>
                <dd>
                  <FormattedDate
                    timestamp={m.date_registered}
                    timezoneOffset={timezoneOffset}
                    type="long"
                  />
                </dd>
                {m.dashboard_url && (
                  <>
                    <dt className="c-partner-profile__meta-header">
                      {t("partnerProfile.campaignDashboardUrlText")}
                    </dt>
                    <dd>
                      <div className="c-partner-profile__dashboard-buttons">
                        <TooltipLink
                          href={m.dashboard_url}
                          tooltipMessage={t("components.openNewTab.tooltipMessage")}
                        />
                        <CopyText
                          appearance="button"
                          text={m.dashboard_url}
                          tooltipMessage={t("components.copyText.tooltipMessage")}
                        />
                      </div>
                    </dd>
                  </>
                )}
                {m.share_url && (
                  <>
                    <dt className="c-partner-profile__meta-header">
                      {t("partnerProfile.campaignShareUrlText")}
                    </dt>
                    <dd>
                      <div className="c-partner-profile__share-url">
                        <span>{m.share_url}</span>

                        <CopyText
                          text={m.share_url}
                          tooltipMessage={t("components.copyText.tooltipMessage")}
                        />
                      </div>
                    </dd>
                  </>
                )}
              </dl>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Campaigns;
