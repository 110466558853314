import { useTranslation } from "react-i18next";

const YesNo = ({ value }) => {
  const { t } = useTranslation();
  if (value) {
    return t("global.yes");
  }
  return t("global.no");
};

export default YesNo;
