import React from "react";

import classnames from "classnames";

import "./campaign_handler.scss";

const CampaignHandler = ({
  icon,
  title,
  children,
  onClick,
  isActive = false,
  className = undefined,
}) => {
  const handlerClasses = classnames(
    "c-campaign-handler",
    {
      "c-campaign-handler--active": isActive,
    },
    className
  );

  return (
    <button type="button" onClick={onClick} tabIndex={0} className={handlerClasses}>
      <i className="c-campaign-handler__icon">{icon}</i>
      <span className="c-campaign-handler__title u-inline-1">{title}</span>
      <span className="c-campaign-handler__content">{children}</span>
    </button>
  );
};

export default CampaignHandler;
