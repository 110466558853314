import React, { useState } from "react";

import { fullName } from "helpers/Helpers";
import { useTranslation } from "react-i18next";

import Status from "components/customer_lookup/row_atoms/status/Status";
import VoucherCode from "components/customer_lookup/voucher_code/VoucherCode";
import DropdownButton from "components/dropdown_button/DropdownButton";
import FormattedDate from "components/formatted_date/FormattedDate";
import Button from "components/forms/button/Button";
import DelayedConfirmationModal from "components/modals/delayed_confirmation_modal/DelayedConfirmationModal";
import addNotification from "components/notifications/Notifications";
import Placeholder from "components/placeholder/Placeholder";

import { getRewardCategoryList, noRewardCategoryString } from "../../user_utils";

const UserReferral = ({
  referral,
  timezoneOffset,
  selectedMarket,
  isCis = false,
  reloadView = () => {},
}) => {
  const { t } = useTranslation();

  const errorNotificationTitle = t("global.error");
  const successNotificationTitle = t("global.success");

  const cancelReferralModalText = t("referrals.cancelReferralModalText");

  const errorCancelledMessage = t("referrals.notifications.errorCancelled");
  const errorConfirmingMessage = t("referrals.notifications.errorConfirming");
  const errorRevertedToPendingMessage = t("referrals.notifications.errorRevertedToPending");
  const noConfirmReferralWarningText = t("referrals.notifications.noConfirmReferralWarning");
  const successCancelledMessage = t("referrals.notifications.successCancelled");
  const successConfirmedMessage = t("referrals.notifications.successConfirmed");
  const successRevertedToPendingMessage = t("referrals.notifications.successRevertedToPending");

  const cancelReferralOptionsLabel = t("referrals.optionsLabels.cancelReferral");
  const confirmReferralOptionsLabel = t("referrals.optionsLabels.confirmReferral");
  const noRewardCategoryOptionsLabel = t("referrals.optionsLabels.noRewardCategory");
  const revertToPendingOptionsLabel = t("referrals.optionsLabels.revertToPending");

  const delayedConfirmationModalConfirmReferralConfirmationText = t(
    "referrals.delayedConfirmationModal.confirmReferral.confirmationText"
  );
  const delayedConfirmationModalConfirmReferralContent = t(
    "referrals.delayedConfirmationModal.confirmReferral.content"
  );
  const delayedConfirmationModalConfirmReferralTitle = t(
    "referrals.delayedConfirmationModal.confirmReferral.title"
  );
  const delayedConfirmationModalConfirmReferralUndoMessage = t(
    "referrals.delayedConfirmationModal.confirmReferral.undoMessage"
  );

  const delayedConfirmationModalCancelReferralConfirmationText = t(
    "referrals.delayedConfirmationModal.cancelReferral.confirmationText"
  );
  const delayedConfirmationModalCancelReferralTitle = t(
    "referrals.delayedConfirmationModal.cancelReferral.title"
  );
  const delayedConfirmationModalCancelReferralUndoMessage = t(
    "referrals.delayedConfirmationModal.cancelReferral.undoMessage"
  );

  const delayedConfirmationModalRestoreReferralConfirmationText = t(
    "referrals.delayedConfirmationModal.restoreReferral.confirmationText"
  );
  const delayedConfirmationModalRestoreReferralTitle = t(
    "referrals.delayedConfirmationModal.restoreReferral.title"
  );
  const delayedConfirmationModalRestoreReferralUndoMessage = t(
    "referrals.delayedConfirmationModal.restoreReferral.undoMessage"
  );

  const [showConfirmReferralModal, setConfirmReferralModal] = useState(false);
  const [showCancelReferralModal, setCancelReferralModal] = useState(false);
  const [showRestoreReferralModal, setRestoreReferralModal] = useState(false);

  const toggleConfirmReferralModal = () => {
    setConfirmReferralModal(!showConfirmReferralModal);
  };

  const toggleCancelReferralModal = () => {
    setCancelReferralModal(!showCancelReferralModal);
  };

  const toggleRestoreReferralModal = () => {
    setRestoreReferralModal(!showRestoreReferralModal);
  };

  const confirmReferral = async (rewardCategory) => {
    try {
      if (isCis) {
        addNotification.warning(errorNotificationTitle, noConfirmReferralWarningText);
      } else {
        await selectedMarket.users.reconcileReferral(
          selectedMarket.id,
          referral.order_number,
          "confirmed",
          rewardCategory === noRewardCategoryString ? undefined : rewardCategory
        );
        await reloadView();
        addNotification.success(successNotificationTitle, successConfirmedMessage);
      }
      toggleConfirmReferralModal();
    } catch (e) {
      addNotification.warning(errorNotificationTitle, errorConfirmingMessage);
      toggleConfirmReferralModal();
    }
  };

  const cancelReferral = async () => {
    try {
      if (isCis) {
        await selectedMarket.cisUsers.denyReferral(referral.id);
      } else {
        await selectedMarket.users.reconcileReferral(
          selectedMarket.id,
          referral.order_number,
          "cancelled"
        );
      }
      await reloadView();
      addNotification.success(successNotificationTitle, successCancelledMessage);
      toggleCancelReferralModal();
    } catch (e) {
      addNotification.warning(errorNotificationTitle, errorCancelledMessage);
      toggleCancelReferralModal();
    }
  };

  const restoreReferral = async () => {
    try {
      if (isCis) {
        await selectedMarket.cisUsers.restoreReferral(referral.id);
      } else {
        await selectedMarket.users.reconcileReferral(
          selectedMarket.id,
          referral.order_number,
          "restore"
        );
      }
      await reloadView();
      addNotification.success(successNotificationTitle, successRevertedToPendingMessage);
      toggleRestoreReferralModal();
    } catch (e) {
      addNotification.warning(errorNotificationTitle, errorRevertedToPendingMessage);
      toggleRestoreReferralModal();
    }
  };

  return (
    <tr className="single-result" key={referral.id}>
      <td>
        <span className="full-name">
          {!isCis && (
            <Button
              appearance="link"
              buttonElement="link"
              href={`/${selectedMarket.subdomain}/users/search/${referral.friend.id}`}
            >
              <Placeholder value={fullName(referral.friend.firstName, referral.friend.lastName)} />
            </Button>
          )}
          {isCis && (
            <Placeholder value={fullName(referral.friend.firstName, referral.friend.lastName)} />
          )}
        </span>
        <br />
        <Placeholder value={referral.friend.email} />
      </td>
      <td>
        <FormattedDate timestamp={referral.createdAt} timezoneOffset={timezoneOffset} type="long" />
      </td>
      <td>
        {referral.rewards && (
          <dl className="reward-vouchers">
            {referral.rewards.map((reward) => (
              <React.Fragment key={reward.id}>
                <dt>{reward.name}</dt>
                {(reward.vouchers.length > 0 &&
                  reward.vouchers.map((voucher) => (
                    <dd key={voucher.code}>
                      {voucher.poolName} (<VoucherCode>{voucher.code}</VoucherCode>)
                    </dd>
                  ))) ||
                  "-"}
              </React.Fragment>
            ))}
          </dl>
        )}
      </td>
      <td>
        <Status status={referral.status} timezoneOffset={timezoneOffset} />
      </td>
      <td>
        {referral.status.isPending() && (
          <React.Fragment>
            {isCis && (
              <DropdownButton
                dropdownList={[
                  { text: cancelReferralModalText, onClick: () => toggleCancelReferralModal() },
                ]}
              />
            )}
            {!isCis && (
              <>
                <DelayedConfirmationModal
                  id="confirm-referral"
                  title={delayedConfirmationModalConfirmReferralTitle}
                  content={delayedConfirmationModalConfirmReferralContent}
                  confirmationText={delayedConfirmationModalConfirmReferralConfirmationText}
                  placeholderText={t("referrals.delayedConfirmationModal.selectRewardCategory")}
                  onConfirmation={confirmReferral}
                  undoMessage={delayedConfirmationModalConfirmReferralUndoMessage}
                  closeModal={toggleConfirmReferralModal}
                  openModal={showConfirmReferralModal}
                  selectOptions={getRewardCategoryList(
                    selectedMarket.campaignById(referral.campaignId).rewardTypes,
                    {
                      name: noRewardCategoryOptionsLabel,
                      value: noRewardCategoryString,
                    }
                  )}
                />
                <DropdownButton
                  dropdownList={[
                    {
                      text: confirmReferralOptionsLabel,
                      onClick: () => toggleConfirmReferralModal(),
                    },
                    {
                      text: cancelReferralOptionsLabel,
                      onClick: () => toggleCancelReferralModal(),
                    },
                  ]}
                />
              </>
            )}
            <DelayedConfirmationModal
              id="cancel-referral"
              title={delayedConfirmationModalCancelReferralTitle}
              confirmationText={delayedConfirmationModalCancelReferralConfirmationText}
              onConfirmation={cancelReferral}
              undoMessage={delayedConfirmationModalCancelReferralUndoMessage}
              closeModal={toggleCancelReferralModal}
              openModal={showCancelReferralModal}
            />
          </React.Fragment>
        )}
        {referral.status.isCancelled() && (
          <React.Fragment>
            <DelayedConfirmationModal
              id="restore-referral"
              title={delayedConfirmationModalRestoreReferralTitle}
              confirmationText={delayedConfirmationModalRestoreReferralConfirmationText}
              onConfirmation={restoreReferral}
              undoMessage={delayedConfirmationModalRestoreReferralUndoMessage}
              closeModal={toggleRestoreReferralModal}
              openModal={showRestoreReferralModal}
            />
            <DropdownButton
              dropdownList={[
                { text: revertToPendingOptionsLabel, onClick: () => toggleRestoreReferralModal() },
              ]}
            />
          </React.Fragment>
        )}
      </td>
    </tr>
  );
};

export default UserReferral;
