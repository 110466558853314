import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import AddCustomersHistory from "components/bulk_actions/add_customers/add_customers_history/AddCustomersHistory";
import FileImport from "components/bulk_actions/file_import/FileImport";
import Button from "components/forms/button/Button";
import Modal from "components/modals/Modal";

import "./add_customers.scss";

const AddCustomers = ({ selectedMarket }) => {
  const { t } = useTranslation();
  const [showRequiredColumnsModal, setShowRequiredColumnsModal] = useState(false);
  const [checkHistory, setCheckHistory] = useState(true);
  const [importList, setImportList] = useState([]);
  const [importListLoadingError, setImportListLoadingError] = useState(false);

  const header = t("bulkActions.addCustomers.header");
  const subtitle = t("bulkActions.addCustomers.subtitle");

  const modalOpenButtonText = t("bulkActions.addCustomers.modal.openButtonText");
  const modalTitle = t("bulkActions.addCustomers.modal.title");
  const modalEmailDescription = t("bulkActions.addCustomers.modal.emailDescription");

  const fileImportButtonLabel = t("bulkActions.addCustomers.fileImport.buttonLabel");
  const fileImportCheckboxLabel = t("bulkActions.addCustomers.fileImport.checkboxLabel");

  const toggleModal = () => {
    setShowRequiredColumnsModal(!showRequiredColumnsModal);
  };

  const modalTable = (
    <>
      <table className="portal-table">
        <tbody>
          <tr>
            <td>Email</td>
            <td>{modalEmailDescription}</td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const onImportSuccess = () => {
    setCheckHistory(!checkHistory);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const addCustomersHistory = await selectedMarket.imports.getList("customers");
        setImportList(addCustomersHistory);
      } catch (err) {
        setImportListLoadingError(true);
      }
    };

    getData();
  }, [checkHistory, selectedMarket?.imports]);

  return (
    <div className="c-add-customers card">
      <h2 className="card__title">{header}</h2>
      <p>{subtitle}</p>
      <Button appearance="link" onClick={toggleModal}>
        {modalOpenButtonText}
      </Button>
      <FileImport
        selectedMarket={selectedMarket}
        importType="customers"
        buttonLabel={fileImportButtonLabel}
        checkBoxOptionKey="md5"
        checkBoxDefault={false}
        checkBoxLabel={fileImportCheckboxLabel}
        onImportSuccess={onImportSuccess}
      />
      <Modal
        className="c-add-customers-required-modal"
        buttonClassName="c-button--tertiary"
        closeModal={toggleModal}
        openModal={showRequiredColumnsModal}
        title={modalTitle}
        fullWidth
        closeButtonAriaLabel={t("global.close")}
      >
        {modalTable}
      </Modal>
      <AddCustomersHistory
        importList={importList}
        timezoneOffset={selectedMarket.timezone_offset}
        importListLoadingError={importListLoadingError}
      />
    </div>
  );
};

export default AddCustomers;
