import { useAuth } from "@/AuthWrapper";

const VisibleWith = ({ actions, children }) => {
  const { currentAdmin } = useAuth();
  const canAction = currentAdmin.canPerformAnyOf(actions);

  return canAction ? children : null;
};

export default VisibleWith;
