import React from "react";

import Market from "models/Market";
import { Translation } from "react-i18next";

import Button from "components/forms/button/Button";
import addNotification from "components/notifications/Notifications";

import "../user_fulfilments.scss";

class UserFulfilmentExpire extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ loading: true });

    const {
      selectedMarket,
      onSuccessfulUpdate,
      errorNotificationTitle,
      errorMessage,
      fulfilmentId = null,
    } = this.props;

    try {
      await selectedMarket.users.expireFulfilment(fulfilmentId);
      onSuccessfulUpdate();
    } catch (e) {
      addNotification.warning(errorNotificationTitle, errorMessage);
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    const { cancelText, onCancel } = this.props;

    return (
      <Translation>
        {(t) => (
          <div className="expiry-container-actions">
            <Button onClick={onCancel} appearance="secondary" outline fullWidth loading={loading}>
              {cancelText}
            </Button>
            <Button onClick={this.handleSubmit} appearance="negative" fullWidth loading={loading}>
              {t("fulfilments.expireModalButton")}
            </Button>
          </div>
        )}
      </Translation>
    );
  }
}

export default UserFulfilmentExpire;
