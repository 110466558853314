import React from "react";

import CisSubscription from "models/CisSubscription";
import { useTranslation } from "react-i18next";

import FormattedDate from "components/formatted_date/FormattedDate";

const Subscriptions = ({ subscriptions, timezoneOffset }) => {
  const { t } = useTranslation();
  const headerText = t("subscriptions.header");
  const tableHeaderActivationDateText = t("subscriptions.tableHeaders.activationDate");
  const tableHeaderIdentifierText = t("subscriptions.tableHeaders.identifier");
  const tableHeaderPlanText = t("subscriptions.tableHeaders.plan");
  const tableHeaderStatusText = t("subscriptions.tableHeaders.status");
  return (
    subscriptions.length > 0 && (
      <div className="user-subscriptions">
        <h3>{headerText}</h3>
        <div className="table-container">
          <table className="portal-table">
            <thead>
              <tr>
                <th>{tableHeaderStatusText}</th>
                <th>{tableHeaderIdentifierText}</th>
                <th>{tableHeaderActivationDateText}</th>
                <th>{tableHeaderPlanText}</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((subscription) => (
                <tr className="single-result" key={subscription.identifier}>
                  <td>{subscription.status}</td>
                  <td>{subscription.identifier}</td>
                  <td>
                    <FormattedDate
                      timestamp={subscription.activationDate}
                      timezoneOffset={timezoneOffset}
                      type="long"
                    />
                  </td>
                  <td>{subscription.plan}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
};

export default Subscriptions;
